import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/store'

// Demos
import Firma                        from '@/views/demos/Firma.vue'
// import ProgressBar                  from '@/views/demos/ProgressBar.vue'

// Teachers
import ComentariosAlumnosTeachers   from '@/views/operaciones/teachers/ComentariosAlumnosTeachers.vue'
import ComentariosAlumnosVentas     from '@/views/operaciones/ventas/ComentariosAlumnosVentas.vue'
import ComentariosAlumnosRecep      from '@/views/operaciones/recepcionista/ComentariosAlumnosRecep.vue'

import Contrato                     from '@/views/usuario/Contrato.vue'
import ReglamentoTeachers           from '@/views/usuario/ReglamentoTeachers.vue'
import Calendario                   from '@/views/usuario/Calendario.vue'

// Catalogos
import Departamentos                from '@/views/catalogos/departamentos.vue'
import UsuariosERP                  from '@/views/catalogos/UsuariosERP.vue'   //ANGEL RODRIGUEZ
import TrabajadoresERP              from '@/views/catalogos/TrabajadoresERP.vue'   //ANGEL RODRIGUEZ
import ChecadorTrabajadores         from '@/views/catalogos/ChecadorTrabajadores.vue'   //ANGEL RODRIGUEZ
import DirectorioTeacher            from '@/views/catalogos/DirectorioTeacher.vue'   //ANGEL RODRIGUEZ
// import Zonas                        from '@/views/catalogos/zonas.vue'
import ConfigUsuario                from '@/views/catalogos/configUsuario.vue'
// import Organigrama                  from '@/views/catalogos/organigrama.vue'
// import OrganigramaGraf              from '@/views/catalogos/organigramaGraf.vue'
import Niveles                      from '@/views/catalogos/niveles.vue'
import VendedoraSucursal            from '@/views/catalogos/VendedoraSucursal.vue'
import CursosEscuela                from '@/views/catalogos/CursosEscuela.vue'
import Modalidades                  from '@/views/catalogos/Modalidades.vue'
import Frecuencias                  from '@/views/catalogos/Frecuencias.vue'
import Ciclos                       from '@/views/catalogos/Ciclos.vue'
import Horarios                     from '@/views/catalogos/Horarios.vue'
import NivelesERP                   from '@/views/catalogos/NivelesERP.vue'
import Salones                      from '@/views/catalogos/Salones.vue'
import Cursos                       from '@/views/catalogos/Cursos.vue'
// import FormasPago                   from '@/views/catalogos/FormasPago.vue'
import Grupos                       from '@/views/catalogos/Grupos.vue'

// import Prospectos                   from '@/views/prospectos/Prospectos.vue'

// Academico
import VistaGruposRoles             from '@/views/academico/VistaGruposRoles.vue'
import GruposSinTeacher             from '@/views/academico/GruposSinTeacher.vue'
import ComentariosMaestro           from '@/views/academico/ComentariosMaestro.vue'           //ANGEL RODRIGUEZ
import PeticionHorasExtras          from '@/views/academico/PeticionHorasExtras.vue'           //ANGEL RODRIGUEZ
import IncidenciasBonos             from '@/views/academico/IncidenciasBonos.vue'           //ANGEL RODRIGUEZ
import SeguroMedicoIMSS             from '@/views/academico/SeguroMedicoIMSS.vue'           //ANGEL RODRIGUEZ
import CajaAhorro                   from '@/views/academico/CajaAhorro.vue'           //ANGEL RODRIGUEZ
import EncargadasComisiones         from '@/views/academico/EncargadasComisiones.vue'           //ANGEL RODRIGUEZ
import RebajesNomina                from '@/views/academico/RebajesNomina.vue'           //ANGEL RODRIGUEZ
import HorasExtras                  from '@/views/academico/HorasExtras.vue'           //ANGEL RODRIGUEZ
import HorasExtraEquipo             from '@/views/academico/HorasExtraEquipo.vue'           //ANGEL RODRIGUEZ
import Devoluciones                 from '@/views/academico/Devoluciones.vue'           //ANGEL RODRIGUEZ
import BonosPuntualidad             from '@/views/academico/BonosPuntualidad.vue'           //ANGEL RODRIGUEZ
import BonosRI                      from '@/views/academico/BonosRI.vue'           //ANGEL RODRIGUEZ
import BonosVentas                  from '@/views/academico/BonosVentas.vue'           //ANGEL RODRIGUEZ
import ReglamentoEscuela            from '@/views/academico/ReglamentoEscuela.vue'           //ANGEL RODRIGUEZ
import Incidencias                  from '@/views/academico/Incidencias.vue'           //ANGEL RODRIGUEZ
import Recursos                     from '@/views/academico/Recursos.vue'           //ANGEL RODRIGUEZ
import ReporteVentasGeneral         from '@/views/academico/ReporteVentasGeneral.vue'           //ANGEL RODRIGUEZ
import ExamenCalidad                from '@/views/academico/ExamenCalidad.vue'           //ANGEL RODRIGUEZ
// import AsignarCodigo                from '@/views/academico/certificacion/AsignarCodigo.vue'
import CodigosAsignados             from '@/views/academico/certificacion/CodigosAsignados.vue'
import ResultadosCambridge          from '@/views/academico/certificacion/ResultadosCambridge.vue'
import AlumnosNivelCatorce          from '@/views/academico/certificacion/AlumnosNivelCatorce.vue'
import MisSolicitudesCertificacion  from '@/views/academico/solicitudes/MisSolicitudesCertificacion.vue'
import SolicitudesCertificacion     from '@/views/academico/solicitudes/SolicitudesCertificacion.vue'
import EvaluacionLeccion            from '@/views/academico/evaluaciones/EvaluacionLeccion.vue'
import SeguimientoCerti             from '@/views/academico/certificacion/SeguimientoCerti.vue'


import Login                        from '@/views/usuario/Login.vue'
import Perfil                       from '@/views/usuario/Perfil.vue'

// KPI
import InscPorCiclo                 from '@/views/kpi/InscPorCiclo.vue'
import InscritosCicloMerka          from '@/views/kpi/InscritosCicloMerka.vue'
import ReporteDiario                from '@/views/kpi/ReporteDiario.vue'
import RiGrupos                     from '@/views/kpi/RiGrupos.vue'
import RiMontos                     from '@/views/kpi/RiMontos.vue'
import RiMontosInbi                 from '@/views/kpi/RiMontosInbi.vue'
import RiMontosFast                 from '@/views/kpi/RiMontosFast.vue'
import NiporCiclos                  from '@/views/kpi/NiporCiclos.vue'
import NiporCicloAlumnos            from '@/views/kpi/NiporCicloAlumnos.vue'
import NITotales                    from '@/views/kpi/NITotales.vue'
import NIVendedora                  from '@/views/kpi/NIVendedora.vue'
import InscritosInduccion           from '@/views/kpi/InscritosInduccion.vue'
import KpiGeneral                   from '@/views/kpi/KpiGeneral.vue'
import KpiGeneralLider              from '@/views/kpi/KpiGeneralLider.vue'
import KpiGeneralTeacher            from '@/views/kpi/KpiGeneralTeacher.vue'
import KpiGeneralTeens              from '@/views/kpi/KpiGeneralTeens.vue'
import IngresosEgresos              from '@/views/kpi/IngresosEgresos.vue'
import NuevasMatriculas             from '@/views/kpi/NuevasMatriculas.vue'
import RegistroKpiTeacher           from '@/views/kpi/kpi-teachers/RegistroKpiTeacher.vue'
import ReporteKpiTeacher            from '@/views/kpi/kpi-teachers/ReporteKpiTeacher.vue'
import ReporteKpiPorTeacher         from '@/views/kpi/kpi-teachers/ReporteKpiPorTeacher.vue'
import ReporteKpiTeachers           from '@/views/kpi/kpi-teachers/ReporteKpiTeachers.vue'
// DashboarGeneral
import DashboardKpiGeneral          from '@/views/kpi/dashboard_general/DashboardKpiGeneral.vue'
import DashboardPersonal            from '@/views/kpi/dashboard_personal/DashboardPersonal.vue'
// import MetasDashboardGeneral        from '@/views/kpi/metas/MetasDashboardGeneral.vue'
// import MetasSemanales               from '@/views/kpi/metas/MetasSemanales.vue'

// import ReporteVentas                from '@/views/kpi/prospectos/ReporteVentas.vue'

// import ActividadesPersonal          from '@/views/kpi/ActividadesPersonal.vue'
// import RegistrarActividad           from '@/views/kpi/RegistrarActividad.vue'

import EstadoResultados             from '@/views/egresos/EstadoResultados.vue'

// RH
import RegistrarVacantes            from '@/views/operaciones/rh/RegistrarVacantes.vue'
import EntradasSalidas              from '@/views/operaciones/rh/EntradasSalidas.vue'
import Nomina                       from '@/views/operaciones/rh/Nomina.vue'
import NominaIndividual             from '@/views/operaciones/rh/NominaIndividual.vue'
import MiNomina                     from '@/views/operaciones/rh/MiNomina.vue'
import ComentariosNomina            from '@/views/operaciones/rh/ComentariosNomina.vue'
import MiNominaTeacher              from '@/views/operaciones/rh/MiNominaTeacher.vue'
import CargarEntradasCorporativo    from '@/views/operaciones/rh/CargarEntradasCorporativo.vue'
import RelojChecador                from '@/views/operaciones/rh/RelojChecador.vue'
import OrganigramaPrueba            from '@/views/operaciones/rh/OrganigramaPrueba.vue'    //Angel Rodriguez
import OrganigramaV2                from '@/views/operaciones/rh/OrganigramaV2.vue'    //Angel Rodriguez
import ComparativaERP               from '@/views/operaciones/rh/ComparativaERP.vue'    //Angel Rodriguez
import ComisionesIndividuales       from '@/views/operaciones/rh/ComisionesIndividuales.vue'           //ANGEL RODRIGUEZ
import AutorizacionNomina           from '@/views/operaciones/rh/AutorizacionNomina.vue'           //ANGEL RODRIGUEZ

// Operaciones --> cambios
import NuevoCambio                  from '@/views/operaciones/cambios/NuevoCambio.vue'
import MisCambios                   from '@/views/operaciones/cambios/MisCambios.vue'
import CambiosPendientes            from '@/views/operaciones/cambios/CambiosPendientes.vue'
import AlumnosLMS                   from '@/views/operaciones/lms/AlumnosLMS.vue'
import Contratos                    from '@/views/operaciones/teachers/Contratos.vue'
import AlumnosReprobados            from '@/views/operaciones/alumnos/AlumnosReprobados.vue'
import AsistenciaAlumnos            from '@/views/operaciones/alumnos/AsistenciaAlumnos.vue'
import AlumnosReprobadosTeacher     from '@/views/operaciones/alumnos/AlumnosReprobadosTeacher.vue'
import AlumnosCambridge             from '@/views/operaciones/alumnos/AlumnosCambridge.vue'
import AlumnosCambridgeRecepcion    from '@/views/operaciones/alumnos/AlumnosCambridgeRecepcion.vue'
import ComentariosAlumnos           from '@/views/operaciones/alumnos/ComentariosAlumnos.vue'
import Recomendados                 from '@/views/operaciones/alumnos/Recomendados.vue'
import RecomendadosFinanzas         from '@/views/operaciones/alumnos/RecomendadosFinanzas.vue'
import NumeroVacantes               from '@/views/operaciones/vacantes/NumeroVacantes.vue'

// Inscripciones
import Inscripciones                from '@/views/operaciones/inscripciones/Inscripciones.vue'
import RelacionPagos                from '@/views/operaciones/inscripciones/RelacionPagos.vue'
import CorteDiario                  from '@/views/operaciones/inscripciones/CorteDiario.vue'
import ValidarPagos                 from '@/views/operaciones/inscripciones/ValidarPagos.vue'
import ValidarPagosSucursal         from '@/views/operaciones/inscripciones/ValidarPagosSucursal.vue'
import Cambios                      from '@/views/operaciones/cambios/Cambios.vue'
import AlumnoFechaPago              from '@/views/operaciones/recepcionista/AlumnoFechaPago.vue'


// operaciones -> alumnos
import EncuestaPresencial           from '@/views/operaciones/alumnos/EncuestaPresencial.vue'
import AlumnosCalificaciones        from '@/views/operaciones/alumnos/AlumnosCalificaciones.vue'

import InicioRP                     from '@/views/tickets/operaciones/inicioRP.vue'

import Areas                        from '@/views/tickets/catalogos/Areas.vue'
import Jefes                        from '@/views/tickets/catalogos/Jefes.vue'
import Auxiliares                   from '@/views/tickets/catalogos/Auxiliares.vue'
import Sucursales                   from '@/views/tickets/catalogos/Sucursales.vue'

// Tickets
import Tickets                      from '@/views/tickets/operaciones/Tickets.vue'
import AddTicket                    from '@/views/tickets/operaciones/AddTicket.vue'
import TicketArea                   from '@/views/tickets/operaciones/TicketArea.vue'
import TicketAuxi                   from '@/views/tickets/operaciones/TicketAuxi.vue'
import ResponderUsuario             from '@/views/tickets/operaciones/ResponderUsuario.vue'
import VerHistorialTicketUsuario    from '@/views/tickets/operaciones/VerHistorialTicketUsuario.vue'
import AgregarTicketRP              from '@/views/tickets/operaciones/AgregarTicketRP.vue'
import MisTickets                   from '@/views/tickets/operaciones/MisTickets.vue'
import TicketsAsignados             from '@/views/tickets/operaciones/TicketsAsignados.vue'

// Rerportes de tickets
import ReporteEstatusPorArea        from '@/views/tickets/operaciones/reportes/ReporteEstatusPorArea.vue'
import Reportes                     from '@/views/tickets/operaciones/reportes/Reportes.vue'
import ReporteTicketsPorInstitucion from '@/views/tickets/operaciones/reportes/ReporteTicketsPorInstitucion.vue'
import ReporteTicketsRespuesta      from '@/views/tickets/operaciones/reportes/ReporteTicketsTiempoRespuesta.vue'
import ReporteTicketsPorArea        from '@/views/tickets/operaciones/reportes/ReportePorArea.vue'

// General
import Manuales                     from '@/views/manuales/Manuales.vue'
import Reglamento                   from '@/views/manuales/Reglamento.vue'
import ManualesTeachers             from '@/views/manuales/ManualesTeachers.vue'
import ManualesRecepcionista        from '@/views/manuales/ManualesRecepcionista.vue'

// LMS
import ProspectosLms                from '@/views/lms/prospectos/ProspectosLms.vue'
import HorariosLMS                  from '@/views/lms/sistema/HorariosLMS.vue'
import CiclosLMS                    from '@/views/lms/sistema/CiclosLMS.vue'
import GruposLMS                    from '@/views/lms/sistema/GruposLMS.vue'
import GrupoTeacherLMS              from '@/views/lms/sistema/GrupoTeacherLMS.vue'
import GrupoAlumnoLMS               from '@/views/lms/sistema/GrupoAlumnoLMS.vue'
import GruposExciLMS                from '@/views/lms/sistema/GruposExciLMS.vue'
import TeachersLMS                  from '@/views/lms/sistema/TeachersLMS.vue'
import UsuariosLMS                  from '@/views/lms/sistema/UsuariosLMS.vue'
import CalificacionesLMS            from '@/views/lms/sistema/CalificacionesLMS.vue'
import AlumnosErpViejo              from '@/views/lms/sistema/AlumnosErpViejo.vue'
import HabilitarPlataforma          from '@/views/lms/soporte/HabilitarPlataforma.vue'

// CALIDAD
import InspeccionClase              from '@/views/calidad/clases/InspeccionClase.vue'
import CalidadClasePreguntas        from '@/views/calidad/clases/CalidadClasePreguntas.vue'
import EvaluacionClase              from '@/views/calidad/clases/EvaluacionClase.vue'
import EvaluacionClaseAll           from '@/views/calidad/clases/EvaluacionClaseAll.vue'
import EvaluacionClaseTeacher       from '@/views/calidad/clases/EvaluacionClaseTeacher.vue'
import CalificacionClase            from '@/views/calidad/clases/CalificacionClase.vue'
import CalificacionGrupo            from '@/views/calidad/clases/CalificacionGrupo.vue'

// CRM PROSPECTOS
// import Etapas                       from '@/views/crm-prospectos/catalogos/Etapas.vue'
import MedioContacto                from '@/views/crm-prospectos/catalogos/MedioContacto.vue'
import Anuncios                     from '@/views/crm-prospectos/catalogos/Anuncios.vue'
import Tareas                       from '@/views/crm-prospectos/catalogos/Tareas.vue'
import Campanias                    from '@/views/crm-prospectos/catalogos/Campanias.vue'
import Motivos                      from '@/views/crm-prospectos/catalogos/Motivos.vue'
import Fuentes                      from '@/views/crm-prospectos/catalogos/Fuentes.vue'
import DetalleFuentes               from '@/views/crm-prospectos/catalogos/DetalleFuentes.vue'


import Prospectos                   from '@/views/crm-prospectos/prospectos/Prospectos.vue'
import VerProspecto                 from '@/views/crm-prospectos/prospectos/VerProspecto.vue'
// import Remarketing                  from '@/views/crm-prospectos/prospectos/Remarketing.vue'
// import RemarketingFast              from '@/views/crm-prospectos/prospectos/RemarketingFast.vue'
import ProspectosInscritos          from '@/views/crm-prospectos/prospectos/ProspectosInscritos.vue'
// import ProspectosAll                from '@/views/crm-prospectos/prospectos/ProspectosAll.vue'
import ProspectosMMK                from '@/views/crm-prospectos/prospectos/ProspectosMMK.vue'
import ProspectosMMKInbi            from '@/views/crm-prospectos/prospectos/ProspectosMMKInbi.vue'
import Convencimiento               from '@/views/crm-prospectos/prospectos/Convencimiento.vue'
import Contactos                    from '@/views/marketing/Contactos.vue'
import LedsFast                     from '@/views/crm-prospectos/prospectos/LedsFast.vue'
import LedsInbi                     from '@/views/crm-prospectos/prospectos/LedsInbi.vue'
import DashboardVentas              from '@/views/crm-prospectos/prospectos/DashboardVentas.vue'
import CantidadProspectosVendedora  from '@/views/crm-prospectos/prospectos/CantidadProspectosVendedora.vue'
import ActivarProspectos            from '@/views/crm-prospectos/prospectos/ActivarProspectos.vue'              //ANGEL RODRIGUEZ
import CambiarVendedora             from '@/views/crm-prospectos/prospectos/CambiarVendedora.vue'               //ANGEL RODRIGUEZ
import CambiarProspecto             from '@/views/crm-prospectos/prospectos/CambiarProspecto.vue'               //ANGEL RODRIGUEZ
import DashboardMarketing           from '@/views/marketing/DashboardMarketing.vue'

import ControlRegistroExci          from '@/views/exci/ControlRegistroExci.vue'
import UsuariosExci                 from '@/views/exci/UsuariosExci.vue'
import BecaInglesExci               from '@/views/exci/BecaInglesExci.vue'



// Preguntas frecuentes
import MisPreguntas                 from '@/views/preguntasfrecuentes/MisPreguntas.vue'
import Preguntas                    from '@/views/preguntasfrecuentes/Preguntas.vue'
import PreguntasFrecuentes          from '@/views/preguntasfrecuentes/PreguntasFrecuentes.vue'
import PreguntasUsuario             from '@/views/preguntasfrecuentes/PreguntasUsuario.vue'

// RIESGOOO
import Riesgo         from '@/views/riesgo/Riesgo.vue'
import PuntosAlumnos  from '@/views/riesgo/PuntosAlumnos.vue'



Vue.use(VueRouter)

const router = new VueRouter({
  mode: '',
  base: process.env.BASE_URL,
  routes: [

    { path: '/contrato/:folio'                , name: 'Contrato'                    , component: Contrato, 
      meta: { ADMIN: true, USUARIO: true, libre: true }},
    { path: '/comentariosalumnosteachers'     , name: 'ComentariosAlumnosTeachers'  , component: ComentariosAlumnosTeachers, 
      meta: { ADMIN: true, USUARIO: true, libre: true }},
    { path: '/comentariosalumnosventas'       , name: 'ComentariosAlumnosVentas'    , component: ComentariosAlumnosVentas, 
      meta: { ADMIN: true, USUARIO: true, libre: true }},
    { path: '/comentariosalumnosrecep'       , name: 'ComentariosAlumnosRecep'      , component: ComentariosAlumnosRecep, 
      meta: { ADMIN: true, USUARIO: true, libre: true }},
    { path: '/firma'       , name: 'Firma'      , component: Firma, 
      meta: { ADMIN: true, USUARIO: true, libre: true }},


    { path: '/'               , name: 'Home'           , component: Home, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/login/:id'      , name: 'Login'          , component: Login, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true, libre: true }},
    { path: '/perfil'         , name: 'Perfil'         , component: Perfil, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/departamentos'      , name: 'Departamentos'      , component: Departamentos, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/reglamentoteachers'      , name: 'ReglamentoTeachers'      , component: ReglamentoTeachers, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/calendario'      , name: 'Calendario'      , component: Calendario, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    // { path: '/zonas'              , name: 'Zonas'              , component: Zonas, 
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/configusuario'      , name: 'ConfigUsuario'      , component: ConfigUsuario, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    // { path: '/organigrama'        , name: 'Organigrama'        , component: Organigrama, 
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    // { path: '/organigramagraf'    , name: 'OrganigramaGraf'    , component: OrganigramaGraf, 
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/niveles'            , name: 'Niveles'            , component: Niveles, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/cursosescuela'      , name: 'CursosEscuela'      , component: CursosEscuela, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/modalidades'        , name: 'Modalidades'        , component: Modalidades, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/frecuencias'        , name: 'Frecuencias'        , component: Frecuencias, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/ciclos'             , name: 'Ciclos'             , component: Ciclos, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/horarios'           , name: 'Horarios'           , component: Horarios, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/niveleserp'         , name: 'NivelesERP'         , component: NivelesERP, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/salones'            , name: 'Salones'            , component: Salones, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/cursos'             , name: 'Cursos'             , component: Cursos, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    // { path: '/formaspago'         , name: 'FormasPago'         , component: FormasPago, 
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/grupos'             , name: 'Grupos'             , component: Grupos, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/permisos'           , name: 'Permisos'           , component: ()=> import('@/views/catalogos/Permisos.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/planteles'           , name: 'Planteles'           , component: ()=> import('@/views/catalogos/Planteles.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},


    // Académico
    { path: '/vistagruposroles'               , name: 'VistaGruposRoles'                   , component: VistaGruposRoles,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/grupossinteacher'               , name: 'GruposSinTeacher'                   , component: GruposSinTeacher,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/comentariosmaestro'             , name: 'ComentariosMaestro'                 , component: ComentariosMaestro,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/peticionhorasextras'            , name: 'PeticionHorasExtras'                , component: PeticionHorasExtras,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/incidenciasbonos'            , name: 'IncidenciasBonos'                      , component: IncidenciasBonos,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/seguromedicoimss'            , name: 'SeguroMedicoIMSS'                      , component: SeguroMedicoIMSS,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/cajaahorro'            , name: 'CajaAhorro'                                  , component: CajaAhorro,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/encargadascomisiones/:fecha/:nombre'          , name: 'EncargadasComisiones' , component: EncargadasComisiones,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/rebajesnomina/:fecha/:nombre'          , name: 'RebajesNomina'               , component: RebajesNomina,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/horasextras/:fecha/:nombre'                    , name: 'HorasExtras'         , component: HorasExtras,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/horasextraequipo'                    , name: 'HorasExtraEquipo'         , component: HorasExtraEquipo,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/devoluciones'                    , name: 'Devoluciones'                      , component: Devoluciones,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/bonospuntualidad'                , name: 'BonosPuntualidad'                  , component: BonosPuntualidad,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/bonosventas/:fecha/:nombre'                , name: 'BonosVentas'             , component: BonosVentas,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/bonosri'                , name: 'BonosRI'                                    , component: BonosRI,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/reglamentoescuela'              , name: 'ReglamentoEscuela'                  , component: ReglamentoEscuela,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/incidencias'                    , name: 'Incidencias'                        , component: Incidencias,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/examencalidad'                    , name: 'ExamenCalidad'                    , component: ExamenCalidad,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/reporteventasgeneral'           , name: 'ReporteVentasGeneral'               , component: ReporteVentasGeneral,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/recursos'                       , name: 'Recursos'                           , component: Recursos,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/usuarioserp'                    , name: 'UsuariosERP'                        , component: UsuariosERP,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/trabajadoreserp'                , name: 'TrabajadoresERP'                    , component: TrabajadoresERP,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/ChecadorTrabajadores'                , name: 'ChecadorTrabajadores'          , component: ChecadorTrabajadores,  //ANGEL RODRIGUEZ
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true } },

    { path: '/directorioteacher'              , name: 'DirectorioTeacher'               , component: DirectorioTeacher,  //ANGEL RODRIGUEZ
    meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/directoriogeneral'              , name: 'DirectorioGeneral'               , component: ()=> import('@/views/catalogos/DirectorioGeneral.vue'),  //ANGEL RODRIGUEZ
    meta: { ADMIN: true, USUARIO: true, NORMAL: true } },

    { path: '/expedienteempleados'           , name: 'ExpedienteEmpleados'             , component: ()=> import('@/views/operaciones/rh/ExpedienteEmpleados.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    // { path: '/asignarcodigo'                 , name: 'AsignarCodigo'                   , component: AsignarCodigo,
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/codigosasignados'              , name: 'CodigosAsignados'                , component: CodigosAsignados,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/resultadoscambridge'           , name: 'ResultadosCambridge'             , component: ResultadosCambridge,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/alumnosnivelcatorce'           , name: 'AlumnosNivelCatorce'             , component: AlumnosNivelCatorce,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/missolicitudescertificacion'    , name: 'MisSolicitudesCertificacion'    , component: MisSolicitudesCertificacion,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/solicitudescertificacion'       , name: 'SolicitudesCertificacion'       , component: SolicitudesCertificacion,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/evaluacionleccion'              , name: 'EvaluacionLeccion'              , component: EvaluacionLeccion,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/seguimientocerti'               , name: 'SeguimientoCerti'               , component: SeguimientoCerti,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/videosalumnos'                  , name: 'VideosAlumnos'                  , component: ()=> import('@/views/academico/VideosAlumnos.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/visualizarasistenciateacher'    , name: 'VisualizarAsistenciaTeacher'    , component: ()=> import('@/views/academico/VisualizarAsistenciaTeacher.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/rolclases'                      , name: 'RolClases'                      , component: ()=> import('@/views/academico/RolClases.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/rolclases2'                      , name: 'RolClases2'                    , component: ()=> import('@/views/academico/RolClases2.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/rolclases3'                      , name: 'RolClases3'                    , component: ()=> import('@/views/academico/RolClases3.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/panelcapacitacionteacher'       , name: 'PanelCapacitacionTeacher'       , component: ()=> import('@/views/academico/capacitacion/PanelCapacitacionTeacher.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/panelcapacitacionusuario'       , name: 'PanelCapacitacionUsuario'       , component: ()=> import('@/views/academico/capacitacion/PanelCapacitacionUsuario.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/revisionexamenes'               , name: 'RevisionExamenes'               , component: ()=> import('@/views/academico/RevisionExamenes.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/rolessucursal'                  , name: 'RolesSucursal'                  , component: ()=> import('@/views/academico/RolesSucursal.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/rolesteachers'                  , name: 'RolesTeachers'                  , component: ()=> import('@/views/academico/RolesTeachers.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/asignarsalon'                  , name: 'AsignarSalon'                    , component: ()=> import('@/views/academico/AsignarSalon.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/teachersmode'                  , name: 'TeachersMode'                    , component: ()=> import('@/views/academico/TeachersMode.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/rolesvista'                    , name: 'RolesVista'                      , component: ()=> import('@/views/academico/RolesVista.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },

    // KPI
    { path: '/inscporciclo',           name: 'inscporciclo',           component: InscPorCiclo, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/inscritosciclomerka',    name: 'InscritosCicloMerka',    component: InscritosCicloMerka, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    
    { path: '/reportediario',          name: 'ReporteDiario',          component: ReporteDiario, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/niporciclos',            name: 'niporciclos',            component: NiporCiclos, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 
    { path: '/niporcicloalumnos',      name: 'niporcicloalumnos',      component: NiporCicloAlumnos, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/nitotales',              name: 'nitotales',              component: NITotales, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/nivendedora',            name: 'nivendedora',            component: NIVendedora, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/inscritosinduccion',     name: 'InscritosInduccion',     component: InscritosInduccion, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/kpigeneral',             name: 'kpigeneral',             component: KpiGeneral, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
      { path: '/kpigenerallider',      name: 'KpiGeneralLider',        component: KpiGeneralLider, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/kpigeneralteacher',      name: 'KpiGeneralTeacher',      component: KpiGeneralTeacher, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/kpigeneralteens',        name: 'kpigeneralteens',        component: KpiGeneralTeens, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/ingresosegresos',        name: 'IngresosEgresos',        component: IngresosEgresos, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/estadoresultados',        name: 'EstadoResultados',      component: EstadoResultados, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/nuevasmatriculas',        name: 'NuevasMatriculas',      component: NuevasMatriculas, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/registrokpiteacher',     name: 'RegistroKpiTeacher',     component: RegistroKpiTeacher, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/reportekpiteacher',      name: 'ReporteKpiTeacher',      component: ReporteKpiTeacher, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/reportekpiporteacher',   name: 'ReporteKpiPorTeacher',   component: ReporteKpiPorTeacher, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/reportekpiteachers',     name: 'ReporteKpiTeachers',     component: ReporteKpiTeachers, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/repsemventas'             ,   name: 'RepSemVentas',                component: ()=> import('@/views/kpi/RepSemVentas.vue'),
      meta: { ADMIN: true } },
    { path: '/repsemvendedora'          ,   name: 'RepSemVendedora',             component: ()=> import('@/views/kpi/RepSemVendedora.vue'),
      meta: { ADMIN: true } },
    { path: '/historialventas'          ,   name: 'HistorialVentas',             component: ()=> import('@/views/kpi/HistorialVentas.vue'),
      meta: { ADMIN: true } },
    { path: '/historialventasfecha'     ,   name: 'HistorialVentasFecha',        component: ()=> import('@/views/egresos/HistorialVentasFecha.vue'),
      meta: { ADMIN: true } },
    { path: '/ventasmomento'            ,   name: 'VentasMomento',               component: ()=> import('@/views/kpi/VentasMomento.vue'),
      meta: { ADMIN: true } },
    { path: '/actividadesdiarias'       ,   name: 'ActividadesDiarias',          component: ()=> import('@/views/kpi/ActividadesDiarias.vue'),
      meta: { ADMIN: true } },
    { path: '/actividadesdiariastodos'  ,   name: 'ActividadesDiariasTodos',     component: ()=> import('@/views/kpi/ActividadesDiariasTodos.vue'),
      meta: { ADMIN: true } },
    { path: '/actividadesdiariasequipo' ,   name: 'ActividadesDiariasEquipo',    component: ()=> import('@/views/kpi/ActividadesDiariasEquipo.vue'),
      meta: { ADMIN: true } },
    { path: '/seguimientowhatsapp'      ,   name: 'SeguimientoWhatsApp',         component: ()=> import('@/views/kpi/SeguimientoWhatsApp.vue'),
      meta: { ADMIN: true } },
    { path: '/objetivossemanales'       ,   name: 'ObjetivosSemanales',          component: ()=> import('@/views/kpi/ObjetivosSemanales.vue'),
      meta: { ADMIN: true } },

    // Dashboard general de kpi
    { path: '/dashboardkpigeneral',    name: 'DashboardKpiGeneral',    component: DashboardKpiGeneral, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/dashboardpersonal',      name: 'DashboardPersonal',      component: DashboardPersonal, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/kpidirector'               ,   name: 'KpiDirector',                  component: ()=> import('@/views/kpi/KpiDirector.vue'),
      meta: { ADMIN: true } },
    { path: '/kpijefedepartamento'       ,   name: 'KpiJefeDepartamento',          component: ()=> import('@/views/kpi/KpiJefeDepartamento.vue'),
      meta: { ADMIN: true } },

    // { path: '/metasdashboardgeneral',  name: 'MetasDashboardGeneral',  component: MetasDashboardGeneral, 
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    // { path: '/actividadespersonal'  ,  name: 'ActividadesPersonal'  ,  component: ActividadesPersonal, 
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    // { path: '/registraractividad'   ,  name: 'RegistrarActividad'   ,  component: RegistrarActividad, 
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    // { path: '/metassemanales'       ,  name: 'MetasSemanales'       ,  component: MetasSemanales, 
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    // { path: '/reporteventas'        ,  name: 'ReporteVentas'       ,  component: ReporteVentas, 
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
      
    

    { path: '/rigrupos'        , name: 'rigrupos'    , component: RiGrupos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/rimontosinbi'    , name: 'RiMontosInbi'    , component: RiMontosInbi,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/rimontosfast'    , name: 'RiMontosFast'    , component: RiMontosFast,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
    { path: '/rimontos'        , name: 'RiMontos'        , component: RiMontos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },

    // {path: '/verprospecto/:id', name: 'ViewProspecto',  component: ViewProspecto ,
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    // { path: '/whatsapp', name: 'Whatsapp', component: ChatsWhatsapp,
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/campanias'      , name: 'Campanias'    , component: Campanias,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    // { path: '/agregartarea/:id', name: 'addCita'     , component: AddCitas,
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    // { path: '/editartarea/:id', name: 'editCita'     , component: EditCita,
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true }},


  
    { path: '/catproductos', name: 'catproductos',  component: ()=> import('@/views/tienda/clasificacion/arts/CatProductos.vue'),
      meta: { ADMIN: true } },
    { path: '/editproduct', name: 'editproduct',    component: ()=> import('@/views/tienda/clasificacion/arts/EditProducto.vue'),
      meta: { ADMIN: true } },
    { path: '/newproducto', name: 'newproducto',    component: ()=> import('@/views/tienda/clasificacion/arts/NewProducto.vue'),
      meta: { ADMIN: true } },


    { path: '/catlab',    name: 'catlab',            component: ()=> import('@/views/tienda/clasificacion/laboratorios/CatLab.vue'),
      meta: { ADMIN: true } },
    { path: '/subcatego', name: 'subcatego',         component: ()=> import('@/views/tienda/clasificacion/laboratorios/SubCategoria.vue'),
      meta: { ADMIN: true } },
    { path: '/familias' , name: 'familias',          component: ()=> import('@/views/tienda/clasificacion/laboratorios/Familias.vue'),
      meta: { ADMIN: true } },
    
    { path: '/pedidos', name: 'pedidos',             component: ()=> import('@/views/tienda/administrativo/Pedidos.vue'),
      meta: { ADMIN: true } },
    { path: '/verpedido', name: 'verpedido',         component: ()=> import('@/views/tienda/administrativo/VerPedido.vue'),
      meta: { ADMIN: true } },

   
    { path: '/dashboard', name: 'dashboard',         component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: { ADMIN: true } },

    // Compras
    { path: '/carrito'          , name: 'Carrito',              component: ()=> import('@/views/tienda/administrativo/Carrito.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/miscompras'       , name: 'MisCompras',           component: ()=> import('@/views/tienda/administrativo/MisCompras.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/solicitudescompra', name: 'SolicitudesCompra',    component: ()=> import('@/views/tienda/administrativo/SolicitudesCompra.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},



    // Operaciones --> cambios
    { path: '/nuevocambio',                name: 'NuevoCambio',               component: NuevoCambio,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/miscambios',                 name: 'MisCambios',                component: MisCambios,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/cambiospendientes',          name: 'CambiosPendientes',         component: CambiosPendientes,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/cambios',                    name: 'Cambios',                   component: Cambios,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},

    { path: '/alumnoslms',                 name: 'AlumnosLMS',                component: AlumnosLMS,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/contratos',                  name: 'Contratos',                 component: Contratos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/alumnosreprobados',          name: 'AlumnosReprobados',         component: AlumnosReprobados,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/asistenciaalumnos',          name: 'AsistenciaAlumnos',         component: AsistenciaAlumnos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/alumnosreprobadosteacher',   name: 'AlumnosReprobadosTeacher',  component: AlumnosReprobadosTeacher,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/alumnoscambridge',           name: 'AlumnosCambridge',          component: AlumnosCambridge,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},  
    { path: '/alumnoscambridgerecepcion',  name: 'AlumnosCambridgeRecepcion', component: AlumnosCambridgeRecepcion,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},  

    { path: '/comentariosalumnos',         name: 'ComentariosAlumnos',        component: ComentariosAlumnos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},  
    { path: '/numerovacantes',             name: 'NumeroVacantes',            component: NumeroVacantes,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},  
    { path: '/inscripciones',              name: 'Inscripciones',             component: Inscripciones,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 
    { path: '/relacionpagos',              name: 'RelacionPagos',             component: RelacionPagos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 
    { path: '/CorteDiario',                name: 'CorteDiario',               component: CorteDiario,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 
    { path: '/ValidarPagos',               name: 'ValidarPagos',              component: ValidarPagos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/validarpagossucursal',       name: 'ValidarPagosSucursal',      component: ValidarPagosSucursal,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/ajustepagos',                name: 'AjustePagos',               component: ()=> import('@/views/operaciones/inscripciones/AjustePagos.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/reporteajustes',             name: 'ReporteAjustes',            component: ()=> import('@/views/operaciones/ajustepagos/ReporteAjustes.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/reimpresionticket/:idpago',  name: 'ReimpresionTicket',         component: ()=> import('@/views/operaciones/ajustepagos/ReimpresionTicket.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/becasalumnos',               name: 'BecasAlumnos',              component: ()=> import('@/views/operaciones/ajustepagos/BecasAlumnos.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
      
    { path: '/recomendados',                name: 'Recomendados',             component: Recomendados,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 
    { path: '/recomendadosfinanzas',        name: 'RecomendadosFinanzas',     component: RecomendadosFinanzas,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 

    { path: '/transferirsaldo',             name: 'TransferirSaldo',          component: ()=> import('@/views/operaciones/ajustepagos/TransferirSaldo.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},

    // DESCUENTOS
    { path: '/descuentosalumno',        name: 'DescuentosAlumno',      component: ()=> import('@/views/operaciones/descuentos/DescuentosAlumno.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 
    { path: '/promociones',             name: 'Promociones',           component: ()=> import('@/views/operaciones/descuentos/Promociones.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 
    { path: '/promocionesalumno',       name: 'PromocionesAlumno',     component: ()=> import('@/views/operaciones/descuentos/PromocionesAlumno.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 

    // 
    { path: '/encuestapresencial',         name: 'EncuestaPresencial',        component: EncuestaPresencial,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},  
    { path: '/alumnoscalificaciones',      name: 'AlumnosCalificaciones',     component: AlumnosCalificaciones,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/seguimientoalumno',          name: 'SeguimientoAlumno',          component: ()=> import('@/views/operaciones/alumnos/SeguimientoAlumno.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/seguimientoni',              name: 'SeguimientoNI',              component: ()=> import('@/views/operaciones/alumnos/SeguimientoNI.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 
    

    // RH
    { path: '/registrarvacantes',          name: 'RegistrarVacantes',                       component: RegistrarVacantes,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 
    { path: '/entradassalidas/:fecha/:nombre',            name: 'EntradasSalidas',          component: EntradasSalidas,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 
    { path: '/nomina',                    name: 'Nomina',                                   component: Nomina,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 
    { path: '/nominaindividual',                  name: 'NominaIndividual',                 component: NominaIndividual,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/minomina',                          name: 'MiNomina',                         component: MiNomina,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/comentariosnomina',                 name: 'ComentariosNomina',                component: ComentariosNomina,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/minominateacher',                          name: 'MiNominaTeacher',           component: MiNominaTeacher,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
    { path: '/cargarentradascorporativo',            name: 'CargarEntradasCorporativo',     component: CargarEntradasCorporativo,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 
    { path: '/relojchecador',              name: 'RelojChecador',                           component: RelojChecador,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 
    { path: '/horasobservacion',           name: 'HorasObservacion',                        component: ()=> import('@/views/academico/HorasObservacion.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 
      
    { path: '/visualizadorentradas',       name: 'VisualizadorEntradas',      component: ()=> import('@/views/operaciones/rh/VisualizadorEntradas.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }}, 
    { path: '/organigramaprueba',          name: 'OrganigramaPrueba',         component: OrganigramaPrueba,           //ANGEL RODRIGUEZ
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/organigramav2',              name: 'OrganigramaV2',             component: OrganigramaV2,           //ANGEL RODRIGUEZ
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/comparativaerp',             name: 'ComparativaERP',            component: ComparativaERP,           //ANGEL RODRIGUEZ
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/comisionesindividuales',     name: 'ComisionesIndividuales',    component: ComisionesIndividuales,           //ANGEL RODRIGUEZ
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/autorizacionnomina',         name: 'AutorizacionNomina',        component: AutorizacionNomina,           //ANGEL RODRIGUEZ
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/actasadmin',                 name: 'ActasAdmin',                component: ()=> import('@/views/operaciones/rh/actas/ActasAdmin.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/misactasadmin',              name: 'MisActasAdmin',             component: ()=> import('@/views/operaciones/rh/actas/MisActasAdmin.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/faltasadmin',                name: 'FaltasAdmin',               component: ()=> import('@/views/operaciones/rh/actas/FaltasAdmin.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/sancionesadmin',             name: 'SancionesAdmin',            component: ()=> import('@/views/operaciones/rh/actas/SancionesAdmin.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/formulario',                 name: 'Formulario',                component: ()=> import('@/views/operaciones/rh/crm_rh/Formulario.vue'),
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/encargadas',                 name: 'Encargadas',                component: ()=> import('@/views/operaciones/rh/crm_rh/Encargadas.vue'),
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/publicidad',                 name: 'Publicidad',                component: ()=> import('@/views/operaciones/rh/crm_rh/Publicidad.vue'),
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/reclutadorapuesto',         name: 'ReclutadoraPuesto',          component: ()=> import('@/views/operaciones/rh/crm_rh/Reclutadora_Puesto.vue'),
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/examenmedico',              name: 'ExamenMedico',               component: ()=> import('@/views/operaciones/rh/crm_rh/Examen_Medico.vue'),
    	meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/festivos',                  name: 'Festivos',                   component: ()=> import('@/views/operaciones/rh/Festivos.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/vacaciones',                name: 'Vacaciones',                 component: ()=> import('@/views/operaciones/rh/Vacaciones.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/entrevistas',               name: 'Entrevistas',                component: ()=> import('@/views/operaciones/rh/Entrevistas.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/CambioHorario',             name: 'CambioHorario',              component: ()=> import('@/views/operaciones/rh/CambioHorario.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/CambiosHorarios',           name: 'CambiosHorarios',            component: ()=> import('@/views/operaciones/rh/CambiosHorarios.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/segvacantes',               name: 'SegVacantes',                component: ()=> import('@/views/operaciones/rh/entrevistas/SegVacantes.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/segprospectos',             name: 'SegProspectos',              component: ()=> import('@/views/operaciones/rh/entrevistas/SegProspectos.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/proyectosti',               name: 'ProyectosTi',                component: ()=> import('@/views/operaciones/ti/ProyectosTi.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/chatstwilio',               name: 'ChatsTwilio',                component: ()=> import('@/views/marketing/ChatsTwilio.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/chatstwiliofast',           name: 'ChatsTwilioFast',            component: ()=> import('@/views/marketing/ChatsTwilioFast.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},

    // FACTURAS
    { path: '/datosfiscales',              name: 'DatosFiscales',             component: ()=> import('@/views/operaciones/facturas/DatosFiscales.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/solicitarfactura',           name: 'SolicitarFactura',          component: ()=> import('@/views/operaciones/facturas/SolicitarFactura.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/facturassolicitadas',        name: 'FacturasSolicitadas',       component: ()=> import('@/views/operaciones/facturas/FacturasSolicitadas.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},


    { path: '/jefes', name: 'jefes', component: Jefes,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/auxiliares', name: 'auxiliares', component: Auxiliares,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/areas', name: 'areas', component: Areas,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/sucursales', name: 'sucursales', component: Sucursales,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},

    { path: '/tickets', name: 'tickets', component: Tickets,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/reportes', name: 'reportes', component: Reportes,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/reportes_tickets_institucion', name: 'ReporteTicketsPorInstitucion', component: ReporteTicketsPorInstitucion,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/reportes_tickets_area', name: 'ReporteTicketsPorArea', component: ReporteTicketsPorArea,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/reporte_estatus_area', name: 'ReporteEstatusPorArea', component: ReporteEstatusPorArea,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    
    { path: '/reporte_tickets_respuesta'     , name: 'ReporteTicketsRespuesta', component: ReporteTicketsRespuesta,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/addticket'                     , name: 'addticket'                , component: AddTicket,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/ticketarea'                    , name: 'ticketarea'               , component: TicketArea,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/ticketauxi'                    , name: 'ticketauxi'               , component: TicketAuxi,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/responderusuario/:id'          , name: 'responderusuario'         , component: ResponderUsuario,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/verhistorialticketusuario/:id' , name: 'verhistorialticketusuario', component: VerHistorialTicketUsuario,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/agregarticketrp/:id/:mensaje'  , name: 'agregarticketrp'          , component: AgregarTicketRP,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/mistickets'                    , name: 'mistickets'               , component: MisTickets,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/ticketsasignados'              , name: 'TicketsAsignados'         , component: TicketsAsignados,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/ticketsrh'                     , name: 'TicketsRh'                , component: ()=> import('@/views/tickets/operaciones/TicketsRh.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/ticketsRecepcionistas'         , name: 'ticketsRecepcionistas'        , component: ()=> import('@/views/tickets/operaciones/TicketsRecepcionistas.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},

    // Manuales
    { path: '/manuales'                      , name: 'Manuales'                 , component: Manuales,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/manualesteachers'              , name: 'ManualesTeachers'         , component: ManualesTeachers,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/manualeseecepcionista'         , name: 'ManualesRecepcionista'    , component: ManualesRecepcionista,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/reglamento'                      , name: 'Reglamento'                 , component: Reglamento,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},

    // LMs
    // { path: '/prospectoslms'        , name: 'prospectoslms'        , component: ProspectosLms,
    //   meta: { ADMIN: true }},
    { path: '/horarioslms'          , name: 'horarioslms'          , component: HorariosLMS,
      meta: { ADMIN: true }},
    { path: '/cicloslms'            , name: 'cicloslms'            , component: CiclosLMS,
      meta: { ADMIN: true }},
    { path: '/gruposlms'            , name: 'gruposlms'            , component: GruposLMS,
      meta: { ADMIN: true }},
    { path: '/grupoteacherlms'      , name: 'grupoteacherlms'      , component: GrupoTeacherLMS,
      meta: { ADMIN: true }},
    { path: '/teacherslms'          , name: 'teacherslms'          , component: TeachersLMS,
      meta: { ADMIN: true }},
    { path: '/usuarioslms'          , name: 'usuarioslms'          , component: UsuariosLMS,
      meta: { ADMIN: true }},
    { path: '/grupoalumnolms'       , name: 'grupoalumnolms'       , component: GrupoAlumnoLMS,
      meta: { ADMIN: true }},
    { path: '/gruposexcilms'        , name: 'GruposExciLMS'        , component: GruposExciLMS,
      meta: { ADMIN: true }},
    { path: '/alumnoserpviejo'      , name: 'AlumnosErpViejo'      , component: AlumnosErpViejo,
      meta: { ADMIN: true }},
    { path: '/habilitarplataforma'  , name: 'HabilitarPlataforma'  , component: HabilitarPlataforma,
      meta: { ADMIN: true }},
    { path: '/configurargrupo'      , name: 'ConfigurarGrupo'      , component: ()=> import('@/views/lms/soporte/ConfigurarGrupo.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    


    // Clases
    { path: '/inspeccionclase'         , name: 'InspeccionClase'         , component: InspeccionClase,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/calidadclasepreguntas'   , name: 'CalidadClasePreguntas'   , component: CalidadClasePreguntas,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/evaluacionclase'         , name: 'EvaluacionClase'         , component: EvaluacionClase,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/evaluacionclaseall'      , name: 'EvaluacionClaseAll'      , component: EvaluacionClaseAll,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/evaluacionclaseteacher'  , name: 'EvaluacionClaseTeacher'  , component: EvaluacionClaseTeacher,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/calificacionclase'       , name: 'CalificacionClase'       , component: CalificacionClase,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/calificaciongrupo'       , name: 'CalificacionGrupo'       , component: CalificacionGrupo,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/DashboardEncuesta'       , name: 'DashboardEncuesta'       , component: ()=> import('@/views/calidad/encuestas/DashboardEncuesta.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/encuestasalumnos'        , name: 'EncuestasAlumnos'       , component: ()=> import('@/views/calidad/EncuestasAlumnos.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},

    // CALIDAD
    { path: '/resultadosavance'        , name: 'ResultadosAvance'       , component: ()=> import('@/views/calidad/ResultadosAvance.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},

    // CRM PROSPECTOS
    // { path: '/etapas'         , name: 'Etapas'           , component: Etapas,
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/mediocontacto'  , name: 'MedioContacto'    , component: MedioContacto,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/anuncios'       , name: 'Anuncios'         , component: Anuncios,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/tareas'         , name: 'Tareas'           , component: Tareas,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/motivos'        , name: 'Motivos'          , component: Motivos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true }},
      
    { path: '/prospectos'           , name: 'Prospectos'            , component: Prospectos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/verprospecto'         , name: 'VerProspecto'          , component: VerProspecto,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    // { path: '/remarketing'          , name: 'Remarketing'           , component: Remarketing,
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    // { path: '/remarketingfast'      , name: 'RemarketingFast'       , component: RemarketingFast,
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/prospectosinscritos'  , name: 'ProspectosInscritos'   , component: ProspectosInscritos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    // { path: '/prospectosall'        , name: 'ProspectosAll'         , component: ProspectosAll,
    //   meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/prospectosMMK'        , name: 'ProspectosMMK'         , component: ProspectosMMK,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/prospectosmmkinbi'    , name: 'ProspectosMMKInbi'     , component: ProspectosMMKInbi,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/convencimiento'       , name: 'Convencimiento'        , component: Convencimiento,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/contactos'            , name: 'Contactos'             , component: Contactos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/fuentes'              , name: 'Fuentes'               , component: Fuentes,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/detallefuentes'       , name: 'DetalleFuentes'        , component: DetalleFuentes,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    
    { path: '/ledsfast'             , name: 'LedsFast'              , component: LedsFast,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/ledsinbi'             , name: 'LedsInbi'              , component: LedsInbi,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/dashboardventas'      , name: 'DashboardVentas'       , component: DashboardVentas,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},

    { path: '/cantidadprospectosvendedora'     , name: 'CantidadProspectosVendedora'     , component: CantidadProspectosVendedora,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/activarprospectos'          , name: 'ActivarProspectos'                    , component: ActivarProspectos,          //ANGEL RODRIGUEZ
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/cambiarvendedora'          , name: 'CambiarVendedora'                      , component: CambiarVendedora,           //ANGEL RODRIGUEZ
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/cambiarprospecto'          , name: 'CambiarProspecto'                      , component: CambiarProspecto,           //ANGEL RODRIGUEZ
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/dashboardmarketing'              , name: 'DashboardMarketing'              , component: DashboardMarketing,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/graficacontactos'                , name: 'GraficaContactos'                , component: ()=> import('@/views/marketing/GraficaContactos.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/cargaconmutador'                 , name: 'CargaConmutador'                 , component: ()=> import('@/views/crm-prospectos/prospectos/CargaConmutador.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/configuracionconmutador'         , name: 'ConfiguracionConmutador'         , component: ()=> import('@/views/crm-prospectos/prospectos/ConfiguracionConmutador.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/desglosellamadas'                , name: 'DesgloseLlamadas'                , component: ()=> import('@/views/crm-prospectos/prospectos/DesgloseLlamadas.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
	{ path: '/vendedorasmerca'                 , name: 'VendedorasMerca'                 , component: ()=> import('@/views/marketing/VendedorasMerca.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },
     { path: '/adminvacantes'                  , name: 'AdminVacantes'                   , component: ()=> import('@/views/operaciones/rh/AdminVacantes.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true } },

    // WHATSAPP ESTATUS
    { path: '/whatsappestatus'                 , name: 'WhatsAppEstatus'                 , component: ()=> import('@/views/crm-prospectos/whatsapp/WhatsAppEstatus.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},

    { path: '/controlregistroexci'     , name: 'ControlRegistroExci'     , component: ControlRegistroExci,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true, libre: true}},
    { path: '/usuariosexci'            , name: 'UsuariosExci'            , component: UsuariosExci,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true, libre: true}},
    { path: '/becainglesexci'          , name: 'BecaInglesExci'          , component: BecaInglesExci,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true, libre: true}},
    

    // Preguntas frecuentes

    { path: '/mispreguntas'         , name: 'MisPreguntas'         , component: MisPreguntas,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/preguntas'            , name: 'Preguntas'            , component: Preguntas,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/preguntasfrecuentes'  , name: 'PreguntasFrecuentes'  , component: PreguntasFrecuentes,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/preguntasusuario'     , name: 'PreguntasUsuario'     , component: PreguntasUsuario,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},

    // RIESGO
    { path: '/riesgo'           , name: 'Riesgo'            , component: Riesgo,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/puntosalumnos'    , name: 'PuntosAlumnos'     , component: PuntosAlumnos,
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},

    // HORARIOS APERTURA
    { path: '/horarioapertura', name: 'HorarioApertura',  component: ()=> import('@/views/usuario/HorarioApertura.vue'),
      meta: { ADMIN: true } },

    // PERMISOS
    { path: '/mispermisos'     , name: 'MisPermisos'  ,    component: ()=> import('@/views/operaciones/rh/permisos/MisPermisos.vue'),
      meta: { ADMIN: true } },
    { path: '/permisosarea'    , name: 'PermisosArea' ,    component: ()=> import('@/views/operaciones/rh/permisos/PermisosArea.vue'),
      meta: { ADMIN: true } },
    { path: '/listapermisos'   , name: 'ListaPermisos',    component: ()=> import('@/views/operaciones/rh/permisos/ListaPermisos.vue'),
      meta: { ADMIN: true } },

    // WHATSAPPPP
    { path: '/mensajeswhatsapp'                , name: 'MensajesWhatsApp'   , component: ()=> import('@/views/whatsapp/MensajesWhatsApp.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/mensajesniwha'                   , name: 'MensajesNIWha'      , component: ()=> import('@/views/whatsapp/MensajesNIWha.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/chatswhatsApp'                   , name: 'ChatsWhatsApp'      , component: ()=> import('@/views/whatsapp/ChatsWhatsApp.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/pruebaswha'                      , name: 'PruebasWha'         , component: ()=> import('@/views/whatsapp/PruebasWha.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},

    // EGRESOS
    { path: '/vernomina'      ,        name: 'VerNomina' ,             component: ()=> import('@/views/egresos/VerNomina.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/rentasucursales',        name: 'RentaSucursales' ,       component: ()=> import('@/views/egresos/RentaSucursales.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/pagoservicios',          name: 'PagoServicios' ,         component: ()=> import('@/views/egresos/PagoServicios.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/misrequisiciones',       name: 'MisRequisiciones' ,      component: ()=> import('@/views/egresos/MisRequisiciones.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/elementos',              name: 'Elementos' ,             component: ()=> import('@/views/egresos/Elementos.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/categoriaelementos',     name: 'CategoriaElementos' ,    component: ()=> import('@/views/egresos/CategoriaElementos.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/requisiciones',          name: 'Requisiciones' ,         component: ()=> import('@/views/egresos/Requisiciones.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/egresos',                name: 'Egresos' ,               component: ()=> import('@/views/egresos/Egresos.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/proyectos',              name: 'Proyectos' ,             component: ()=> import('@/views/egresos/Proyectos.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/cuentasescuela',         name: 'CuentasEscuela' ,        component: ()=> import('@/views/egresos/CuentasEscuela.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/servicios',              name: 'Servicios' ,             component: ()=> import('@/views/egresos/Servicios.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},
    { path: '/subirmisegresos',        name: 'SubirMisEgresos' ,       component: ()=> import('@/views/egresos/SubirMisEgresos.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},

    // PROSPECTOS DE RH
    { path: '/prospectosrh'                , name: 'ProspectosRh'                , component: ()=> import('@/views/operaciones/rh/ProspectosRh.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},


    // SISTEMA WEB
    { path: '/sistemaweb'                , name: 'SistemaWeb'                , component: ()=> import('@/views/academico/sistemaweb/SistemaWeb.vue'),
      meta: { ADMIN: true, USUARIO: true, NORMAL: true}},

    // TEACHERS LIDERES
    { path: '/bitacorateachers'   , name: 'BitacoraTeachers',    component: ()=> import('@/views/academico/BitacoraTeachers.vue'),
      meta: { ADMIN: true } },
    { path: '/bitacorafallas'     , name: 'BitacoraFallas'  ,    component: ()=> import('@/views/academico/BitacoraFallas.vue'),
      meta: { ADMIN: true } },


    { path: '/mispedidos'         , name: 'MisPedidos'         ,    component: ()=> import('@/views/tienda/MisPedidos.vue'),
      meta: { ADMIN: true } },
    { path: '/controlpedidos'     , name: 'ControlPedidos'     ,    component: ()=> import('@/views/tienda/ControlPedidos.vue'),
      meta: { ADMIN: true } },
    { path: '/comidas'            , name: 'Comidas'            ,    component: ()=> import('@/views/tienda/Comidas.vue'),
      meta: { ADMIN: true } },

    { path: '/encuestaambiente'      , name: 'EncuestaAmbiente'      ,    component: ()=> import('@/views/calidad/encuestas/EncuestaAmbiente.vue'),
      meta: { ADMIN: true } },
    { path: '/reporteambiente'       , name: 'ReporteAmbiente'       ,    component: ()=> import('@/views/calidad/encuestas/ReporteAmbiente.vue'),
      meta: { ADMIN: true } },
    { path: '/reportesatisfaccion'   , name: 'ReporteSatisfaccion'   ,    component: ()=> import('@/views/calidad/encuestas/ReporteSatisfaccion.vue'),
      meta: { ADMIN: true } },
    { path: '/faltanambiente'        , name: 'FaltanAmbiente'        ,    component: ()=> import('@/views/calidad/encuestas/FaltanAmbiente.vue'),
      meta: { ADMIN: true } },
    { path: '/faltansatisfaccion'    , name: 'FaltanSatisfaccion'    ,    component: ()=> import('@/views/calidad/encuestas/FaltanSatisfaccion.vue'),
      meta: { ADMIN: true } },

    { path: '/CampaniasInbi'    , name: 'CampaniasInbi'    ,    component: ()=> import('@/views/marketing/CampaniasInbi.vue'),
      meta: { ADMIN: true } },
    { path: '/CampaniasFast'    , name: 'CampaniasFast'    ,    component: ()=> import('@/views/marketing/CampaniasFast.vue'),
      meta: { ADMIN: true } },

    { path: '/EvaluarEmpleado'    , name: 'EvaluarEmpleado'    ,    component: ()=> import('@/views/operaciones/rh/evaluaciones/EvaluarEmpleado.vue'),
      meta: { ADMIN: true } },
    { path: '/MisEvaluaciones'    , name: 'MisEvaluaciones'    ,    component: ()=> import('@/views/operaciones/rh/evaluaciones/MisEvaluaciones.vue'),
      meta: { ADMIN: true } },

    { path: '/reglamentointerno'    , name: 'ReglamentoInterno'    ,    component: ()=> import('@/views/rh/ReglamentoInterno.vue'),
      meta: { ADMIN: true } },
    { path: '/desciptorpuesto'      , name: 'DesciptorPuesto'      ,    component: ()=> import('@/views/rh/DesciptorPuesto.vue'),
      meta: { ADMIN: true } },
    { path: '/misionvision'         , name: 'MisionVision'         ,    component: ()=> import('@/views/rh/MisionVision.vue'),
      meta: { ADMIN: true } },
    { path: '/culturaorg'           , name: 'CulturaOrg'           ,    component: ()=> import('@/views/rh/CulturaOrg.vue'),
      meta: { ADMIN: true } },
    { path: '/confidencialidad'     , name: 'Confidencialidad'     ,    component: ()=> import('@/views/rh/Confidencialidad.vue'),
      meta: { ADMIN: true } },
    { path: '/codigovestimenta'     , name: 'CodigoVestimenta'     ,    component: ()=> import('@/views/rh/CodigoVestimenta.vue'),
      meta: { ADMIN: true } },

  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.login.datosUsuario) {
    if (to.matched.some(record => record.meta.ADMIN)) {
      next()
    }
  } else {
    next({
      name: 'Login'
    })
  }
})

export default router