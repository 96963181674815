<template>
  <v-container class="mt-4 ml-2 pr-6" fluid>
    <v-row>
      <v-col cols="12">
        
        <v-card class="shadowCard">
          <v-card-title class="text-subtitle-1">
            Mis cambios
            <v-spacer></v-spacer>

            <v-btn 
              class="mr-2" 
              small 
              dark 
              color="green"
              @click="exportExcel( alumnos  , 'CAMBIOS')"
              tile
            >
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>


            <v-btn 
              class="mr-2" 
              small 
              dark 
              color="orange"
              @click="dialogAddCambios = true"
              tile
            >
              <!-- v-if="[334, 1, 28, 161, 382, 7, 526, 626, 568, 707 ].includes( getdatosUsuario.iderp )" -->
              <v-icon small left>mdi-plus</v-icon>
              Agregar
            </v-btn>
            
            <v-btn color="primary" tile small @click="getAlumnosxEscuela()">Actualizar</v-btn>

          </v-card-title>

          <v-card-text>

            <!-- Filtro de ciclos -->
            <v-row>
              <v-col cols="12">
                <v-radio-group
                  v-model="estatus"
                  row
                  dense
                  hide-details
                >
                  <v-badge bordered color="red" overlap :content="canAtrasados" class="mr-10">
                    <v-radio
                      label="Asignado"
                      value="Solicitado"
                    ></v-radio>
                  </v-badge>
                  <v-badge bordered color="red" overlap :content="cantRepondidos" class="mr-10">
                  <v-radio
                    label="Respondido"
                    value="Por aceptar"
                  ></v-radio>
                  </v-badge>
                  <v-badge bordered color="red" overlap :content="cantRechazados" class="mr-10">
                  <v-radio
                    label="Rechazado"
                    value="Rechazado"
                  ></v-radio>
                  </v-badge>
                  <v-badge bordered color="red" overlap :content="CantCerrados" class="mr-10">
                  <v-radio
                    label="Cerrado"
                    value="Aceptado"
                  ></v-radio>
                  </v-badge>
                  <v-badge bordered color="red" overlap :content="cantTodos" class="mr-10">
                  <v-radio
                    label="Todos"
                    value="Todos"
                  ></v-radio>
                  </v-badge>
                </v-radio-group>
              </v-col>


              <v-col cols="12" md="4" >
                <v-text-field
                  label="Fecha inicio"
                  v-model="fechaini"
                  clearable
                  type="date"
                  filled
                  single-line
                  hide-details
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" >
                <v-text-field
                  name="name"
                  label="Fecha final"
                  v-model="fechafin"
                  clearable
                  type="date"
                  filled
                  single-line
                  hide-details
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-text-field
                  label="Buscar"
                  filled
                  dense
                  append-icon="mdi-magnify"
                  v-model="search"
                  single-line
                ></v-text-field>
              </v-col>
            </v-row>

  			    <v-data-table
  			      :headers="headers"
  			      :items="filterAlumnos"
  			      :search="search"
  			    >
              <template v-slot:item.estatus_nombre="{ item }">
                <v-chip v-if="item.estatus_nombre == 'Solicitado'"  small color="blue"    dark>Asignado</v-chip>
                <v-chip v-if="item.estatus_nombre == 'Por aceptar'" small color="green"   dark>Respondido</v-chip>
                <v-chip v-if="item.estatus_nombre == 'Rechazado'"   small color="black"   dark>Rechazado</v-chip>
                <v-chip v-if="item.estatus_nombre == 'Aceptado'"    small color="yellow"      >Cerrado</v-chip>
              </template>

              <template v-slot:item.tipo_reporte="{ item }">
                <v-chip v-if="item.tipo_reporte == 7" small dark>Cambio de comprobante</v-chip>
                <v-chip v-else-if="item.tipo_reporte == 4" small dark>Cambio en método de pago</v-chip>
                <v-chip v-else-if="item.tipo_reporte == 2" small dark>Cambio en monto pagado</v-chip>
                <v-chip v-else-if="item.tipo_reporte == 9" small dark>Cambios múltiples</v-chip>
                <v-chip v-else-if="item.tipo_reporte == 10" small dark>Cambio de curso</v-chip>
                <v-chip v-else small dark>Cambio de grupo</v-chip>
              </template>

              <template v-slot:item.opciones="{ item }">
                <v-chip small color="orange" dark @click="verCambio(item)">Ver</v-chip>
              </template>
  			    </v-data-table>
          </v-card-text>
    		</v-card>
    	</v-col>
    </v-row>

    <v-dialog
      v-model="dialogRechazar"
      hide-cargar
      persistent
      max-width="800"
    >
      <v-card
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Solicitud rechazada
        </v-card-title>
        <v-card-text>
          <v-textarea
          	v-model="mensajeRechazo"
          	outlined
          	dense
          	label="Escribe la razón de el rechazo"
          >	
          </v-textarea>
        </v-card-text>
        <v-btn 
        	color="transparent elevation-0"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogRechazar = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogMensajes"
      hide-cargar
      persistent
      max-width="800"
    >
      <v-card
        style="position: relative"
      >
        <v-card-title primary-title >
          Agregar nota
        </v-card-title>
        <v-card-text>
        	<span v-for="(mensaje, i) in mensajes">
		        <v-row v-if="mensaje.envia == 1">
		        	<v-col cols="12">
				        <v-card class="ml-6 pa-3 mt-2" max-width="400" color="#f2f2f2" style="border-radius: 15px; border-top-left-radius: 0">
				          {{ mensaje.mensaje }}
				        </v-card>
		        	</v-col>
		        </v-row>

		        <v-row v-if="mensaje.envia == 0">
		        	<v-col cols="12" align="right">
				        <v-card class="mr-6 mt-2 pa-3" max-width="400" dark color="#0465ac" shaped style="border-radius: 15px; border-top-right-radius: 0;">
				          {{ mensaje.respuesta }}
				        </v-card>
		        	</v-col>
		        </v-row>
        	</span>
        </v-card-text>

        <v-card-text class="pb-0 pt-6">
        	<v-text-field
            v-model="mensajeRechazo"
            :append-outer-icon="mensajeRechazo ? 'mdi-send' : ''"
            outlined
            clearable
            label="Message"
            type="text"
            @click:append-outer="enviarMensaje()"
            hide-details
            dense
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialogMensajes = false, dialogUpdateCambio = true">Cancelar</v-btn>
          <v-btn color="success" text @click="enviarMensaje()">Enviar</v-btn>
        </v-card-actions>
        <v-btn 
        	color="transparent elevation-0"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogMensajes = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>


    <!-- Dialog para agregar un cambio -->
    <v-dialog
      v-model="dialogAddCambios"
      persistent
      max-width="1000"
    >
      <v-card class="shadowCard">

        <v-card-title class="text-h6 font-weight-regular justify-space-between">
          <span>{{ currentTitle }}</span>
          <v-avatar
            color="primary lighten-2"
            class="subheading white--text"
            size="24"
            v-text="step"
          ></v-avatar>
        </v-card-title>

        <v-window v-model="step">
          <v-window-item :value="1">
            <v-card>
              <SeleccionarAlumno @addDialogCambio="addDialogCambio"/>
            </v-card>
          </v-window-item>

          <v-window-item :value="2">
            <v-card-text>

              <div v-if="alumno">
                <b>Matricula:</b> {{ alumno.matricula }}
                <br/>
                <b>Nombre:</b> {{ alumno.alumno }}
              </div>

              <v-radio-group v-model="tipo_reporte">
                <v-radio
                  v-for="(tipo, i) in tiposReporte"
                  :key="i"
                  :label="`${tipo.texto}`"
                  :value="tipo.id"
                ></v-radio>
              </v-radio-group>
            </v-card-text>
          </v-window-item>

          <v-window-item :value="3">

            <!-- Cambio de grupo -->
            <CambioGrupo
              v-if="tipo_reporte == 8"
              @addDialogCambio="addDialogCambio"
              @getAlumnosxEscuela="getAlumnosxEscuela"
              :alumno="alumno"
              :tipo_reporte="tipo_reporte"
              :reporteTexto="reporteTexto"
            />

            <!-- Cambio de comprobante -->
            <CambioComprobante
              v-if="tipo_reporte == 7"
              @addDialogCambio="addDialogCambio"
              @getAlumnosxEscuela="getAlumnosxEscuela"
              :alumno="alumno"
              :tipo_reporte="tipo_reporte"
              :reporteTexto="reporteTexto"
            />

            <!-- CambioMetodo -->
            <CambioMetodo
              v-if="tipo_reporte == 4"
              @addDialogCambio="addDialogCambio"
              @getAlumnosxEscuela="getAlumnosxEscuela"
              :alumno="alumno"
              :tipo_reporte="tipo_reporte"
              :reporteTexto="reporteTexto"
            />

            <!-- CambioMonto -->
            <CambioMonto
              v-if="tipo_reporte == 2"
              @addDialogCambio="addDialogCambio"
              @getAlumnosxEscuela="getAlumnosxEscuela"
              :alumno="alumno"
              :tipo_reporte="tipo_reporte"
              :reporteTexto="reporteTexto"
            />

            <!-- CambioFecha -->
            <CambioFecha
              v-if="tipo_reporte == 11"
              @addDialogCambio="addDialogCambio"
              @getAlumnosxEscuela="getAlumnosxEscuela"
              :alumno="alumno"
              :tipo_reporte="tipo_reporte"
              :reporteTexto="reporteTexto"
            />

            <!-- Cambio de grupo -->
            <CambiosMultiples
              v-if="tipo_reporte == 9"
              @addDialogCambio="addDialogCambio"
              @getAlumnosxEscuela="getAlumnosxEscuela"
              :alumno="alumno"
              :tipo_reporte="tipo_reporte"
              :reporteTexto="reporteTexto"
            />

            <!-- Cambio de curso -->
            <CambiosCurso
              v-if="tipo_reporte == 10"
              @addDialogCambio="addDialogCambio"
              @getAlumnosxEscuela="getAlumnosxEscuela"
              :alumno="alumno"
              :tipo_reporte="tipo_reporte"
              :reporteTexto="reporteTexto"
            />

          </v-window-item>
        </v-window>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            v-if="step === 1"
            color="black"
            dark
            small
            tile
            @click="dialogAddCambios = false"
          >
            <v-icon left small>mdi-cancel</v-icon>
            Cancelar
          </v-btn>

          <v-btn
            :disabled="step === 1"
            color="black"
            dark
            small
            tile
            @click="step--"
          >
            Regresar
          </v-btn>
          <v-spacer></v-spacer>
          <!-- <v-btn
            v-if="step == 3"
            color="primary"
            depressed
            @click="confirmarReporte()"
          >
            Confirmar reporte
          </v-btn> -->
        </v-card-actions>     
      </v-card>
    </v-dialog>

    <!-- Cambio de grupo -->
    <CambioGrupoF
      v-if="alumno && paramsDialogCambio.estatus"
      :alumno="alumno"
      :paramsDialogCambio="paramsDialogCambio"
      @getAlumnosxEscuela="getAlumnosxEscuela"
      :reporteTexto="reporteTexto"
    />

    <!-- Cambio de comprobante de pago -->
    <CambioComprobanteF
      v-if="alumno && alumno.tipo_reporte == 7 && paramsDialogComprobante.estatus"
      :alumno="alumno"
      :paramsDialogComprobante="paramsDialogComprobante"
      @getAlumnosxEscuela="getAlumnosxEscuela"
      :reporteTexto="reporteTexto"
    />

    <CambioMetodoF
      v-if="alumno && alumno.tipo_reporte == 4 && paramsDialogMetodo.estatus"
      :alumno="alumno"
      :paramsDialogMetodo="paramsDialogMetodo"
      @getAlumnosxEscuela="getAlumnosxEscuela"
      :reporteTexto="reporteTexto"
    />

    <CambioMontoF
      v-if="alumno && alumno.tipo_reporte == 2 && paramsDialogMonto.estatus"
      :alumno="alumno"
      :paramsDialogMonto="paramsDialogMonto"
      @getAlumnosxEscuela="getAlumnosxEscuela"
      :reporteTexto="reporteTexto"
    />

    <CambioFechaF
      v-if="alumno && alumno.tipo_reporte == 5 && paramsDialogFecha.estatus"
      :alumno="alumno"
      :paramsDialogFecha="paramsDialogFecha"
      @getAlumnosxEscuela="getAlumnosxEscuela"
      :reporteTexto="reporteTexto"
    />

    <CambiosMultiplesF
      v-if="alumno && alumno.tipo_reporte == 9 && paramsDialogMultiple.estatus"
      :alumno="alumno"
      :paramsDialogMultiple="paramsDialogMultiple"
      @getAlumnosxEscuela="getAlumnosxEscuela"
      :reporteTexto="reporteTexto"
    />

    <CambiosCursoF
      v-if="alumno && alumno.tipo_reporte == 10 && paramsDialogCurso.estatus"
      :alumno="alumno"
      :paramsDialogCurso="paramsDialogCurso"
      @getAlumnosxEscuela="getAlumnosxEscuela"
      :reporteTexto="reporteTexto"
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>


  </v-container>
</template>
<script>
  import axios from 'axios';
	var moment = require('moment');
  moment.locale();
	import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import SeleccionarAlumno     from '@/components/cambios/SeleccionarAlumno.vue';
  import CambioGrupo           from '@/components/cambios/CambioGrupo.vue';
  import CambioGrupoF          from '@/components/cambios/CambioGrupoF.vue';
  import CambioComprobante     from '@/components/cambios/CambioComprobante.vue';
  import CambioComprobanteF    from '@/components/cambios/CambioComprobanteF.vue';
  import CambioMetodo          from '@/components/cambios/CambioMetodo.vue';
  import CambioMetodoF         from '@/components/cambios/CambioMetodoF.vue';
  import CambioMonto           from '@/components/cambios/CambioMonto.vue';
  import CambioMontoF          from '@/components/cambios/CambioMontoF.vue';
  import CambioFecha           from '@/components/cambios/CambioFecha.vue';
  import CambioFechaF          from '@/components/cambios/CambioFechaF.vue';
  import CambiosMultiples      from '@/components/cambios/CambiosMultiples.vue';
  import CambiosMultiplesF     from '@/components/cambios/CambiosMultiplesF.vue';
  import CambiosCurso          from '@/components/cambios/CambiosCurso.vue';
  import CambiosCursoF         from '@/components/cambios/CambiosCursoF.vue';

  // import CambiosFecha          from '@/components/cambios/CambiosFecha.vue';
  // import CambiosCursoF         from '@/components/cambios/CambiosCursoF.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    components:{
      Alerta,
      carga,
      SeleccionarAlumno,
      CambioGrupo,
      CambioGrupoF,
      CambioComprobante,
      CambioComprobanteF,
      CambioMetodo,
      CambioMetodoF,
      CambioMonto,
      CambioMontoF,
      CambioFecha,
      CambioFechaF,
      CambiosMultiples,
      CambiosMultiplesF,
      CambiosCurso,
      CambiosCursoF
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search: '',
      headers: [
        { text: 'ID'           ,   value: 'idsolicitud_cambio' },
        { text: 'Matricula'    ,   value: 'matricula'          },
        { text: 'Nombre'       ,   value: 'alumno'             },
        { text: 'Tipo reporte' ,   value: 'tipo_reporte'       },
        { text: 'Estatus'      ,   value: 'estatus_nombre'     },
        { text: 'Solicitó'     ,   value: 'nombre_completo'    },
        { text: 'Fecha'        ,   value: 'fecha_solicitud'    },
        { text: 'Opciones'     ,   value: 'opciones'           },
      ],

      headers2: [
        { text: 'Matricula',   value: 'matricula' },
        { text: 'Nombre',      value: 'alumno' },
        { text: 'Grupo',       value: 'grupo' },
        { text: 'Opciones',    value: 'opciones' },
      ],
      teachers: [],

      dialogError: false,
      error:'',

      ciclos:[],
      ciclo:{},
      idciclo:0,

      tipociclo:'',
      grupos:[],
      nuevoGrupo:null,
      tipocambios:[],
      tipoCambio:null,
      alumnos:[],

      step: 1,

      dialogUpdateCambio: false,
      alumno:null,
      dialogSuccess:false,
      successMensaje:'',
      dialogRechazar:false,
      mensajeRechazo:'',
      dialogMensajes: false,
      mensajes:[],
      dialogAddCambios: false,

      teachers: [],

      grupoAlumnos:   [],
      
      dialogAddCambio:false,
      loading:        false,
      disabled:       false,
      motivo:         null,

      alumnos2:[],

      dataGrupoActual: null,
      dataNuevoGrupo:null,
      precioSeleccionado2: null,
      descuentoHermanos:0,


      tiposReporte:[
        { id: 8,  texto: 'Cambio de grupo'},
        { id: 7,  texto: 'Cambio de comprobante'},
        { id: 4,  texto: 'Cambio en método de pago'},
        { id: 2,  texto: 'Cambio en monto pagado'},
        { id: 9,  texto: 'Cambio multiple'},
        { id: 10, texto: 'Cambio de curso'},
        { id: 11, texto: 'Cambio de fecha de pago'},

        // { id: 1, texto: 'Pago duplicado'},
        // { id: 3, texto: 'Error en costo de curso'},
        // { id: 5, texto: 'Cambio de fecha'},
        // { id: 6, texto: 'Baja de alumno en el grupo'},
      ],
      tipo_reporte:null,
      selected:[], 

      url:'',

      fotos:[],
      files: [],
      vistaPrevias:[],

      imagen: null,
      dialogImagen: false,

      paramsDialogCambio: {
        estatus: false
      },

      paramsDialogComprobante: {
        estatus: false
      },

      paramsDialogMetodo: {
        estatus: false
      },

      paramsDialogMonto: {
        estatus: false
      },

      paramsDialogFecha: {
        estatus: false
      },

      paramsDialogMultiple: {
        estatus: false
      },

      paramsDialogCurso: {
        estatus: false
      },

      estatus: 'Todos',
      fechaini: null,
      fechafin: null,

    }),

    watch:{
      nuevoGrupo( value ){
        this.precioSeleccionado = value ? value.id_curso : null
        console.log( value )
      },

      dataNuevoGrupo( value ){
        this.precioSeleccionado = value ? value.id_curso : null
        console.log( value )
      },

      tipo_reporte( val ){
        if( val ){ this.step += 1 }
      }
    },

    async created () {
      console.clear()

      this.url = axios.defaults.baseURL + 'evidencia-cambios/'
      // Ahora traemos los alumnos por escuela
      await this.getAlumnosxEscuela()

      // Primero necesitamos consultar el tipo de usuario y de donde viene
      await this.getTipoUsuarioEscuela()

      // Ahora si consultamos los grupos por tipo de escuela
      // await this.getGrupos()

      // Ahora traemos los tipos de cambio
      await this.getTitpoCambios()
      
      // Ahora traemos los alumnos por escuela
      await this.getAlumnosxEscuela2()

    },

    methods: {

      getAlumnosxEscuela () {
        this.limpiar()
      	this.cargar = true
        return this.$http.get('cambios.usuario/' + this.getdatosUsuario.iderp).then(response=>{
          console.log( response.data )
          this.alumnos = response.data
          this.cargar = false
        }).catch(error=>{
          console.log(error)
          this.loading = false
        })
      },

      verCambio (alumno) {
        console.log( alumno )
        this.alumno = alumno
        if( alumno.tipo_reporte == 7 ){ this.paramsDialogComprobante.estatus = true }
        else if( alumno.tipo_reporte == 4 ){ this.paramsDialogMetodo.estatus = true }
        else if( alumno.tipo_reporte == 2 ){ this.paramsDialogMonto.estatus = true }
        else if( alumno.tipo_reporte == 5 ){ this.paramsDialogFecha.estatus = true }
        else if( alumno.tipo_reporte == 9 ){ this.paramsDialogMultiple.estatus = true }
        else if( alumno.tipo_reporte == 10 ){ this.paramsDialogCurso.estatus = true }
        else if( alumno.tipo_reporte == 11 ){ this.paramsDialogFecha.estatus = true }
        else{
          this.paramsDialogCambio.estatus = true
        }
      },

      getTipoUsuarioEscuela () {
        if([1,2,3,4,5,6,17,19,21,22,23,24,25,26].includes( this.getdatosUsuario.id_plantel ) ){
          this.tipociclo = `grupo NOT LIKE '%FE%'`
        }else{ this.tipociclo = `grupo LIKE '%FE%'` }

        console.log( this.tipociclo )
        return 
      },

      getTitpoCambios () {
        this.cargar = true
        return this.$http.get('cambios.tipo.cambio').then(response=>{
          this.tipocambios = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getAlumnosxEscuela2 () {
        // Vaciamos el arreglo de los grupos
        this.alumnos2 = []
        // Activamos el loader
        this.cargar = true
        const payload =  { tipociclo: this.tipociclo }
        return this.$http.post('cambios.alumnos.grupos',payload).then(response=>{
          // Llenamos el arreglo de alumnos
          this.alumnos2 = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      cargarMensajes () {
      	this.$http.get('cambios.mensaje/' + this.alumno.idsolicitud_cambio).then(response=>{
        	this.mensajes = response.data
        	this.dialogMensajes = true
        	this.dialogUpdateCambio = false
        	this.cargar = false
        }).catch(error=>{
          this.error = error.body.message
          this.dialogError = true
          this.cargar = false
        	this.dialogRechazar = false
        })
      },

      getAhora () {
      	let fecha = new Date().toLocaleString();
    		fecha = moment(fecha).format().replace('T',' ').substr(0,19)
    		return fecha
      },

      async addDialogCambio (alumno) {
        await this.getGrupoUsuario(alumno.id_alumno)
        this.alumno = alumno
        this.step += 1
        // this.dialogAddCambio = true
      },

      getGrupoUsuario ( idalumno ){
        this.cargar = true
        this.grupoAlumnos = []
        return this.$http.get('cambios.grupo.usuario/' + idalumno).then(response=>{
          this.grupoAlumnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      limpiar () {
        this.paramsDialogMetodo.estatus      = false
        this.paramsDialogCambio.estatus      = false
        this.paramsDialogComprobante.estatus = false
        this.paramsDialogMonto.estatus       = false
        this.paramsDialogCurso.estatus       = false

        this.loading              = false
        this.disabled             = false
        this.nuevoGrupo           = null
        this.tipoCambio           = null
        this.overlay              = false
        this.dialogAddCambio      = false
        this.grupos               = []
        this.grupoAlumnos         = []
        this.motivo               = null
        this.dialogAddCambios     = false
        this.step                 = 1
        this.tipo_reporte         = null
      },

      verImagen( imagen, es_solucion ){
        this.imagen       = imagen
        this.dialogImagen = true
      },

    },

    computed:{
      ...mapGetters ('login',['getdatosUsuario']),

      currentTitle () {
        switch (this.step) {
          case 1: return 'Selecciona el alumno'
          case 2: return 'Selecciona tipo de reporte'
          default: return 'Llenado de datos'
        }
      },

      reporteTexto ( ){
        return '' // this.tiposReporte.find( el => el.id == this.alumno.tipo_reporte ).texto
        // return this.tiposReporte.find( el => el.id == this.tipo_reporte ).texto
      },

      canAtrasados( ){
        return this.alumnos.filter( el => el.estatus_nombre == 'Solicitado').length
      },

      cantRepondidos( ){
        return this.alumnos.filter( el => el.estatus_nombre == 'Por aceptar').length
      },

      cantRechazados( ){
        return this.alumnos.filter( el => el.estatus_nombre == 'Rechazado').length
      },

      CantCerrados( ){
        return this.alumnos.filter( el => el.estatus_nombre == 'Aceptado').length
      },

      cantTodos( ){
        return this.alumnos.length
      },


      filterAlumnos( ){

        let datos = this.alumnos

        if( this.estatus != 'Todos' ){
          datos = datos.filter( el => el.estatus_nombre == this.estatus )
        }

        // Ahora veremos si hay fechas
        if( this.fechaini && !this.fechafin ){
          datos = datos.filter( el => el.fecha_solicitud.substr(0,10) == this.fechaini )
        }


        if( this.fechaini && this.fechafin ){
          datos = datos.filter( el => el.fecha_solicitud.substr(0,10) >= this.fechaini && el.fecha_solicitud.substr(0,10) <= this.fechafin )
        }

        return datos
      }
    }
  }
</script>

<style>

  .v-menu__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-menu__content::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-button:increment,.v-menu__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-menu__content::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>