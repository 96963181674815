<template>
	<v-container class="mt-4 px-5" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Grupos</span>
	  		    <v-spacer></v-spacer>

	  		    <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( filterGrupos.filter( el => el.activo_sn == 1 )  , 'GRUPOS_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

	  		   <v-btn
              color="black"
              dark
              class="mr-2"
              @click="dialogGruposSiguientes = true"
              small
              tile
            >

              <v-icon small left>mdi-page-next</v-icon>
              Grupo Siguiente
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>  		    
	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<!-- Seleccionar el plantel -->
							<v-col cols="12" md="4" lg="3">
	  		  			<v-autocomplete
	  		  			  label="Plantel"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  v-model="plantel"
	  		  			  :items="planteles"
	  		  			  item-text="plantel"
	  		  			  item-value="id_plantel"
	  		  			  clearable
	  		  			></v-autocomplete>
	  		  		</v-col> 		  		
	  		  		<!-- Seleccionar el ciclo -->
	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-autocomplete
	  		  			  label="Ciclo"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  v-model="ciclo"
	  		  			  :items="ciclos"
	  		  			  item-text="ciclo"
	  		  			  item-value="id_ciclo"
	  		  			  clearable
	  		  			></v-autocomplete>
	  		  		</v-col>
	  		  		<v-spacer></v-spacer>
	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			  clearable
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="filterGrupos"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
			            id="grupos"
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip 
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>
							  

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2 mb-1"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="error" 
							    		x-small
							    		@click="deleteItem(item)"
							    		class="mr-2 mb-1"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="blue" 
							    		x-small
							    		dark
							    		@click="grupoZoom = item, dialogZoom = true"
							    		class="mr-2 mb-1"
							    	>
								      <v-icon small>mdi-file-video</v-icon>
							    	</v-btn>

							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="1200px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.id_grupo }}</strong>
          </span>
        </v-card-title>
        <v-tabs vertical v-model="tabs">
        	<!-- Grupo -->
		      <v-tab class="text-capitalize" >
		        <v-icon left>
		          mdi-home
		        </v-icon>
		        Grupo
		      </v-tab>
		      <!-- Descuento -->
		      <v-tab class="text-capitalize" v-if="editedIndex != -1">
		        <v-icon left>
		          mdi-percent-outline
		        </v-icon>
		        Descuento
		      </v-tab>
		      <!-- Maestros -->
		      <v-tab class="text-capitalize" v-if="editedIndex != -1">
		        <v-icon left>
		          mdi-school-outline
		        </v-icon>
		        Maestros
		      </v-tab>
		      <!-- Maestros -->
		      <v-tab class="text-capitalize" v-if="editedIndex != -1">
		        <v-icon left>
		          mdi-account
		        </v-icon>
		        Alumnos
		      </v-tab>

		      <!-- Siguiente grupo -->
		      <v-tab class="text-capitalize" v-if="editedIndex != -1">
		        <v-icon left>
		          mdi-skip-next
		        </v-icon>
		        Siguiente
		      </v-tab>

		      <!-- información del grupo -->
		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
		          	<InfoGrupo  v-if="tabs == 0" :editedItem="editedItem" @click="initialize()"/> 
			        </v-card-text>
		        </v-card>
		      </v-tab-item>

		      <!-- Descuentos -->
		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
		            <DescuentoGrupo  v-if="tabs == 1" :editedItem="editedItem"/>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>

		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
		            <MaestrosGrupo v-if="tabs == 2" :editedItem="editedItem"/>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>

		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
		            <AlumnosGrupo  v-if="tabs == 3" :editedItem="editedItem"/>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>

		      <v-tab-item>
		        <v-card flat>
		          <v-card-text class="pt-0">
		            <SiguienteGrupo  v-if="tabs == 4" :editedItem="editedItem" class="pt-0"/>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>
		    </v-tabs>

        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogGruposSiguientes" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogGruposSiguientes"
      persistent
      max-width="1200"
    >
      <v-card>
        <v-card-title class="text-h5">
          Grupos siguientes

          <v-spacer></v-spacer>
          <v-btn 
          	color="green"
          	dark
          	small
          	tile
          	class="mr-2"
          	@click="getGruposSiguientes"
          >
        		Actualizar
        	</v-btn>
          <v-btn 
          	color="primary" 
          	small 
          	rounded 
          	@click="procesarGrupos"
          >
        		Procesar
        	</v-btn>
        </v-card-title>
        <v-card-text>
        	<v-row justify="end">
  		  		<!-- Seleccionar el ciclo -->
  		  		<v-col cols="12" md="4" lg="3">
  		  			<v-autocomplete
  		  			  label="Ciclo"
  		  			  id="id"
  		  			  filled
  		  			  dense
  		  			  hide-details
  		  			  v-model="cicloSiguiente"
  		  			  :items="ciclosSiguientes"
  		  			  item-text="ciclo"
  		  			  item-value="id_ciclo"
  		  			  clearable
  		  			  return-object
  		  			></v-autocomplete>
  		  		</v-col>
  		  		<v-spacer></v-spacer>
  		  		<v-col cols="12" md="6" lg="4">
  		  			<v-text-field
  		  			  name="name"
  		  			  label="Buscar"
  		  			  id="id"
  		  			  filled
  		  			  dense
  		  			  hide-details
  		  			  append-icon="mdi-magnify"
  		  			  v-model="searchSiguiente"
  		  			  clearable
  		  			></v-text-field>
  		  		</v-col>
  		  	</v-row>
          <v-data-table
            :headers="headersGruposSiguientes"
            :items="gruposSiguientes"
            class="elevation-0 mt-4"
            dense
            :search="searchSiguiente"
          >
          	<template v-slot:item.estatus="{ item }">
				      <v-chip 
								small
				      	:color="item.estatus ? 'green' : 'red' "
				      	dark
				      >
				      	{{ item.estatus ? 'Alta' : 'NO ALTA' }}
				      </v-chip>
				    </template>
						<!-- v-if="item.estatus == 0 && item.cant_alumnos > 0" -->
				    <template v-slot:item.actions="{ item }">
				      <v-chip 
				    
								small
				      	color="primary"
				      	dark
				      	@click="procesarGrupos( item )"
				      >
				      	Agregar
				      </v-chip>
				    </template>

            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initialize"
                small
              >
                Actualizar
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            rounded
            dark
            small
            @click="dialogGruposSiguientes = false"
          >
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogZoom"
      persistent
      max-width="400"
      v-if="grupoZoom"
    >
      <v-card>
        <v-card-title class="text-h5">
          Agregar codigos al grupo
        </v-card-title>
        <v-card-text>
	  			<v-textarea
	  				v-model="grupoZoom.codigos"
	  				rows="3"
	  				auto-grow
	  				label="Agregar acceso al zoom"
	  				filled
	  				hide-details
	  			/>
        </v-card-text>
        <v-card-actions>
        	<v-btn
            color="black"
            tile
            dark
            small
            @click="dialogZoom = false"
          >
          	<v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            tile
            dark
            small
            @click="saveCodigoZoom()"
          >
          	<v-icon small left>mdi-content-save</v-icon>
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';
  import AlumnosGrupo    from '@/components/grupos/AlumnosGrupo.vue';
  import MaestrosGrupo   from '@/components/grupos/MaestrosGrupo.vue';
  import DescuentoGrupo  from '@/components/grupos/DescuentosGrupo.vue';
  import InfoGrupo       from '@/components/grupos/InfoGrupo.vue';
  import SiguienteGrupo  from '@/components/grupos/SiguienteGrupo.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
      AlumnosGrupo,
      MaestrosGrupo,
      DescuentoGrupo,
      InfoGrupo,
      SiguienteGrupo
    },

    data: () => ({
    	tabs: 0, 
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,


      grupoZoom: null,
			dialogZoom: false,

      editedItem: {
        id_grupo:0,
				forma_pago:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
      },

      defaultItem: {
        id_grupo:0,
				forma_pago:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
      },

      // Filtros: 
      incio:null,
			fin:null,
			plantel: null,
			ciclo: null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      grupos: [],
      headers: [
        { text: 'ID'        , value: 'id_grupo'    },
        { text: 'Grupo'     , value: 'grupo'       },
        { text: 'Plantel'   , value: 'plantel'     },
        { text: 'Curso'     , value: 'curso'       },
        { text: 'Ciclo'     , value: 'ciclo'       },
        { text: 'Horario'   , value: 'horario'     },
        { text: 'Salón'     , value: 'salon'       },
        { text: 'Nivel'     , value: 'nivel'       },
        { text: 'Precio'    , value: 'precio'      },
        { text: 'Cupo'      , value: 'cupo'        },
        { text: 'Activo'    , value: 'activo_sn'   },
        { text: 'Alta'      , value: 'fecha_alta'  },
        { text: 'Baja'      , value: 'fecha_baja'  },
        { text: 'Actions'   , value: 'actions', sortable: false },
      ],

      
      headersGruposSiguientes: [
        { text: 'ID'        , value: 'id_grupo'     },
        { text: '#alumnos'  , value: 'cant_alumnos' },
        { text: 'Grupo'     , value: 'grupo_viejo'  },
        { text: 'Siguiente' , value: 'grupo'        },
        { text: 'Activo'    , value: 'activo_sn'    },
        { text: 'Estatus'   , value: 'estatus'      },
        { text: 'Actions'   , value: 'actions', sortable: false },
      ],

      gruposSiguientes:[],
      searchSiguiente:'',

      ciclos:[],
			salones:[],
			cursos:[],
			planteles:[],
			horarios:[],
			niveles:[],
			dialogGruposSiguientes: false,
			cicloSiguiente: null,
			ciclosSiguientes:[]
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Grupo' : 'Editar Grupo'
      },

      filterGrupos( ){
      	if( this.plantel && !this.ciclo ){
      		return this.grupos.filter( el=> { return el.id_plantel == this.plantel })
      	}

      	if( this.plantel && this.ciclo){
      		return this.grupos.filter( el=> { return el.id_plantel == this.plantel && el.id_ciclo == this.ciclo })
      	}

      	if( !this.plantel && this.ciclo){
      		return this.grupos.filter( el=> { return el.id_ciclo == this.ciclo })
      	}

      	if( !this.plantel && !this.ciclo){
      		return this.grupos
      	}
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      tabs( val ) { console.log( val ) },

      cicloSiguiente( val ){ if( val ){ this.getGruposSiguientes() }  },


      ciclo( ){
      	if( this.ciclo ){
      		this.initialize()
      	}
      }

    },

    async created () {
      // await this.initialize()
      await this.getCiclos()
      await this.getPlanteles()
      await this.getCiclosDisponibles()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.grupos = []
        let payload = {
        	id_ciclo: this.ciclo
        }
        return this.$http.post('grupos.list', payload).then(response=>{
        	this.grupos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCiclosDisponibles () {
      	this.cargar = true
        this.ciclosSiguientes = []
        this.$http.get('ciclos.activos.erp').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclosSiguientes.push(response.data[i])
              }
            }
          }
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCiclos () {
      	this.cargar = true
        this.ciclos = []
        return this.$http.get('ciclos.activos.erp').then(response=>{
        	this.ciclos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.grupos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.grupos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('grupos.eliminar/' + this.editedItem.id_grupo, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	if(this.editedItem.horario == ''){
      		this.validarErrorDirecto('Favor de llenar todos los campos')
      	}
      	// Cargamos al usuario que hizo el cambio
      	this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('grupos.update/' + this.editedItem.id_grupo, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
          	this.initialize()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        } else {
	        // Lo mandapos por el EP
          this.$http.post('grupos.add', this.editedItem).then(response=>{
          	this.initialize()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
        this.close()
      },

    	getGruposSiguientes() {
      	this.cargar = true
        this.gruposSiguientes = []
        return this.$http.post('grupos.siguientes', this.cicloSiguiente).then(response=>{
        	this.gruposSiguientes = response.data.filter( el => el.activo_sn2 == 1 )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      procesarGrupos( value ){
      	// Cargamos al usuario que hizo el cambio
      	value['id_usuario']        = this.getdatosUsuario.iderp
      	value['id_grupo_original'] = value.id_grupo
	      this.cargar = true
        // Lo mandapos por el EP
        this.$http.post('grupos.add', value).then(response=>{
        	this.editar = true
        	this.validarSuccess( response.data.message )
        	this.getGruposSiguientes()
        }).catch( error =>{
        	this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      saveCodigoZoom( ){
      	this.cargar = true
        return this.$http.post('grupos.codigos', this.grupoZoom ).then(response=>{
        	this.dialogZoom = false
        	this.initialize()
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      }
    },
  }
</script>


<style>
  #grupos td:nth-child(1) {
    background-color: #E8E8E8 !important;
  }

  #grupos td{
    font-size: 11px !important;
    padding-left: 5px;
    padding-right: 5px;
  }

</style>