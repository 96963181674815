<template>
  <v-container fluid class="mt-4" v-if="autorizados.includes( getdatosUsuario.iderp )">
  	<v-row>
  		<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title class="subtitle-1">

	  		  	Vendedora vs Convencimiento

	  		    <v-spacer></v-spacer>
	  		    <v-btn color="primary" @click="initialize()">Buscar</v-btn>
	  		  </v-card-title>

	  		  <v-card-text>
	  		  	<v-row>
	  		  		<v-col cols="12" md="4" lg="2" xl="2">
			  		    <v-text-field
			  		      label="Fecha de inicio"
			  		      filled
			  		      hide-details
			  		      dense
			  		      type="date"
			  		      v-model="fecha_inicio"
			  		    ></v-text-field>	  		  			
	  		  		</v-col>

	  		  		<v-col cols="12" md="4" lg="2" xl="2">
			  		    <v-text-field
			  		      label="Fecha final"
			  		      filled
			  		      hide-details
			  		      dense
			  		      type="date"
			  		      v-model="fecha_final"
			  		    ></v-text-field>
	  		  		</v-col>

	  		  		<v-col cols="12" md="4" lg="3" >
		  		  		<v-radio-group
						      v-model="opEscuela"
						      row
						    >
						      <v-radio
						        label="Inbi"
						        :value="1"
						      ></v-radio>
						      <v-radio
						        label="Fast"
						        :value="2"
						      ></v-radio>
						    </v-radio-group>
	  		  		</v-col>



	  		  	</v-row>

				    <v-data-table
				      :headers="headers"
				      :items="vendedoras"
				      item-key="vendedora"
				      class="table-rounded"
				    >
				      <!-- name -->
				      <template v-slot:item.vendedora="{item}">
				        <div class="d-flex flex-column">
				          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.vendedora }}</span>
				          <!-- <small>{{ item.post }}</small> -->
				        </div>
				      </template>

				      <!-- name -->
				      <template v-slot:item.contactos="{item}">
				        <v-chip small dark color="green" @click="folios = item.contactosAll, dialogFolios = true">{{ item.totalContactos }}</v-chip>
				      </template>

				      <!-- name -->
				      <template v-slot:item.cantidadInscritos="{item}">
				        <v-chip small dark color="green" @click="alumnosInscritos = item.alumnosInscritos, dialogInscritos = true">{{ item.cantidadInscritos }}</v-chip>
				      </template>

				      <template v-slot:item.convencimiento="{item}">
				        {{ item.convencimiento }} %
				      </template>
				    </v-data-table>
	  		  	
	  		  </v-card-text>
	  		</v-card>
  		</v-col>
  	</v-row>

    <!-- Dialog de desglose de contactos -->
    <v-dialog
			v-model="dialogFolios"
			max-width="800px"
		>
			<v-card>
				<v-card-title primary-title class="subtitle-1">
					Folios
					<v-spacer></v-spacer>

					<v-btn
	          class="mr-2"
	          small
	          dark
	          color="green"
	          @click="exportExcel(folios, 'folios')"
	          tile
	        >
	          <v-icon small>mdi-microsoft-excel</v-icon>
	        </v-btn>

				  <v-btn icon small class="me-n3 mt-n2" @click="dialogFolios = false">
		        <v-icon>
		          mdi-close
		        </v-icon>
		      </v-btn>
				</v-card-title>
				<v-card-text class="pt-4">
					<v-data-table
			      :headers="headersFolios"
			      :items="folios"
			      dense
			    >
			    	
			    </v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- Dialog de desglose de contactos -->
    <v-dialog
			v-model="dialogInscritos"
			max-width="800px"
		>
			<v-card>
				<v-card-title primary-title class="subtitle-1">
					Inscritos
					<v-spacer></v-spacer>
				  <v-btn icon small class="me-n3 mt-n2" @click="dialogInscritos = false">
		        <v-icon>
		          mdi-close
		        </v-icon>
		      </v-btn>
				</v-card-title>
				<v-card-text class="pt-4">
					<v-data-table
			      :headers="headersInscritos"
			      :items="alumnosInscritos"
			      dense
			    >
			    	<!-- name -->
			      <template v-slot:item.nombre="{item}">
			       <span > {{ item.nombre + ' ' + item.apellido_paterno + ' ' + item.apellido_materno }}</span>
			      </template>
			    </v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>

<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'
	import { mapGetters } from 'vuex'


export default {
  components:{
    Alerta,
    carga,
  },

  mixins: [ validarErrores, funcionesExcel ],

  data(){
  	return{
  		fecha_inicio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fecha_final:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			overlay: false,
			vendedoras:[],

			dialogDesglose: false,

  		headers: [
        { text: 'VENDEDORA'        , value: 'vendedora' },
        { text: 'CONTACTOS'        , value: 'contactos' },
        { text: 'INSCRITOS'        , value: 'cantidadInscritos' },
        { text: '% CONVENCIMIENTO' , value: 'convencimiento' },
      ],

  		headersInscritos: [
        { text: 'Matricula'        , value: 'matricula' },
        { text: 'Nombre'           , value: 'nombre'    },
        // { text: 'Estatus'          , value: 'idetapa' },
        // { text: 'IdAlumno'         , value: 'idalumno' },
      ],

      headersFolios: [
        { text: 'Folio'            , value: 'folio' },
        { text: 'Fecha'            , value: 'fecha_creacion' },
        { text: 'Etapa'            , value: 'etapa'    },
        { text: 'Estatus'          , value: 'estatus' },
        { text: 'IdAlumno'         , value: 'idalumno' },
        { text: 'Teléfono'         , value: 'telefono' },
      ],

      autorizados: [],

      folios:[],
			dialogFolios:false,
			alumnosInscritos:[],
			dialogInscritos: false,

			opEscuela: 1,


  	}
  },

  async created(){
  	await this.initialize()
  },

  methods:{
  	initialize(){
  		this.cargar = true
  		const payload = {
  			fecha_inicio: this.fecha_inicio,
  			fecha_final:  this.fecha_final,
  			escuela:      this.opEscuela
  		}
			this.$http.post('data.dashboard.marketing', payload).then((response) => {
				this.vendedoras                 = response.data.vendedoras
				this.autorizados                = response.data.autorizados
				this.cargar = false
        return
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
  	},

  	abrirModalDesgloce( data ){
  		this.fuente         = data
  		this.desglose       = data.detalles
  		this.dialogDesglose = true
  	}
  },

  computed:{
    	...mapGetters('login',['getdatosUsuario']),
    tamanioPantalla () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        return 'auto';
          // return this.$vuetify.breakpoint.height -110
        break;
        case 'sm': 
          return this.$vuetify.breakpoint.height - 65
        break;
        case 'md':
          return this.$vuetify.breakpoint.height - 65
        break;
        case 'lg':
          return this.$vuetify.breakpoint.height - 65
        break;
        case 'xl':
          return this.$vuetify.breakpoint.height - 65
        break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>