<template>
	<v-container class="mt-4 px-5" fluid>
		<span v-if="this.getdatosUsuario.cargo == 'Jefe'">
			<TicketArea/>
		</span>

		<span v-if="this.getdatosUsuario.cargo == 'Auxiliar'">
			<TicketAuxi/>
		</span>
	</v-container>
</template>
<script>
import axios from 'axios';
  var moment = require('moment');
  moment.locale('es');
  
  import {mapGetters, mapActions} from 'vuex'
  import TicketArea from '@/views/tickets/operaciones/TicketArea'
  import TicketAuxi from '@/views/tickets/operaciones/TicketAuxi'

  export default {
  	components:{
  		TicketArea,
			TicketAuxi
  	},

    data: () => ({
     
    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),
    },

    created ( ){
      console.log( this.getdatosUsuario )
    }

  }
</script>