<template>
	<v-container class="mt-4 ml-2" fluid>
		<v-row>
			<v-col cols="12">
				<v-card class="shadowCard">
					<v-card-title primary-title>
						<span class="text-subtitle-1"><v-icon left>mdi-fingerprint</v-icon>Nómina Semanal</span>
						<v-spacer></v-spacer>
					</v-card-title>
					<v-card-text>
						<v-row>
							<!-- <v-col cols="12" md="3" lg="2">
								<v-text-field
									label="Fecha"
									type="date"
									filled
									dense
									hide-details
									v-model="fecha"
								></v-text-field>
							</v-col> -->

							<v-col cols="12" md="3" lg="2">
                <v-autocomplete
                  filled
                  dense
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo"
                  hide-details
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>

				      <v-col cols="12" md="3" lg="2">
					      	<!-- :items="[{ bloque: 'Semana 1', value: 1},{ bloque: 'Semana 2', value: 2},{ bloque: 'Semana Vacaciones', value: 3},{ bloque: 'Semana 3', value: 4},{ bloque: 'Semana 4', value: 5}]" -->

				      	<v-select
					      	:items="[{ bloque: 'Semana 1', value: 1},{ bloque: 'Semana 2', value: 2},{ bloque: 'Semana 3', value: 3},{ bloque: 'Semana 4', value: 4}]"
					      	v-model="selectedHeaders"
					      	label="# Semana"
					      	filled
                  dense
					      	hide-details
					      	item-text="bloque"
					      	item-value="value"
				      	></v-select>
				      </v-col> 


				      <v-col cols="12" md="3" lg="2">
				      	<v-radio-group
						      v-model="tipoNomina"
						      column
						      hide-details
						      small
						      class="mt-0 pt-0"
						    >
						      <v-radio
						      	v-if="getdatosUsuario.iderp != 169"
						        label="Administrativa"
						        :value="1"
						      ></v-radio>
						      <v-radio
						      	v-if="getdatosUsuario.iderp != 568"
						        label="Teachers"
						        :value="2"
						      ></v-radio>
						    </v-radio-group>
				      </v-col> 

				    </v-row>

				    <v-row>
				    	<v-col cols="12">
				    		<Administrativa 
				    			v-if="tipoNomina == 1 && getdatosUsuario.iderp != 169"
				    			:selectedHeaders="selectedHeaders"
				    			:ciclo="ciclo"
				    			:fecha="fecha"
				    		/>

				    		<TeacherSabatina 
				    			v-if="tipoNomina == 2 && tipoNominaT == 1 && getdatosUsuario.iderp != 568"
				    			:selectedHeaders="selectedHeaders"
				    			:ciclo="ciclo"
				    		/>
				    		<TeacherQuinceca 
				    			v-if="tipoNomina == 2 && tipoNominaT == 2 && getdatosUsuario.iderp != 568"
				    			:selectedHeaders="selectedHeaders"
				    			:ciclo="ciclo"
				    			:fecha="fecha"
				    		/>
				    	</v-col>
				    </v-row>
			    </v-card-text>
				</v-card> 
			</v-col>
		</v-row>

		<!-- Dialog de carga -->
		<Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
		<carga                v-if="cargar"/>

	</v-container>
</template>
<script>
	import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
	import Alerta                from '@/components/alertas/Alerta.vue'
	import carga                 from '@/components/alertas/carga.vue';


	import Administrativa        from '@/components/nomina/Administrativa.vue';
	import TeacherSabatina       from '@/components/nomina/TeacherSabatina.vue';
	import TeacherQuincena       from '@/components/nomina/TeacherQuincena.vue';

	import validarErrores  from '@/mixins/validarErrores'
	import XLSX            from 'xlsx'
	import funcionesExcel  from '@/mixins/funcionesExcel'


	export default {
		components:{
			Alerta,
			carga,
			Administrativa,
			TeacherSabatina,
			TeacherQuincena
		},

		mixins: [ validarErrores, funcionesExcel ],

		data: () => ({
    	// Alertas
			parametros:{
				dialogError: false,
				mensaje: '',
				color: ''
			},

			respuestaAlerta:false,
			loader: true,
			cargar: false,

			encabezados: null,
			encabezados2: null,
			dialogAceptar: false,
			dialogActualizar: false,
			ciclos:[],
			gruposRoles: [],
			pagos: [],
			ciclo:null,
			id_ciclo: 0,

      // Filtros: 
			fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			// fecha: "2024-11-08",
      // fechafin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

			page: 1,
			page2: 1,
			pageCount: 0,
			itemsPerPage: 10,
			entradassalidas: [],
			autorizarNomina: [],
			nominaEstatus: [],
			nominaTeachersSabatino: [],
			nominaTeachersCatorcena: [],
			asistencias: [],
			estatus: null,
			entradassalidasteachers: [],

			selectedHeaders:null,
			gruposRoles2:[],

			tipoNominaT: 1,
			tipoNomina: null
		}),

		computed: {

			...mapGetters( 'login' ,['getdatosUsuario']),

		},

		async created () {
			await this.getCiclosFecha()
			if( this.getdatosUsuario.iderp == 169 ){ this.tipoNomina == 2 }
		},

		methods: {

			getCiclos () {
				this.cargar = true
				this.ciclos = []

				this.$http.get('roles.ciclos.all').then(response=>{
					for(const i in response.data){
						if (!(response.data[i].ciclo.search('FE') != -1)) {
							if(response.data[i].ciclo.search('CICLO') != -1){
								this.ciclos.push(response.data[i])
							}
						}
					}

					this.cargar = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

			getCiclosFecha () {
				this.cargar = true
				this.ciclos = []

				const payload = {
					fecha: this.fecha
				}

				this.$http.post('roles.get.ciclofecha', payload).then(response=>{
					for(const i in response.data){
						if (!(response.data[i].ciclo.search('FE') != -1)) {
							if(response.data[i].ciclo.search('CICLO') != -1){
								this.ciclos.push(response.data[i])
								this.ciclo = this.ciclos[0];
							}
						}
					}
					this.cargar = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { })
			},
		},
	}
</script>




