<template>
	<v-container class="mt-4 ml-2" fluid>
		<v-row>
			<v-col cols="12">
				<v-card class="shadowCard">
					<v-card-title primary-title>
						<span class="text-subtitle-1"><v-icon left>mdi-fingerprint</v-icon>Comentarios nómina</span>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							dark
							@click="initialize()"
							small
							tile
						>
							Actualizar
						</v-btn>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" md="3" lg="2">
                <v-autocomplete
                  filled
                  dense
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo"
                  hide-details
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>

				      <v-col cols="12" md="3" lg="2">
				      	<v-select
					      	:items="[{ bloque: 'Semana 1', value: 1},{ bloque: 'Semana 2', value: 2},{ bloque: 'Semana 3', value: 3},{ bloque: 'Semana 4', value: 4}]"
					      	v-model="selectedHeaders"
					      	label="# Semana"
					      	filled
                  dense
					      	hide-details
					      	item-text="bloque"
					      	item-value="value"
				      	></v-select>
				      </v-col> 
				    </v-row>

				    <v-row>
							<v-col cols="12">
		          	<v-radio-group
						      v-model="opcionFiltro"
						      row
						    >
						      <v-radio
						        label="Pendiente"
						        :value="0"
						      ></v-radio>
						      <v-radio
						        label="Aceptados"
						        :value="1"
						      ></v-radio>
						      <v-radio
						        label="Rechazados"
						        :value="2"
						      ></v-radio>
						      <v-radio
						        label="Todos"
						        :value="3"
						      ></v-radio>
						    </v-radio-group>

						    <v-row justify="space-between">

						    	<v-col cols="12" md="6">
								    <div class="black--text">
								    	Avance: {{ nominaActual.filter( el => el.estatus_nomina == 1 ).length ? ( (nominaActual.filter( el => el.estatus_nomina == 1 ).length / nominaActual.length ) *100).toFixed(2) : 0 }} %
								    </div>
						    	</v-col>

						    	<v-col cols="12" md="6">
								    <v-text-field
								      label="Buscar"
								      v-model="search"
								      dense
								      single-line
								      filled
								    ></v-text-field>
						    	</v-col>
						    </v-row>

				        <v-data-table
	            		:headers="headers"
	            		:items="filterEntradas"
	            		class="elevation-0"
	            		:search="search"
	            		:mobile-breakpoint="100"
	            		dense
            		>
            			<!-- Valores que ocupan botons, chips, iconos, etc -->
	            		<template v-slot:item.nombre_completo="{ item }">
	            			<span 
	            				:class="item.estatus_nomina == 1 ? 'green--text' : item.estatus_nomina == 2 ? 'red--text' : ''"
	            				@click="verComentarios( item )"
	            				type="button"
	            			>
	            				{{ item.nombre_completo }}
	            			</span>
	            		</template>

	            		<!-- Valores que ocupan botons, chips, iconos, etc -->
	            		<template v-slot:item.tipo_pago="{ item }">
	            			<v-chip v-if="item.tipo_pago == 2" small color="green" dark >Hora</v-chip>
	            			<v-chip v-if="item.tipo_pago == 1" small color="blue" dark >Dia</v-chip>
	            			<v-chip v-if="item.tipo_pago == null || item.tipo_pago == 0" small color="orange" dark >Sin Tipo Pago</v-chip>
	            		</template>

	            		<template v-slot:item.confirmacion_comisiones="{ item }">
	            			<v-chip v-if="(item.confirmacion_comisiones == 3)" small color="green" dark >Aceptadas</v-chip>
	            			<v-chip v-if="(item.confirmacion_comisiones == 4)" small color="blue" dark >Rechazadas</v-chip>
	            			<v-chip v-if="item.puesto == 'Encargada de sucursal' && (item.confirmacion_comisiones == null || item.tipo_pago == 0)" small color="orange" dark >Pendiente</v-chip>
	            		</template>

	            		<template v-slot:item.monto_laborado="{ item }">
	            			<span type="button" @click="goPrenomina(item.nombre_completo )">{{ item.monto_laborado }}</span>
	            		</template>

	            		<template v-slot:item.monto_horas_extras="{ item }">
	            			<span type="button" @click="goHorasExtras(item.nombre_completo )">{{ item.monto_horas_extras }}</span> 
	            		</template>

	            		<template v-slot:item.comisiones_semana="{ item }">
	            			<span type="button" @click="goComisiones(item.nombre_completo )">{{ item.comisiones_semana }}</span>
	            		</template>

	            		<template v-slot:item.monto_total_rebajes="{ item }">
	            			<span type="button" @click="goRebajes(item.nombre_completo )">{{ item.monto_total_rebajes }}</span>
	            		</template>

	            		<template v-slot:item.monto_total_bonos="{ item }">
	            			<span v-if="item.puesto != 'Teacher'" @click="goBonos(item.nombre_completo )">{{ item.monto_total_bonos }}</span>
	            			<span v-if="item.puesto == 'Teacher'" >{{ item.monto_total_bonos }}</span>
	            		</template>

	            		<template v-slot:item.monto_total="{ item }">
	            			<span type="button" @click="formulaMontoTotal(item), dialogMontoTotal = true">{{ item.monto_total }}</span>
	            		</template>

		            	<template v-slot:no-data>
		            		<v-btn
			            		color="primary"
			            		@click="initialize"
			            		small
		            		>
		            			Actualizar
			            	</v-btn>
			            </template>
			          </v-data-table>
							</v-col>
						</v-row>

			    </v-card-text>
				</v-card> 
			</v-col>
		</v-row>

		<v-dialog
		  v-model="dialogComentarios"
		  max-width="500px"
		  v-if="renglonNomina"
		>
			<v-card>
				<v-card-title primary-title>
				  Comentarios de nómina
				</v-card-title>

			  <v-card-text>
			  	<v-row>
			  		<v-col cols="12"
			  			v-for="(mensaje, i) in renglonNomina.comentarios" :key="i"
			  		>
					  	<p 
					  		:class="`pa-2 ma-0 mb-4 ${ mensaje.id_usuario != getdatosUsuario.id_trabajador ? 'mensajeIzq' : 'mensajeDer' }`">
					    	{{ mensaje.comentarios }}
					    	<br/>
					    	{{ mensaje.nombre_completo }}
					  	</p>
			  		</v-col>
			  	</v-row>
			    <br/>
			  </v-card-text>

			  <v-card-text>
			    <v-textarea
			  		v-model="respuesta"
			  		filled
			  		:rows="3"
			  		auto-grow
			  		label="respuesta al colaborador"
			  		hide-details
			  	></v-textarea>
			  </v-card-text>
			  
			  <v-card-actions>
			  	<v-btn 
			  		color="black"
			  		@click="dialogComentarios = false"
			  		tile
			  		dark
			  		small
			  	>
			  		<v-icon left span>mdi-cancel</v-icon>
			  		Cancelar
			  	</v-btn>
			    <v-spacer></v-spacer>
			  	<v-btn 
			  		color="success"
			  		@click="responder( )"
			  		tile
			  		small
			  	>
			  		<v-icon left span>mdi-content-save</v-icon>
			  		Agregar comentario
			  	</v-btn>
			  </v-card-actions>
			</v-card>	
		</v-dialog>
		<!-- Dialog de carga -->
		<Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
		<carga                v-if="cargar"/>

	</v-container>
</template>
<script>
	import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
	import Alerta                from '@/components/alertas/Alerta.vue'
	import carga                 from '@/components/alertas/carga.vue';


	import Administrativa        from '@/components/nomina/Administrativa.vue';

	import validarErrores  from '@/mixins/validarErrores'
	import XLSX            from 'xlsx'
	import funcionesExcel  from '@/mixins/funcionesExcel'


	export default {
		components:{
			Alerta,
			carga,
			Administrativa,
		},

		mixins: [ validarErrores, funcionesExcel ],

		data: () => ({
    	// Alertas
			parametros:{
				dialogError: false,
				mensaje: '',
				color: ''
			},

			respuestaAlerta:false,
			loader: true,
			cargar: false,

			ciclos:[],
			ciclo:null,
			id_ciclo: 0,

      // Filtros: 
			fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			// fecha: "2024-07-12",
      // fechafin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

			estatus: null,

			selectedHeaders:null,

			respuestaAlerta:false,
			loader: true,
			cargar: false,

			dialogAceptar: false,
			dialogActualizar: false,
			ciclos:[],
			id_ciclo: 0,

      // tabla
			search:'',

			nominaActual: [],
			nominaEstatus: [],
			estatus: null,
			entradassalidasteachers: [],

			headers: [
				{ text: 'Empleado'              , value: 'nombre_completo'      },
				// { text: 'Puesto'                , value: 'puesto'               },
			],

			tab: null,
			dataNominaAnterior: [],

			renglonNomina: null,
			dialogComentarios: false,
			respuesta: '',

			opcionFiltro: 3

		}),

		computed: {

			...mapGetters( 'login' ,['getdatosUsuario']),

			filterEntradas( ){
				let entradass = this.nominaActual
				if( this.opcionFiltro != 3 ){

					entradass = entradass.filter( el => el.estatus_nomina == this.opcionFiltro )

				} 
				return entradass.filter(el =>  el.puesto !== 'Sin puesto');
			},

			filterEntradasEncargadas( ){
				let entradass = this.nominaActual;

				return entradass.filter(el => el.puesto !== 'Sin puesto' && el.puesto == 'Encargada de sucursal');
			},

		},

		async created () {
			await this.getCiclosFecha()
		},

		methods: {

			getCiclos () {
				this.cargar = true
				this.ciclos = []

				this.$http.get('roles.ciclos.all').then(response=>{
					for(const i in response.data){
						if (!(response.data[i].ciclo.search('FE') != -1)) {
							if(response.data[i].ciclo.search('CICLO') != -1){
								this.ciclos.push(response.data[i])
							}
						}
					}

					this.cargar = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

			getCiclosFecha () {
				this.cargar = true
				this.ciclos = []

				const payload = {
					fecha: this.fecha
				}

				this.$http.post('roles.get.ciclofecha', payload).then(response=>{
					for(const i in response.data){
						if (!(response.data[i].ciclo.search('FE') != -1)) {
							if(response.data[i].ciclo.search('CICLO') != -1){
								this.ciclos.push(response.data[i])
								this.ciclo = this.ciclos[0];
							}
						}
					}
					this.cargar = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { })
			},

			initialize () {
				this.cargar = true
				this.nominaActual = []

				const payload = {
					fecha   : this.fecha,   
					id_ciclo: this.ciclo.id_ciclo,
					semana  : this.selectedHeaders 
				}

				return this.$http.post('usuarios.entradas', payload).then(async response=>{

					let puesto          = this.getdatosUsuario.puesto
					let mapIdPlanteles  = this.getdatosUsuario.planteles.map((registro) => registro.idplantel);

					// María esther
					if( this.getdatosUsuario.iderp == 568 ){ puesto = 'Jefe de Operaciones' }

					if( this.getdatosUsuario.iderp == 626 ){
						puesto         = 'COORDINADOR ZONA' 
						mapIdPlanteles = [32,21,4,6] 
					}

			    if( this.getdatosUsuario.iderp == 7 ){
						puesto         = 'COORDINADOR ZONA' 
			    	mapIdPlanteles = [29] 
			    }

			    if( this.getdatosUsuario.iderp == 1312 ){
						puesto         = 'COORDINADOR ZONA' 
			    	mapIdPlanteles = [27,5,31,17] 
			    }

			    // Patricia Salazar
			    if( this.getdatosUsuario.iderp == 161 ){
						puesto         = 'COORDINADOR ZONA' 
			    	mapIdPlanteles = [14,12,9,16] 
			    }

			    // PIEDAD LOPEZ 
			    if( this.getdatosUsuario.iderp == 1248 ){
						puesto         = 'COORDINADOR ZONA' 
			    	mapIdPlanteles = [11,7,8,10] 
			    }

			    // María Isabel Rodríguez Mora
			    if( this.getdatosUsuario.iderp == 1196 ){
						puesto         = 'COORDINADOR ZONA' 
			    	mapIdPlanteles = [2,3,5] 
			    }

			    // fernanda oliva espinosa
			    if( this.getdatosUsuario.iderp == 1489 ){
						puesto         = 'COORDINADOR ZONA' 
			    	mapIdPlanteles = [25,28,27,31,17] 
			    }

			    // fernanda oliva espinosa
			    if( this.getdatosUsuario.iderp == 1312 ){
						puesto         = 'COORDINADOR ZONA' 
			    	mapIdPlanteles = [24,1,23,22] 
			    }

			    // Karen Gonzalez
			    if( this.getdatosUsuario.iderp == 28 ){
			    	puesto = 'Jefe Finanzas'
			    }

					let puestosDependientes = response.data.organigrama.filter( el => el.puesto_jefe == puesto ).map((registro) => { return registro.puesto_auxiliar })
			    
			    if( this.getdatosUsuario.iderp == 954 ){
			    	puestosDependientes = response.data.organigrama.map((registro) => { return registro.puesto_auxiliar })
			    }

					this.nominaActual = response.data.result3.filter( el => puestosDependientes.includes( el.puesto ));
					
					if( this.getdatosUsuario.puesto == 'Encargada de sucursal' && this.getdatosUsuario.iderp != 568 ){
						this.nominaActual = this.nominaActual.filter( el => mapIdPlanteles.includes( el.id_plantel ) )
					}


					if( puesto == 'COORDINADOR ZONA' ){
						this.nominaActual = this.nominaActual.filter( el => mapIdPlanteles.includes( el.id_plantel ) )
					}

				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

			verComentarios( value ){

				this.renglonNomina = value

				this.dialogComentarios = true
			},

			responder( ){

				const payload = {
					idnomina_estatus: this.renglonNomina.idnomina_estatus,
					estatus         : 0,
					id_usuario      : this.getdatosUsuario.id_trabajador,
					motivo          : this.respuesta,
					idusuarionomina : this.renglonNomina.id_usuario 
				}

				this.$http.post('responder.nomina.jefe', payload ).then(response=>{
          this.validarSuccess( 'Respuesta enviada' )
          this.initialize( )
          this.dialogComentarios = false
          this.respuesta = ''
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
			},

			format( valor ){
			  return valor.toLocaleString('es-MX', {
			    style: 'currency',
			    currency: 'MXN'
			  });
      }
		},
	}
</script>




