<template>
	<v-card class="elevation-0" >
		<v-row>
			<v-col cols="12" class="px-8">

				<v-expansion-panels multiple active-class>
					<v-expansion-panel
			      v-for="(item, i ) in hermanos"
			      :key="i"
			      class="elevation-10"
			    >
			      <v-expansion-panel-header>
			        Datos del hermano {{ i + 1 }}
			      </v-expansion-panel-header>
			      <v-expansion-panel-content>
					  	<!-- BUSCAR POR MATRICULA -->
					  	<b>Matricula:</b> {{ item.matricula }}
					  	<br/>
					  	<b>Nombre:</b> {{ item.nombre }} {{ item.apellido_paterno }} {{ item.apellido_materno }}

							<v-divider class="my-4"></v-divider>

							<!-- TIPO DE ALUMNOS -->
					  	<label >Estatus del alumno actualmente</label>
					  	<br/>
			        <span v-if="tipoAlumno == 1"><b>ALUMNO NI</b></span>
		          <span v-if="tipoAlumno == 2"><b>ALUMNO REGULAR</b></span>
		          <span v-if="tipoAlumno == 3" class="orange--text"><b>ALUMNO IRREGULAR</b></span>
		          <span v-if="tipoAlumno == 4" class="red--text"><b>ALUMNO CON ADEUDO</b></span>
		          <span v-if="tipoAlumno == 5" ><b>ALUMNO PENDIENTE DE ABRIR GRUPO</b></span>
		          <span v-if="tipoAlumno == 6" class="red--text"><b>ALUMNO CON DIFERENCIA DE PAGO</b></span>


							<v-divider class="my-4"></v-divider>

			        <v-card-title primary-title class="subtitle-1">
		            <b>Grupo Actual del alumno</b>
		          </v-card-title>
				  	  <v-card-text class="black--text">
						  	<div class="text-subtitle-1">
						  		{{ item.getGrupoActual.grupo }}
						  		<br/>
				          <strong class="red--text">Adeudo: $ {{ item.getGrupoActual.adeudo }}<br/></strong> 
						  	</div>
				  	  </v-card-text>


							<v-divider class="my-4"></v-divider>

							<!-- Alumno irregular, selecciona un nuevo grupo -->
			        <div v-if="[3].includes(item.tipoAlumno)">
			          <v-card-title primary-title class="subtitle-1">
			            Selecciona un nuevo grupo para el alumno
			          </v-card-title>
			          <v-card-text>
			            <v-autocomplete
			              label="Grupos"
			              dense
			              v-model="item.grupoSiguiente"
			              :items="grupos"
			              item-text="grupo"
			              item-value="id_grupo"
			              clearable
			              filled
			              return-object
			            ></v-autocomplete>
			          </v-card-text>
			        </div>

				      <!-- ALUMNO RI REGUALAR -->
				      <!-- Seleccionar otro grupo, alumnos NO HERMANOS -->
			        <div v-if="[2,4,5].includes(item.tipoAlumno)">
			          <v-card-title primary-title class="subtitle-1">
			            <b>Grupo siguiente:</b>
			          </v-card-title>
			          <v-card-text class="black--text">
			            <!-- Seleccionar otro grupo diferente al siguiente -->
			            <v-autocomplete
			              label="Selecciona un grupo"
			              dense
			              v-if="item.otroGrupo"
			              v-model="item.grupoSiguiente"
			              :items="grupos"
			              item-text="grupo"
			              item-value="id_grupo"
			              clearable
			              filled
			              return-object
			            ></v-autocomplete>

			            <div class="text-subtitle-1" v-if="item.grupoSiguiente">
			              <span v-if="!item.otroGrupo">{{ item.grupoSiguiente.grupo }}</span>
			              <span v-if="item.capacidad">El siguiente grupo no cuenta con capacidad para inscribir alumnos</span>
			              <v-switch label="Seleccionar otro grupo" v-model="item.otroGrupo" hide-details v-if="item.getGrupoActual.adeudo <= 0"></v-switch>
			            </div>

			            <div class="text-subtitle-1" v-if="!item.grupoSiguiente">
			              <strong>{{ errorMensaje }}
			                <br/>
			              </strong>
			              <span v-if="!item.otroGrupo"></span>
			              <v-switch label="Seleccionar otro grupo" v-model="item.otroGrupo" hide-details></v-switch>
			            </div>
			          </v-card-text>
			        </div>


			      </v-expansion-panel-content>
			    </v-expansion-panel>
			  </v-expansion-panels>
			</v-col>


		</v-row>

		<v-divider class="my-4"></v-divider>

		<v-card-actions>
      <v-btn 
        text 
        @click="dialogAddAlumno.estatus = false"
      >
        Cancelar
      </v-btn>
      
      <v-btn 
        tile
        color="primary"
        class="ml-2"
        @click="steppers.paso = 1"
      >
        <v-icon left small>mdi-arrow-left</v-icon>
        Regresar
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
      	tile
        color="primary"
        @click="steppers.paso = 3"
      >
        Siguiente
      	<v-icon right small>mdi-arrow-right</v-icon>
      </v-btn>
  	</v-card-actions>


  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <AgregarProspecto     v-if="existeProspecto"             :parametrosProspectos="parametrosProspectos" @saveProspecto="saveProspecto"/>


	</v-card>
</template>
<script>

	import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import AgregarProspecto   from '@/components/inscripciones/AgregarProspecto.vue';


  import validarErrores  from '@/mixins/validarErrores'

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
      AgregarProspecto
    },

    mixins: [ validarErrores ],

  	props:[
			'alumno',
			'steppers',
			'hermanos',
			'tutor'
	  ],

    data: () => ({
    	escuela: 2,
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

    	prospectos:[],
    	parametrosProspectos:{
    		estatus: false
    	},

    	existeProspecto:false,
    	prospecto: null,
    	grupos:[],

    	grupo: null,
    	errorMensaje:null,
      otroGrupo: false,
      nuevoGrupoSeleccionado:null,
      tipoAlumno: null

    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),
    },

    watch:{

    },

    async created () {
    	this.escuela = this.getEscuela
    	this.iniciarValidaciones( )
    	this.getGruposDisponibles( )
    },

    methods: {

    	async iniciarValidaciones( ){
        this.cargar = true
        let hermanos = await this.validarHermano( ).then( response => response )

        if( hermanos && hermanos.hermanos.length ){ 

        	console.log( 'hermanos',hermanos )
          // this.hermanos    = hermanos.hermanos
          this.tipoAlumno  = hermanos.tipoAlumno
          this.grupos      = []

          for( const i in hermanos.hermanos){
            if( typeof(hermanos.hermanos[i]) == 'object' ){
              hermanos.hermanos[i]['tipoAlumno'] = this.tipoAlumno
              this.grupos.push( hermanos.hermanos[i] )
            }        
          }

          for( const i in this.hermanos ){

          	const { id_alumno } = this.hermanos[i]

          	console.log( id_alumno )

          	if( id_alumno ){
	          	const existeHermano = hermanos.hermanos.find( el => el.id_alumno == id_alumno )

	          	this.hermanos[i]['getGrupoActual'] = existeHermano ? existeHermano.getGrupoActual : null
	          	this.hermanos[i]['grupoSiguiente'] = existeHermano ? existeHermano.grupoSiguiente : null
	          	this.hermanos[i]['tipoAlumno']     = existeHermano ? existeHermano.tipoAlumno     : null
          	}

 
          }

          if( this.tipoAlumno == 1 ){ this.getGruposDisponibles( ) }
          
          this.cargar = false
          
          return
        }

        this.cargar = false
      },

      validarHermano( ){
        return new Promise(( resolve, reject ) => {
          const payload = {
            id_alumno: this.hermanos[0].id_alumno,
            escuela  : this.hermanos[0].unidad_negocio
          }

          this.$http.post('validar.hermanos', payload).then(response=>{
            resolve( response.data )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },

      getGruposDisponibles( ) {
        this.cargar = true
        this.grupos = []

        return this.$http.get('grupos.disponibles/' + this.getEscuela ).then(response=>{
          this.cargar = false
          this.grupos = response.data

          console.log( this.grupos[0] )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    },
  }
</script>
