<template>
	<v-container class="mt-4 ml-2" fluid>
		<v-row>
			<v-col cols="12">
				<v-card class="shadowCard">

					<!-- Titulo -->
					<v-card-title primary-title><span class="text-subtitle41"><v-icon left>mdi-fingerprint</v-icon>Mi Nómina Teacher</span>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							dark
							@click="getDatosNominaTeacher(), initialize()"
							small
							tile
						>
							Actualizar
						</v-btn>
					</v-card-title>

					<!-- FILTROS -->
					<v-card-text v-if="getdatosUsuario.idpuesto != 18">
						<v-row>
							<v-col cols="12" md="3" lg="2">
                <v-autocomplete
                  filled
                  dense
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo"
                  hide-details
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>

					    <!-- :items="[{ bloque: 'Semana 1', value: 1},{ bloque: 'Semana 2', value: 2},{ bloque: 'Semana 3', value: 3},{ bloque: 'Semana 4', value: 4}]" -->

				      <v-col cols="12" md="3" lg="2">
				      	<v-select
					      	:items="semanaAseleccionar"
					      	v-model="selectedHeaders"
					      	label="# Semana"
					      	filled
                  dense
					      	hide-details
					      	item-text="bloque"
					      	item-value="value"
				      	></v-select>
				      </v-col>
						</v-row>
						<br />

	      		<!-- Check para los eliminados y optimizados -->
            <v-row class="mb-2" v-if="quincenaLiberada && selectedHeaders">
            	<!-- Filtrar por teacher -->
            	<v-col cols="12" md="3" lg="4">
                <h3> TOTAL DE GRUPOS: {{ filterGruposQuincena.length }} </h3>
            	</v-col>

              <v-col cols="12" md="3" lg="4">
              </v-col>
            </v-row>

            <v-row>
            	<v-col cols="12">
            		
		            <v-data-table
		            	v-if="quincenaLiberada && selectedHeaders"
				          :headers="headersBloque1"
				          :items="filterGruposQuincena"
				          class="elevation-0 mt-2"
				          dense
				          fixed-header
				          hide-default-footer
				          :loading="cargar"
				        > 

				          <!-- Editar Bloque 1 -->
				          <template v-slot:item.lu1_n="{ item }">
				            <span v-if="item.lu1 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.lu1_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.ma1_n="{ item }">
				            <span v-if="item.ma1 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ma1_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.mi1_n="{ item }">
				            <span v-if="item.mi1 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.mi1_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.ju1_n="{ item }">
				            <span v-if="item.ju1 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ju1_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.vi1_n="{ item }">
				            <span v-if="item.vi1 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.vi1_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>


				          <!-- Editar Bloque 2 -->
				          <template v-slot:item.lu2_n="{ item }">
				            <span v-if="item.lu2 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.lu2_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.ma2_n="{ item }">
				            <span v-if="item.ma2 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ma2_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.mi2_n="{ item }">
				            <span v-if="item.mi2 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.mi2_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.ju2_n="{ item }">
				            <span v-if="item.ju2 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ju2_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.vi2_n="{ item }">
				            <span v-if="item.vi2 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.vi2_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>


				          <!-- Editar Bloque 3 -->
				          <template v-slot:item.lu3_n="{ item }">
				            <span v-if="item.lu3 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.lu3_falta ? 'red darken-2 white--text' : '' ">
		                  {{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
		              </template>

				          <template v-slot:item.ma3_n="{ item }">
				            <span v-if="item.ma3 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ma3_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
		              </template>

				          <template v-slot:item.mi3_n="{ item }">
				            <span v-if="item.mi3 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.mi3_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
		              </template>

				          <template v-slot:item.ju3_n="{ item }">
				            <span v-if="item.ju3 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ju3_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
		              </template> 

				          <template v-slot:item.vi3_n="{ item }">
				            <span v-if="item.vi3 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.vi3_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
		              </template>


				          <!-- Editar Bloque 4 -->
				          <template v-slot:item.lu4_n="{ item }">
				            <span v-if="item.lu4 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.lu4_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.ma4_n="{ item }">
				            <span v-if="item.ma4 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ma4_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.mi4_n="{ item }">
				            <span v-if="item.mi4 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.mi4_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.ju4_n="{ item }">
				            <span v-if="item.ju4 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ju4_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.vi4_n="{ item }">
				            <span v-if="item.vi4 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.vi4_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>
				        </v-data-table>
				        
				        <v-spacer v-if="[1,3].includes( selectedHeadersQ ) && quincenaLiberada"></v-spacer>
				        <div class="align-end text-h6" align="end" v-if="[1,3].includes( selectedHeadersQ ) && quincenaLiberada">
				        	<b class="black--text">Horas Totales: {{ totalFinal }}</b>
				        </div>
            	</v-col>
            </v-row>

            <!-- TABLA QUINCENA -->
  			  	<v-row>
				  		<v-col cols="12" md="8" lg="7" v-for="(mensaje, i) in comentariosQ" :key="i">
						  	<p 
						  		:class="`pa-2 ma-0 mb-4 ${ mensaje.id_usuario != getdatosUsuario.iderp ? 'mensajeIzq' : 'mensajeDer' }`">
						    	{{ mensaje.comentarios }}
						    	<br/>
						    	<!-- {{ mensaje.nombre_completo }} -->
						  	</p>
				  		</v-col>
				  	</v-row>

		        <!-- NOMINA DE CATORCENA -->
		        <v-row v-if="[1,3].includes( selectedHeadersQ ) && !miEstatusQ && quincenaLiberada">
	          	<v-col cols="12">
	          		
						    <v-btn 
						    	v-if="liberada && !rechazado"
						    	color="success"
						    	dark
						    	tile
						    	class="mr-2"
						    	@click="dialogAceptar = true, tipo_nomina = 2"
						    	small
						    >
						  		Aceptar mis horas
						  		<v-icon small right>mdi-check</v-icon>
						  	</v-btn>


						  	<v-btn 
						  		v-if="liberada && !rechazado"
						    	color="red darken-3"
						    	dark
						    	tile
						    	@click="rechazado = true, tipo_nomina = 2"
						    	small
						    >
						  		Rechazar mis horas
						  		<v-icon small right>mdi-close</v-icon>
						  	</v-btn>
	        		</v-col>

	        		<v-col cols="12" md="6" lg="4" v-if="liberada && rechazado">
	        			<v-textarea
	        				v-model="motivo"
	        				:rows="3"
	        				auto-grow
	        				filled
	        				dense
	        				label="Agrega tu motivo por favor"
	        			>
	        			</v-textarea>
						  	
						  	<v-btn 
						    	color="black"
						    	dark
						    	tile
						    	class="mr-2"
						    	@click="rechazado = false"
						    >
						  		Cancelar
						  		<v-icon small right>mdi-close</v-icon>
						  	</v-btn>

	        			<v-btn 
						    	color="success"
						    	dark
						    	tile
						    	class="mr-2"
						    	@click="save(2, 2)"
						    >
						  		Aceptar
						  		<v-icon small right>mdi-check</v-icon>
						  	</v-btn>
	        		</v-col>
	      		</v-row>

	      		<v-alert
				    	v-if="miEstatusQ == 1 && [1,3].includes( selectedHeadersQ )"
				      dense
				      text
				      type="success"
				    >
				      Felicidades, tu nómina ha sido aceptada con éxito!
				    </v-alert>

				    <v-alert
				    	v-if="miEstatusQ == 2 && [1,3].includes( selectedHeadersQ )"
				      dense
				      text
				      type="error"
				    >
				      Lo sentimos, estamos reviando tu rechazo, en un momento podrás revisarla nuevamente!
				    </v-alert>

            <!-- Check para los eliminados y optimizados -->
            <v-row class="mb-2" v-if="liberadaSabatina">
            	<!-- Filtrar por teacher -->
            	<v-col cols="12" md="3" lg="4">
                <h3> TOTAL DE GRUPOS: {{ filterGruposSabatino.length }} </h3>
            	</v-col>

              <v-col cols="12" md="3" lg="4">
              </v-col>

              <v-col cols="12">
	              <v-data-table
		            	v-if="liberadaSabatina"
				          :headers="headersBloqueS"
				          :items="filterGruposSabatino"
				          class="elevation-0 mt-2"
				          dense
				          fixed-header
				          hide-default-footer
				          :loading="cargar"
				        > 

				          <!-- Editar Bloque 1 -->
				          <template v-slot:item.lu1_n="{ item }">
				            <span v-if="item.lu1 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.lu1_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.ma1_n="{ item }">
				            <span v-if="item.ma1 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ma1_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.mi1_n="{ item }">
				            <span v-if="item.mi1 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.mi1_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.ju1_n="{ item }">
				            <span v-if="item.ju1 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ju1_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.vi1_n="{ item }">
				            <span v-if="item.vi1 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.vi1_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>


				          <!-- Editar Bloque 2 -->
				          <template v-slot:item.lu2_n="{ item }">
				            <span v-if="item.lu2 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.lu2_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.ma2_n="{ item }">
				            <span v-if="item.ma2 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ma2_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.mi2_n="{ item }">
				            <span v-if="item.mi2 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.mi2_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.ju2_n="{ item }">
				            <span v-if="item.ju2 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ju2_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.vi2_n="{ item }">
				            <span v-if="item.vi2 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.vi2_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>


				          <!-- Editar Bloque 3 -->
				          <template v-slot:item.lu3_n="{ item }">
				            <span v-if="item.lu3 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.lu3_falta ? 'red darken-2 white--text' : '' ">
		                  {{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
		              </template>

				          <template v-slot:item.ma3_n="{ item }">
				            <span v-if="item.ma3 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ma3_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
		              </template>

				          <template v-slot:item.mi3_n="{ item }">
				            <span v-if="item.mi3 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.mi3_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
		              </template>

				          <template v-slot:item.ju3_n="{ item }">
				            <span v-if="item.ju3 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ju3_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
		              </template> 

				          <template v-slot:item.vi3_n="{ item }">
				            <span v-if="item.vi3 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.vi3_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
		              </template>


				          <!-- Editar Bloque 4 -->
				          <template v-slot:item.lu4_n="{ item }">
				            <span v-if="item.lu4 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.lu4_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.ma4_n="{ item }">
				            <span v-if="item.ma4 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ma4_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.mi4_n="{ item }">
				            <span v-if="item.mi4 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.mi4_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.ju4_n="{ item }">
				            <span v-if="item.ju4 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.ju4_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>

				          <template v-slot:item.vi4_n="{ item }">
				            <span v-if="item.vi4 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )" :class="item.vi4_falta ? 'red darken-2 white--text' : '' ">
											{{ item.grupo.match('DOMINICAL') || item.grupo.match('SABATINO') ? 1 : item.horas_dia }}
		                </span>
		                </div>
				          </template>
				        </v-data-table>
              </v-col>
            </v-row>
		        <v-spacer v-if="liberadaSabatina"></v-spacer>
		        <div class="align-end text-h6" align="end" v-if="liberadaSabatina">
		        	<b class="black--text">Horas Totales: {{ totalFinalS }}</b>
		        </div>

		        <!-- ACEPTAR NOMINA SABATINA -->
		        <v-row v-if="liberadaSabatina && miEstatus == 0">
	          	<v-col cols="12">
	          		
						    <v-btn 
						    	v-if="liberada && !rechazado"
						    	color="success"
						    	dark
						    	tile
						    	class="mr-2"
						    	@click="dialogAceptar = true, tipo_nomina = 1"
						    	small
						    >
						  		Aceptar mis horas
						  		<v-icon small right>mdi-check</v-icon>
						  	</v-btn>


						  	<v-btn 
						  		v-if="liberada && !rechazado"
						    	color="red darken-3"
						    	dark
						    	tile
						    	@click="rechazado = true, tipo_nomina = 1"
						    	small
						    >
						  		Rechazar mis horas
						  		<v-icon small right>mdi-close</v-icon>
						  	</v-btn>
	        		</v-col>

	        		<v-col cols="12" md="6" lg="4" v-if="liberada && rechazado">
	        			<v-textarea
	        				v-model="motivo3"
	        				:rows="3"
	        				auto-grow
	        				filled
	        				dense
	        				label="Agrega tu motivo por favor"
	        			>
	        			</v-textarea>
						  	
						  	<v-btn 
						    	color="black"
						    	dark
						    	tile
						    	class="mr-2"
						    	@click="rechazado = false"
						    >
						  		Cancelar
						  		<v-icon small right>mdi-close</v-icon>
						  	</v-btn>

	        			<v-btn 
						    	color="success"
						    	dark
						    	tile
						    	class="mr-2"
						    	@click="save(2, 2)"
						    >
						  		Aceptar
						  		<v-icon small right>mdi-check</v-icon>
						  	</v-btn>
	        		</v-col>
	      		</v-row>

	      		<v-alert
				    	v-if="miEstatus == 1 && liberadaSabatina"
				      dense
				      text
				      type="success"
				    >
				      Felicidades, tu nómina ha sido aceptada con éxito!
				    </v-alert>

				    <v-alert
				    	v-if="miEstatus == 2 && liberadaSabatina"
				      dense
				      text
				      type="error"
				    >
				      Lo sentimos, estamos reviando tu rechazo, en un momento podrás revisarla nuevamente!
				    </v-alert>

		    	</v-card-text>


		    	<!-- Listado de maestros con su grupo -->
					<v-card-text v-if="getdatosUsuario.idpuesto == 18">

						<v-row>
							<v-col cols="12" md="4" lg="3">
								<v-autocomplete
	                :items="planteles"
	                v-model="plantel"
	                label="Plantel"
	                filled
	                hide-details
	                dense
	                clearable
	            	></v-autocomplete>
							</v-col>

							<v-col >
								<v-radio-group
						      v-model="opcionFiltro"
						      row
						      dense
						    >
						      <v-radio
						        label="Pendiente"
						        :value="0"
						      ></v-radio>
						      <v-radio
						        label="Aceptados"
						        :value="1"
						      ></v-radio>
						      <v-radio
						        label="Rechazados"
						        :value="2"
						      ></v-radio>
						      <v-radio
						        label="Todos"
						        :value="3"
						      ></v-radio>
						    </v-radio-group>
							</v-col>

							<v-col cols="12">
								<b>Avance: {{ avance }} %</b> 
								<br/>
								<b>Faltantes: {{ faltantes }}</b> 
							</v-col>

						</v-row>

            <v-data-table
            	v-if="liberadaSabatina"
		          :headers="headersNomina"
		          :items="filterGruposEncargada"
		          class="elevation-0 mt-4"
		          dense
		          :loading="cargar"
		        > 

		          <template v-slot:item.teacher="{ item }">
          			<v-chip 
          				v-if="item.estatus_sabatina" 
          				:color="item.estatus_sabatina == 1 ? 'green' : 'red'" 
          				dark
          				small 
          				@click="verComentarios( item.comentarios_sabatina )"
          			>
          				{{ item.teacher }}
          			</v-chip>

          			<v-chip 
          				v-else
          				color="grey" 
          				dark
          				small 
          				@click="verComentarios( item.comentarios_sabatina )"
          			>
          				{{ item.teacher }}
          			</v-chip>
          			
          		</template>

          		<template v-slot:item.grupos="{ item }">
          			<div 
          				v-for="(grupo, i ) in item.grupos"
          			>
          				{{ grupo }}
          			</div>          			
          		</template>

		        </v-data-table>
		    	</v-card-text>
				</v-card> 

				<v-row v-if="mostrarBono">
					<v-col cols="12" lg="6">
						<v-card class="shadowCard mt-6">
							<v-card-title primary-title>
				    	  <b>Bono de puntualidad</b>
							</v-card-title>
						  <v-card-text class="black--text" v-if="mostrarBono">

				    	  Hola, <b>{{ getdatosUsuario.nombre_completo }}</b>

				    	  <!-- En caso de obtner el bono -->
				    	  <div v-if="existeBono" class="green--text"><b>Felicidades obtuviste tu bono de puntualidad</b></div>
				    	  
				    	  <div v-else>
				    	  	Lo sentimos, <b class="red--text">no obtuviste tu bono de puntualidad</b>
				    	  	<br/>
				    	  	<br/>
				    	  	Cuentas con <b class="red--text">{{ conteoRetardo }}</b> retardos. 
				    	  	<br/>
				    	  	<br/>

				    	  	<v-alert
							      outlined
							      type="warning"
							      prominent
							      border="left"
							    >
				    	  		Recuerda que para contar con una asistencia correcta, deberás asistir 15 min antes de que inicie la clase.
							    </v-alert>

				    	  	<div v-if="conteoRetardo > 0" style="white-space: pre-line">
				    	  		{{ textoRetardos }}
				    	  	</div>

				    	  	<br/>
				    	  	<div v-if="motivoNoBono">Y además, retardos y/o faltas en las siguientes clases</div>
				    	  	<div class="text-subtitle-1" style="white-space: pre-line">{{ motivoNoBono }}</div>
				    	  </div>

				    	  <v-row>
						  		<v-col cols="12" md="8"  v-for="(mensaje, i) in comentariosB" :key="i">
								  	<p 
								  		:class="`pa-2 ma-0 mb-4 ${ mensaje.id_usuario != getdatosUsuario.iderp ? 'mensajeIzq' : 'mensajeDer' }`">
								    	{{ mensaje.comentarios }}
								    	<br/>
								    	<!-- {{ mensaje.nombre_completo }} -->
								  	</p>
						  		</v-col>
						  	</v-row>

				    	  <v-row v-if="miEstatusB == 0 && liberada2 ">
			          	<v-col cols="12" >
			          		
								    <v-btn 
								    	v-if="liberada2 && !rechazado2"
								    	color="success"
								    	dark
								    	tile
								    	class="mr-2"
								    	@click="dialogAceptar = true, tipo_nomina = 3"
								    	small
								    >
								  		Aceptar mi bono
								  		<v-icon small right>mdi-check</v-icon>
								  	</v-btn>


								  	<v-btn 
								  		v-if="liberada2 && !rechazado2"
								    	color="red darken-3"
								    	dark
								    	tile
								    	@click="rechazado2 = true, tipo_nomina = 3"
								    	small
								    >
								  		Rechazar mi bono
								  		<v-icon small right>mdi-close</v-icon>
								  	</v-btn>
			        		</v-col>

			        		<v-col cols="12" md="6" lg="4" v-if="liberada2 && rechazado2">
			        			<v-textarea
			        				v-model="motivo2"
			        				:rows="3"
			        				auto-grow
			        				filled
			        				dense
			        				label="Agrega tu motivo por favor"
			        			>
			        			</v-textarea>
								  	
								  	<v-btn 
								    	color="black"
								    	dark
								    	tile
								    	class="mr-2"
								    	@click="rechazado2 = false"
								    >
								  		Cancelar
								  		<v-icon small right>mdi-close</v-icon>
								  	</v-btn>

			        			<v-btn 
								    	color="success"
								    	dark
								    	tile
								    	class="mr-2"
								    	@click="save(2, 3)"
								    >
								  		Aceptar
								  		<v-icon small right>mdi-check</v-icon>
								  	</v-btn>
			        		</v-col>

			      		</v-row>

			      		<v-alert
						    	v-if="miEstatusB == 1 && liberada2"
						      dense
						      text
						      type="success"
						      class="mt-6"
						    >
						      Felicidades, tu nómina ha sido aceptada con éxito!
						    </v-alert>

						    <v-alert
						    	v-if="miEstatusB == 2 && liberada2"
						      dense
						      text
						      type="error"
						      class="mt-6"
						    >
						      Lo sentimos, estamos reviando tu rechazo, en un momento podrás revisarla nuevamente!
						    </v-alert>
				    	</v-card-text>
						</v-card>
					</v-col>
				</v-row>

			</v-col>

			<v-dialog v-model="dialogAceptar" max-width="500px">
				<v-card>
					<v-card-text class="text-h6 black--text">
						¿Estás seguro que deseas guardar la nómina?
					</v-card-text>
					<v-card-actions class="pt-6">
						<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
						<v-btn
							color="black"
							small
							tile
							dark
							class="mr-2"
							@click="dialogAceptar=false"
						>
							No, cancelar
							<v-icon small right>mdi-cancel</v-icon>
						</v-btn>
						<v-spacer></v-spacer>
						<!-- Guardar la información  -->
						<v-btn
							color="green"
							small
							dark
							tile
							@click="dialogAceptar = false, save( 1 )"
						>
							Si
							<v-icon small right>mdi-content-save</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>

		<v-dialog
		  v-model="dialogVerComentario"
		  max-width="500px"
		  v-if="comentarios"
		>
			<v-card>
				<v-card-title primary-title>
				  Comentarios de nómina
				</v-card-title>
			  <v-card-text>
			  	<v-row>
			  		<v-col cols="12"
			  			v-for="(mensaje, i) in comentarios" :key="i"
			  		>
					  	<p 
					  		:class="`pa-2 ma-0 mb-4 ${ mensaje.id_usuario != getdatosUsuario.id_trabajador ? 'mensajeIzq' : 'mensajeDer' }`">
					    	{{ mensaje.comentarios }}
					    	<br/>
					    	{{ mensaje.nombre_completo }}
					  	</p>
			  		</v-col>
			  	</v-row>
			    <br/>
			  </v-card-text>
			</v-card>	
		  
		</v-dialog>

		<!-- Dialog de carga -->
		<Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
		<carga                v-if="cargar"/>

	</v-container>

</template>
<script>
	import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
	import Alerta                from '@/components/alertas/Alerta.vue'
	import carga                 from '@/components/alertas/carga.vue';

	import botonesRoles          from '@/components/roles/botonesRoles.vue';

	import validarErrores  from '@/mixins/validarErrores'
	import XLSX            from 'xlsx'
	import funcionesExcel  from '@/mixins/funcionesExcel'


	export default {
		components:{
			Alerta,
			carga,
			botonesRoles,
		},

		mixins: [ validarErrores, funcionesExcel ],

		data: () => ({
    	// Alertas
			parametros:{
				dialogError: false,
				mensaje: '',
				color: ''
			},

			respuestaAlerta:false,
			loader: true,
			cargar: false,

			encabezados: null,
			encabezados2: null,
			dialogAceptar: false,
			
			ciclos:[],
			gruposRoles:[],
			gruposRoles2:[],
			gruposQuincena:[],
			pagos: [],
			ciclo:null,
			id_ciclo: 0,

      // Filtros: 
			fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // tabla
			search:'',
			search2:'',
			autorizarNomina: [],
			nominaEstatus: [],
			nominaTeachersSabatino: [],
			nominaTeachersCatorcena: [],
			asistencias: [],
			estatus: null,
			entradassalidasteachers: [],
			horasDobles:[],
			horasTotales: 0,
			gruposRoles2:[],

	    dialogComisiones: false,
			liberada: true,
			motivo: '',
			rechazado:false,

			teachersQ:[],
			gruposActivos:[],

			headers2Q: [
        { text: 'id'              ,  value: 'id_teacher'               },
        { text: 'Teacher'         ,  value: 'teacher'                  },  
        { text: 'H_LV_FAST'       ,  value: 'HORAS_ENTRE_SEMANA_FAST'  },  
        { text: 'H_D_FAST'        ,  value: 'HORAS_DOMINGO_FAST'       },  
        { text: 'H_ONL_FAST'      ,  value: 'HORAS_LMV_FAST'           },  
        { text: 'H_LV_INBI'       ,  value: 'HORAS_ENTRE_SEMANA_INBI'  },  
        { text: 'H_D_INBI'        ,  value: 'HORAS_DOMINGO_INBI'       },  
        { text: 'H_ONL_INBI'      ,  value: 'HORAS_LMV_INBI'           },
        { text: 'TOTAL_HORAS'     ,  value: 'HORAS_TOTALES'            },  
      ],

      horasTeacher: [],
			dialogHoras: false,

	    headersNomina: [
        { text: 'id'        , value: 'id_teacher'   },
        { text: 'Teacher'   , value: 'teacher'      },  
        { text: 'Plantel'   , value: 'plantel'      },  
        { text: 'Grupos'    , value: 'grupos'       },  
      ],


	    totalFinal:0,
	    existeBono: null,
			totalBono: 0,
			mostrarBono: false,

			liberada2: true,
			rechazado2: false,
			motivo2: '',
			conteoRetardo:0,
			motivoNoBono: '',
			rolReemplazo:[],
			rolRegistrado:[],
			tipo_nomina: null,
			motivo3:'',
			teachersEncargadas:[],

			comentarios:[],
			dialogVerComentario:false,
			planteles:[],
			plantel: null,
      opcionFiltro: 3,

      miEstatus: 0,
      textoRetardos:'',
			selectedHeaders: 0,
			miEstatusQ: false,
			comentariosQ: [],
			comentariosB: [],
			gruposAll:[],
			quincenaLiberada: false,
      liberadaSabatina: false,
      selectedHeadersQ: 0,
      semanaAseleccionar:[]
		}),

		computed: {
			...mapGetters( 'login' ,['getdatosUsuario']),

			filterGruposQuincena( ){
	  		const diasSemana = ['lu', 'ma', 'mi', 'ju', 'vi'];

	  		let semana1 = [3,4].includes( this.selectedHeadersQ ) ? 1 : 3
	  		let semana2 = [3,4].includes( this.selectedHeadersQ ) ? 2 : 4


	      const bloquesTiempo = [semana1,semana2];

	      let grupos = this.gruposQuincena.filter(el => {
	        for (const dia of diasSemana) {
	          for (const bloque of bloquesTiempo) {
	            if (el[`${dia}${bloque}`] === this.getdatosUsuario.iderp && el[`${dia}${bloque}_n`] != '' ) {
	              return true; // Si encuentra una coincidencia, incluye el grupo en el resultado
	            }
	          }
	        }
	        return false; // Si no hay coincidencias en ningún día ni bloque, excluye el grupo
	      });

	      let totalFinal = 0

	      for( const i in grupos ){

	      	let total = 0

	      	if( grupos[i][`lu${semana1}`] == this.getdatosUsuario.iderp ){

	      		total += grupos[i].grupo.match('DOMINI') ? 1 : grupos[i].horas_dia

	      	}

	      	if( grupos[i][`ma${semana1}`] == this.getdatosUsuario.iderp ){

	      		total += grupos[i].grupo.match('DOMINI') ? 1 : grupos[i].horas_dia

	      	}

	      	if( grupos[i][`mi${semana1}`] == this.getdatosUsuario.iderp ){

	      		total += grupos[i].grupo.match('DOMINI') ? 1 : grupos[i].horas_dia

	      	}

	      	if( grupos[i][`ju${semana1}`] == this.getdatosUsuario.iderp ){

	      		total += grupos[i].grupo.match('DOMINI') ? 1 : grupos[i].horas_dia

	      	}

	      	if( grupos[i][`vi${semana1}`] == this.getdatosUsuario.iderp ){

	      		total += grupos[i].grupo.match('DOMINI') ? 1 : grupos[i].horas_dia

	      	}

	      	/*********************************************/

	      	if( grupos[i][`lu${semana2}`] == this.getdatosUsuario.iderp ){

	      		total += grupos[i].grupo.match('DOMINI') ? 1 : grupos[i].horas_dia

	      	}

	      	if( grupos[i][`ma${semana2}`] == this.getdatosUsuario.iderp ){

	      		total += grupos[i].grupo.match('DOMINI') ? 1 : grupos[i].horas_dia

	      	}

	      	if( grupos[i][`mi${semana2}`] == this.getdatosUsuario.iderp ){

	      		total += grupos[i].grupo.match('DOMINI') ? 1 : grupos[i].horas_dia

	      	}

	      	if( grupos[i][`ju${semana2}`] == this.getdatosUsuario.iderp ){

	      		total += grupos[i].grupo.match('DOMINI') ? 1 : grupos[i].horas_dia

	      	}

	      	if( grupos[i][`vi${semana2}`] == this.getdatosUsuario.iderp ){

	      		total += grupos[i].grupo.match('DOMINI') ? 1 : grupos[i].horas_dia

	      	}


	      	grupos[i]['total'] = total

	      	// total

	      	totalFinal += total

	      }

	      this.totalFinal = totalFinal

	      return grupos
			},

			filterGruposSabatino( ){
	  		const diasSemana = ['lu', 'ma', 'mi', 'ju', 'vi'];
	      const bloquesTiempo = [this.selectedHeaders];

	      let grupos = this.gruposActivos.filter(el => {
	        for (const dia of diasSemana) {
	          for (const bloque of bloquesTiempo) {
	            if (el[`${dia}${bloque}`] === this.getdatosUsuario.iderp && el[`${dia}${bloque}_n`] != '' ) {
	              return true; // Si encuentra una coincidencia, incluye el grupo en el resultado
	            }
	          }
	        }
	        return false; // Si no hay coincidencias en ningún día ni bloque, excluye el grupo
	      });

	      let totalFinalS = 0

	      for( const i in grupos ){

	      	let total = 0

	      	if( grupos[i][`lu${this.selectedHeaders}`] == this.getdatosUsuario.iderp ){

	      		total += grupos[i].grupo.match('SABATINO') ? 1 : grupos[i].horas_dia

	      	}

	      	if( grupos[i][`ma${this.selectedHeaders}`] == this.getdatosUsuario.iderp ){

	      		total += grupos[i].grupo.match('SABATINO') ? 1 : grupos[i].horas_dia

	      	}

	      	if( grupos[i][`mi${this.selectedHeaders}`] == this.getdatosUsuario.iderp ){

	      		total += grupos[i].grupo.match('SABATINO') ? 1 : grupos[i].horas_dia

	      	}

	      	if( grupos[i][`ju${this.selectedHeaders}`] == this.getdatosUsuario.iderp ){

	      		total += grupos[i].grupo.match('SABATINO') ? 1 : grupos[i].horas_dia

	      	}

	      	if( grupos[i][`vi${this.selectedHeaders}`] == this.getdatosUsuario.iderp ){

	      		total += grupos[i].grupo.match('SABATINO') ? 1 : grupos[i].horas_dia

	      	}

	      	/*********************************************/

	      	grupos[i]['total'] = total

	      	// total

	      	totalFinalS += total

	      }

	      this.totalFinalS = totalFinalS

	      return grupos
			},

			filterGruposEncargada( ){

				let teachers = this.teachersEncargadas

				if( this.plantel ){
					teachers = teachers.filter( el => el.plantel == this.plantel )
				}

				if( this.opcionFiltro != 3 ){


					if( this.opcionFiltro == 2 ){

						return teachers.filter( el => el.estatus_sabatina == 2 )

					}else if( this.opcionFiltro == 1 ){

						return teachers.filter( el => el.estatus_sabatina == 1 )

					}else{

						return teachers.filter( el => el.estatus_sabatina == 0 )
					
					}

				}

				return teachers
			},

			avance( ){

				let total = this.filterGruposEncargada.length

				let aceptados = this.filterGruposEncargada.filter( el => el.estatus_sabatina == 1 ).length

				return (( aceptados / total ) * 100 ).toFixed(2)
			},

			faltantes( ){

				return this.filterGruposEncargada.filter( el => el.estatus_sabatina == 0 || el.estatus_sabatina == 2  ).length

			},

			headersBloque1( ) {
				let semana1 = [3,4].includes( this.selectedHeadersQ ) ? 1 : 3 
				let semana2 = [3,4].includes( this.selectedHeadersQ ) ? 2 : 4 

				return [
		      { text: 'Grupo' , value: 'grupo'       },
		      { text: `Lu ${semana1}`  , value: `lu${semana1}_n`       },
		      { text: `Ma ${semana1}`  , value: `ma${semana1}_n`       },
		      { text: `Mi ${semana1}`  , value: `mi${semana1}_n`       },
		      { text: `Ju ${semana1}`  , value: `ju${semana1}_n`       },
		      { text: `Vi ${semana1}`  , value: `vi${semana1}_n`       },
		      { text: `Lu ${semana2}`  , value: `lu${semana2}_n`       },
		      { text: `Ma ${semana2}`  , value: `ma${semana2}_n`       },
		      { text: `Mi ${semana2}`  , value: `mi${semana2}_n`       },
		      { text: `Ju ${semana2}`  , value: `ju${semana2}_n`       },
		      { text: `Vi ${semana2}`  , value: `vi${semana2}_n`       },
		      { text: 'TOTAL' , value: 'total'       },
		    ]
			},

			headersBloqueS(){
				return  [
		      { text: 'Grupo' , value: 'grupo'       },
		      { text: `Lu ${this.selectedHeaders}`  , value: `lu${this.selectedHeaders}_n`       },
		      { text: `Ma ${this.selectedHeaders}`  , value: `ma${this.selectedHeaders}_n`       },
		      { text: `Mi ${this.selectedHeaders}`  , value: `mi${this.selectedHeaders}_n`       },
		      { text: `Ju ${this.selectedHeaders}`  , value: `ju${this.selectedHeaders}_n`       },
		      { text: `Vi ${this.selectedHeaders}`  , value: `vi${this.selectedHeaders}_n`       },
		      { text: 'TOTAL' , value: 'total'       },
				]
	    }

		},

		watch:{

			async selectedHeaders( value ){
				if( value ){ 
					await this.initialize()
					await this.getEstatusNominaTeacher( )
				}
			}

		},


		async created () {
			await this.getCiclosFecha()
			await this.getDatosNominaTeacher( )
			// await this.initialize()
			// await this.getEstatusNominaTeacher( )
		},

		methods: {

			getDatosNominaTeacher( ){

				if( !this.ciclo ){
	    		return this.validarErrorDirecto('Selecciona un ciclo por favor')
	    	}

	    	this.cargar      = true

	      this.$http.post('rol.clases.datos', this.ciclo ).then(async response=>{

					this.semanaAseleccionar = response.data.fechas
	      	this.quincenaLiberada   = response.data.quincenaLiberada
					this.liberadaSabatina   = response.data.liberadaSabatina
					this.selectedHeadersQ   = response.data.selectedHeadersQ
	        // this.cargar      = false
	      }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
			},

			initialize () {
	    	if( !this.ciclo ){
	    		return this.validarErrorDirecto('Selecciona un ciclo por favor')
	    	}

	    	this.gruposRoles = []
	    	this.cargar      = true
	      this.$http.post('rol.clases', this.ciclo ).then(async response=>{

	        this.gruposActivos      = response.data.respuesta.filter( el => { return el.deleted == 0 && el.sabado }).sort((a, b) => a.grupo - b.grupo)
	        
	        // Veremos el this.selectedHeaders == 3 si es 3, la quincena es 2, semana 1 y 2 del ciclo actual
	        if( this.selectedHeadersQ == 3 ){
	        	this.gruposAll       = response.data.respuesta.filter( el => { return el.deleted == 0 }).sort((a, b) => a.grupo - b.grupo)
	        	this.gruposQuincena  = response.data.respuesta.filter( el => { return el.deleted == 0 && !el.sabado }).sort((a, b) => a.grupo - b.grupo)
	        }
	        
	        this.pagos            = response.data.teachers
	        this.horasDobles      = response.data.horasDobles
        	this.comentariosT     = response.data.estatusNominaQuincena

        	const existeMiEstatus = this.comentariosT.find( el => el.id_usuario == this.getdatosUsuario.iderp && el.semana == this.selectedHeaders && el.tipo_nomina == 1 )

        	this.miEstatus = existeMiEstatus ? existeMiEstatus.estatus : 0

        	// Quincena
        	const existeMiEstatusQ = this.comentariosT.find( el => el.id_usuario == this.getdatosUsuario.iderp && el.semana == this.selectedHeadersQ && el.tipo_nomina == 2 )

        	this.comentariosQ = existeMiEstatusQ ? existeMiEstatusQ.comentarios.filter( el => el.id_usuario != 169) : []

        	this.miEstatusQ = existeMiEstatusQ ? existeMiEstatusQ.estatus : 0

        	// BONO
        	// Quincena
        	const existeMiEstatusB = this.comentariosT.find( el => el.id_usuario == this.getdatosUsuario.iderp && el.semana == this.selectedHeadersQ && el.tipo_nomina == 3 )

        	this.comentariosB = existeMiEstatusB ? existeMiEstatusB.comentarios.filter( el => el.id_usuario != 169) : []

        	this.miEstatusB = existeMiEstatusB ? existeMiEstatusB.estatus : 0

        	for( const i in this.gruposActivos ){
		    		const { grupo } = this.gruposActivos[i]
		      	if( ( grupo.match('SABATI') && grupo.match('TEENS') ) || ( grupo.match('DOMINICAL') && grupo.match('TEENS') ) ){
		    			this.gruposActivos[i].ju1_n = null
		    			this.gruposActivos[i].ju1   = null
		    			this.gruposActivos[i].vi1_n = null
		    			this.gruposActivos[i].vi1   = null
		    			this.gruposActivos[i].ju2_n = null
		    			this.gruposActivos[i].ju2   = null
		    			this.gruposActivos[i].vi2_n = null
		    			this.gruposActivos[i].vi2   = null
		    			this.gruposActivos[i].ju3_n = null
		    			this.gruposActivos[i].ju3   = null
		    			this.gruposActivos[i].vi3_n = null
		    			this.gruposActivos[i].vi3   = null
		    			this.gruposActivos[i].ju4_n = null
		    			this.gruposActivos[i].ju4   = null
		    			this.gruposActivos[i].vi4_n = null
		    			this.gruposActivos[i].vi4   = null
		    		}      		
		    	}

		    	for( const i in this.gruposAll ){
		    		const { grupo } = this.gruposAll[i]
		      	if( ( grupo.match('SABATI') && grupo.match('TEENS') ) || ( grupo.match('DOMINICAL') && grupo.match('TEENS') ) ){
		    			this.gruposAll[i].ju1_n = null
		    			this.gruposAll[i].ju1   = null
		    			this.gruposAll[i].vi1_n = null
		    			this.gruposAll[i].vi1   = null
		    			this.gruposAll[i].ju2_n = null
		    			this.gruposAll[i].ju2   = null
		    			this.gruposAll[i].vi2_n = null
		    			this.gruposAll[i].vi2   = null
		    			this.gruposAll[i].ju3_n = null
		    			this.gruposAll[i].ju3   = null
		    			this.gruposAll[i].vi3_n = null
		    			this.gruposAll[i].vi3   = null
		    			this.gruposAll[i].ju4_n = null
		    			this.gruposAll[i].ju4   = null
		    			this.gruposAll[i].vi4_n = null
		    			this.gruposAll[i].vi4   = null
		    		}      		
		    	}
        	await this.obtenerTeachersGrupos( this.gruposActivos )

	        await this.getNominaTeacher2( )


	        // this.cargar      = false
	      }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
			},

			obtenerTeachersGrupos( data ){

				let coordinadoras = [7,526,626,568,382,161]

				let planteles = this.getdatosUsuario.planteles.map(( registro) => { return registro.idplantel })
				let grupos = coordinadoras.includes( this.getdatosUsuario.iderp ) ? data : data.filter( el => planteles.includes( el.id_plantel ))


				this.planteles = grupos.map((registro) => { return registro.plantel })


				for( const i in grupos ){
	    		const { grupo } = grupos[i]
	      	if( ( grupo.match('SABATI') && grupo.match('TEENS') ) || ( grupo.match('DOMINICAL') && grupo.match('TEENS') ) ){
	    			grupos[i].ju1_n = null
	    			grupos[i].ju1   = null
	    			grupos[i].vi1_n = null
	    			grupos[i].vi1   = null
	    			grupos[i].ju2_n = null
	    			grupos[i].ju2   = null
	    			grupos[i].vi2_n = null
	    			grupos[i].vi2   = null
	    			grupos[i].ju3_n = null
	    			grupos[i].ju3   = null
	    			grupos[i].vi3_n = null
	    			grupos[i].vi3   = null
	    			grupos[i].ju4_n = null
	    			grupos[i].ju4   = null
	    			grupos[i].vi4_n = null
	    			grupos[i].vi4   = null
	    		}      		
	    	}

	    	let semana = this.selectedHeaders

	    	// Sacar a los teachers que dan clase
	    	// Vamos a sacar los teachers de cada bloque
	      let tlu1 = grupos.filter( el => { return el[`lu${semana}_n`] }).map((registro) => { return { id_teacher: registro[`lu${semana}`], teacher: registro[`lu${semana}_n`]}})
	      let tma1 = grupos.filter( el => { return el[`ma${semana}_n`] }).map((registro) => { return { id_teacher: registro[`ma${semana}`], teacher: registro[`ma${semana}_n`]}})
	      let tmi1 = grupos.filter( el => { return el[`mi${semana}_n`] }).map((registro) => { return { id_teacher: registro[`mi${semana}`], teacher: registro[`mi${semana}_n`]}})
	      let tju1 = grupos.filter( el => { return el[`ju${semana}_n`] }).map((registro) => { return { id_teacher: registro[`ju${semana}`], teacher: registro[`ju${semana}_n`]}})
	      let tvi1 = grupos.filter( el => { return el[`vi${semana}_n`] }).map((registro) => { return { id_teacher: registro[`vi${semana}`], teacher: registro[`vi${semana}_n`]}})

	      // Concatenamos a los maestros
	      let arregloTeachers = tlu1.concat( tma1 ).concat( tmi1 ).concat( tju1 ).concat( tvi1 )
	        
	      // Quitamos los duplicados de los maestros
	      this.teachersEncargadas = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);

		      // Recorremos los maestros para ir agregando las cosas
				for( const i in this.teachersEncargadas ){

					// Sacamos el id del teacher
					const { id_teacher } = this.teachersEncargadas[i]

					// Sacamos las clases donde esta el maestro 
					let clasesSabado = grupos.filter( el => el[`lu${this.selectedHeaders}`] == id_teacher || el[`ma${this.selectedHeaders}`] == id_teacher || el[`mi${this.selectedHeaders}`] == id_teacher || el[`ju${this.selectedHeaders}`] == id_teacher || el[`vi${this.selectedHeaders}`] == id_teacher )


					// AGREGAREMOS LOS PAGOS
					let clasesTeacher = grupos.filter( el => el[`lu${this.selectedHeaders}`] == id_teacher || el[`ma${this.selectedHeaders}`] == id_teacher || el[`mi${this.selectedHeaders}`] == id_teacher || el[`ju${this.selectedHeaders}`] == id_teacher || el[`vi${this.selectedHeaders}`] == id_teacher )

					this.teachersEncargadas[i]['plantel']             = clasesSabado.length ? clasesSabado[0].plantel : 'SIN PLANTEL'

					// Aquí... poner esa cosa de los comentarios
					const existeEstatusS = this.comentariosT.find( el => el.id_usuario == id_teacher && el.semana == this.selectedHeaders && el.tipo_nomina == 1 )

					this.teachersEncargadas[i]['estatus_sabatina']     = existeEstatusS ? existeEstatusS.estatus : 0 
					this.teachersEncargadas[i]['comentarios_sabatina'] = existeEstatusS ? existeEstatusS.comentarios : [] 

					this.teachersEncargadas[i]['grupos'] = clasesTeacher.map((registro) => { return registro.grupo })


				}

			},

			getEstatusNominaTeacher () {
	    	
	    	const payload = {
	    		id_ciclo  : this.ciclo.id_ciclo,
	    		semana    : this.selectedHeaders,
	    		id_usuario: this.getdatosUsuario.iderp
	    	}

	    	this.cargar      = true
	      
	      this.$http.post('rol.clases', this.ciclo ).then(async response=>{
	        this.gruposActivos    = response.data.respuesta.filter( el => { return el.deleted == 0 && el.sabado }).sort((a, b) => a.grupo - b.grupo)
	        this.pagos            = response.data.teachers
	        this.horasDobles      = response.data.horasDobles

	        for( const i in this.gruposActivos ){
		    		const { grupo } = this.gruposActivos[i]
		      	if( ( grupo.match('SABATI') && grupo.match('TEENS') ) || ( grupo.match('DOMINICAL') && grupo.match('TEENS') ) ){
		    			this.gruposActivos[i].ju1_n = null
		    			this.gruposActivos[i].ju1   = null
		    			this.gruposActivos[i].vi1_n = null
		    			this.gruposActivos[i].vi1   = null
		    			this.gruposActivos[i].ju2_n = null
		    			this.gruposActivos[i].ju2   = null
		    			this.gruposActivos[i].vi2_n = null
		    			this.gruposActivos[i].vi2   = null
		    			this.gruposActivos[i].ju3_n = null
		    			this.gruposActivos[i].ju3   = null
		    			this.gruposActivos[i].vi3_n = null
		    			this.gruposActivos[i].vi3   = null
		    			this.gruposActivos[i].ju4_n = null
		    			this.gruposActivos[i].ju4   = null
		    			this.gruposActivos[i].vi4_n = null
		    			this.gruposActivos[i].vi4   = null
		    		}      		
		    	}

	        this.cargar      = false
	      }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
			},

			getNominaTeacher2( ){
				this.gruposRoles2 = []
				this.cargar      = true

				return this.$http.post('rol.clases.nomina2', this.ciclo ).then(response=>{
					this.gruposRoles2     = response.data.respuesta.filter( el => { return el.deleted == 0 && el.sabado }).sort((a, b) => a.grupo - b.grupo)
					if( this.selectedHeadersQ == 1 ){
	        	this.gruposAll      = response.data.respuesta.filter( el => { return el.deleted == 0 }).sort((a, b) => a.grupo - b.grupo)
	        	this.gruposQuincena = response.data.respuesta.filter( el => { return el.deleted == 0 && !el.sabado }).sort((a, b) => a.grupo - b.grupo)
	          for( const i in this.gruposAll ){
			    		const { grupo } = this.gruposAll[i]
			      	if( ( grupo.match('SABATI') && grupo.match('TEENS') ) || ( grupo.match('DOMINICAL') && grupo.match('TEENS') ) ){
			    			this.gruposAll[i].ju1_n = null
			    			this.gruposAll[i].ju1   = null
			    			this.gruposAll[i].vi1_n = null
			    			this.gruposAll[i].vi1   = null
			    			this.gruposAll[i].ju2_n = null
			    			this.gruposAll[i].ju2   = null
			    			this.gruposAll[i].vi2_n = null
			    			this.gruposAll[i].vi2   = null
			    			this.gruposAll[i].ju3_n = null
			    			this.gruposAll[i].ju3   = null
			    			this.gruposAll[i].vi3_n = null
			    			this.gruposAll[i].vi3   = null
			    			this.gruposAll[i].ju4_n = null
			    			this.gruposAll[i].ju4   = null
			    			this.gruposAll[i].vi4_n = null
			    			this.gruposAll[i].vi4   = null
			    		}      		
			    	}
	        }
	        // this.gruposQuincena   = response.data.respuesta.filter( el => { return el.deleted == 0 && !el.sabado }).sort((a, b) => a.grupo - b.grupo)
          this.rolReemplazo     = response.data.rolReemplazo
          this.rolRegistrado    = response.data.rolRegistrado
          // this.comentariosT     = response.data.estatusNominaQuincena


	        this.sacarTeachersQ( )

	        this.sacarHorasQuincena1( )
	        
	        this.sacarHorasQuincena1( )

	        this.sacarMotivoNoBono( )

				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

			sacarTeachersQ ( ) {

      	let bloqueInicial = [3,4].includes( this.selectedHeadersQ ) ? 1 : 3
      	let bloqueFinal   = [3,4].includes( this.selectedHeadersQ ) ? 2 : 4

	      // Vamos a sacar los teachers de cada bloque
        let tlu1 = this.gruposQuincena.filter( el => { return ( el.lunes || el.domingo || el.sabado ) && el[`lu${bloqueInicial}_n`] })
        														.map((registro) => { return { id_teacher: registro[`lu${bloqueInicial}`], teacher: registro[`lu${bloqueInicial}_n`]  }})

        let tma1 = this.gruposQuincena.filter( el => { return ( el.martes || el.domingo || el.sabado ) && el[`ma${bloqueInicial}_n`] })
        														.map((registro) => { return { id_teacher: registro[`ma${bloqueInicial}`], teacher: registro[`ma${bloqueInicial}_n`]  }})

        let tmi1 = this.gruposQuincena.filter( el => { return ( el.miercoles || el.domingo || el.sabado ) && el[`mi${bloqueInicial}_n`] })
        														.map((registro) => { return { id_teacher: registro[`mi${bloqueInicial}`], teacher: registro[`mi${bloqueInicial}_n`]  }})

        let tju1 = this.gruposQuincena.filter( el => { return ( el.jueves || el.domingo || el.sabado ) && el[`ju${bloqueInicial}_n`] })
        														.map((registro) => { return { id_teacher: registro[`ju${bloqueInicial}`], teacher: registro[`ju${bloqueInicial}_n`]  }})

        let tvi1 = this.gruposQuincena.filter( el => { return ( el.viernes || el.domingo || el.sabado ) && el[`vi${bloqueInicial}_n`] })
        														.map((registro) => { return { id_teacher: registro[`vi${bloqueInicial}`], teacher: registro[`vi${bloqueInicial}_n`]  }})
        // Vamos a sacar los teachers de cada bloque
        let tlu2 = this.gruposQuincena.filter( el => { return ( el.lunes || el.domingo || el.sabado ) && el[`lu${bloqueFinal}_n`] })
        														.map((registro) => { return { id_teacher: registro[`lu${bloqueFinal}`], teacher: registro[`lu${bloqueFinal}_n`]  }})

        let tma2 = this.gruposQuincena.filter( el => { return ( el.martes || el.domingo || el.sabado ) && el[`ma${bloqueFinal}_n`] })
        														.map((registro) => { return { id_teacher: registro[`ma${bloqueFinal}`], teacher: registro[`ma${bloqueFinal}_n`]  }})

        let tmi2 = this.gruposQuincena.filter( el => { return ( el.miercoles || el.domingo || el.sabado ) && el[`mi${bloqueFinal}_n`] })
        														.map((registro) => { return { id_teacher: registro[`mi${bloqueFinal}`], teacher: registro[`mi${bloqueFinal}_n`]  }})

        let tju2 = this.gruposQuincena.filter( el => { return ( el.jueves || el.domingo || el.sabado ) && el[`ju${bloqueFinal}_n`] })
        														.map((registro) => { return { id_teacher: registro[`ju${bloqueFinal}`], teacher: registro[`ju${bloqueFinal}_n`]  }})

        let tvi2 = this.gruposQuincena.filter( el => { return ( el.viernes || el.domingo || el.sabado ) && el[`vi${bloqueFinal}_n`] })
        														.map((registro) => { return { id_teacher: registro[`vi${bloqueFinal}`], teacher: registro[`vi${bloqueFinal}_n`]  }})

        // Concatenamos a los maestros
        let arregloTeachers = tlu1.concat( tma1 ).concat( tmi1 ).concat( tju1 ).concat( tvi1 ).concat(tlu2).concat( tma2 ).concat( tmi2 ).concat( tju2 ).concat( tvi2 )

        // Quitamos los duplicados de los maestros
        this.teachersQ = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);
      },

      sacarHorasQuincena1 ( ) {

      	try{

	      	let semana = this.selectedHeadersQ - 1 == -1 ? 4  : this.selectedHeadersQ - 1 

	      	let clasesDobles = this.horasDobles.filter( el => el.tipo == 2 )

	      	let bloqueInicial = [1,2].includes( semana ) ? 1 : 3
	      	let bloqueFinal   = [1,2].includes( semana ) ? 2 : 4

					this.teachersQ = this.teachersQ.filter( el => el.id_teacher == this.getdatosUsuario.iderp )

					// Recorremos los maestros para ir agregando las cosas
					for( const i in this.teachersQ ){

						// Sacamos el id del teacher
						const { id_teacher, teacher } = this.teachersQ[i]

						// Sacamos las clases que le pertenecen al teacher 
						const clasesTeacher = this.gruposQuincena.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																			.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher) || ['lu', 'ma', 'mi', 'ju', 'vi']
																			.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher)) && ( !el.domingo && !el.sabado && el.horas_dia != 1.5 ));

						// SACAR LAS CLASES DOMINICALES
						const clasesTeacherDomingo = this.gruposQuincena.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																					.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																					.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.domingo );

						// SACAR LAS CLASES SABATINO
						const clasesTeacherSabado = this.gruposQuincena.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																					.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																					.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.sabado );

						// CLASES DE 1.5 HORAS
						const clasesLMV = this.gruposQuincena.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																					.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																					.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.horas_dia == 1.5 );

						// Lo separamos por escuela
						const clasesFast = clasesTeacher.filter( el => { return el.id_unidad_negocio == 2})
						const clasesInbi = clasesTeacher.filter( el => { return el.id_unidad_negocio == 1})

						// CLASES FAST DOMINGO
						const clasesFastDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 2})
						const clasesInbiDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 1})

						// CLASES FAST SABADO
						const clasesFastSabado = clasesTeacherSabado.filter( el => { return el.id_unidad_negocio == 2})
						const clasesInbiSabado = clasesTeacherSabado.filter( el => { return el.id_unidad_negocio == 1})

						// CLASES LMV
						const clasesFastLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 2})
						const clasesInbiLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 1})

						let clasesFastLV = 0
						let clasesFastLM = 0
						let clasesFastDom = 0
						let clasesInbiLV = 0
						let clasesInbiLM = 0
						let clasesInbiDom = 0


						// CONTADOR ENTRE SEMANA
						let contadorBloquesFast = 0
						for( const i in clasesFast ){

							// ENTRE SEMANA
							contadorBloquesFast += clasesFast[i][`lu${bloqueInicial}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
							contadorBloquesFast += clasesFast[i][`ma${bloqueInicial}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
							contadorBloquesFast += clasesFast[i][`mi${bloqueInicial}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
							contadorBloquesFast += clasesFast[i][`ju${bloqueInicial}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
							contadorBloquesFast += clasesFast[i][`vi${bloqueInicial}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0

							// ENTRE SEMANA
							contadorBloquesFast += clasesFast[i][`lu${bloqueFinal}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
							contadorBloquesFast += clasesFast[i][`ma${bloqueFinal}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
							contadorBloquesFast += clasesFast[i][`mi${bloqueFinal}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
							contadorBloquesFast += clasesFast[i][`ju${bloqueFinal}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
							contadorBloquesFast += clasesFast[i][`vi${bloqueFinal}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0
						}

						clasesFastLV += contadorBloquesFast

						// CONTADOR FAST DOMINGOS
						let contadorBloquesFastDomingo = 0
						for( const i in clasesFastDomingo ){

							// DOMINICALES
							contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
							contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
							contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
							contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
							contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

							contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
							contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
							contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
							contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
							contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0

							// DOMINICALES
							clasesFastDom += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
							clasesFastDom += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
							clasesFastDom += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
							clasesFastDom += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
							clasesFastDom += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

							clasesFastDom += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
							clasesFastDom += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
							clasesFastDom += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
							clasesFastDom += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
							clasesFastDom += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0
						}

						// CONTADOR CLASES DE 1.5 HORAS
						let contadorBloquesFastLMV = 0
						for( const i in clasesFastLMV ){

							// ENTRE SEMANA
							contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
							contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
							contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
							contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
							contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

							contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
							contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
							contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
							contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
							contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

						}

						// INBIIIIIIIIIII CLASES ENTRE SEMANA
						// CONTADOR ENTRE SEMANA
						let contadorBloquesINBI = 0
						for( const i in clasesInbi ){

							// ENTRE SEMANA BOQUE 1 o 3
							contadorBloquesINBI += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
							contadorBloquesINBI += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
							contadorBloquesINBI += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
							contadorBloquesINBI += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
							contadorBloquesINBI += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0
							// ENTRE SEMANA BOQUE 2 o 4
							contadorBloquesINBI += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
							contadorBloquesINBI += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
							contadorBloquesINBI += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
							contadorBloquesINBI += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
							contadorBloquesINBI += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0

							// ENTRE SEMANA BOQUE 1 o 3
							clasesInbiLV += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
							clasesInbiLV += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
							clasesInbiLV += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
							clasesInbiLV += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
							clasesInbiLV += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0
							// ENTRE SEMANA BOQUE 2 o 4
							clasesInbiLV += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
							clasesInbiLV += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
							clasesInbiLV += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
							clasesInbiLV += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
							clasesInbiLV += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0

						}

						// CONTADOR ENTRE SEMANA
						let contadorBloquesInbiDomingo = 0
						for( const i in clasesInbiDomingo ){

							// DOMINICALES
							contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
							contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
							contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
							contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
							contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

							contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
							contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
							contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
							contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
							contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0

							clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
							clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
							clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
							clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
							clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

							clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
							clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
							clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
							clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
							clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0

						}

						// CONTADOR ENTRE SEMANA
						let contadorBloquesInbiLMV = 0
						for( const i in clasesInbiLMV ){

							// ENTRE SEMANA
							contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
							contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
							contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
							contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
							contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0

							contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
							contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
							contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
							contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
							contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0


							clasesInbiLM += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
							clasesInbiLM += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
							clasesInbiLM += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
							clasesInbiLM += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
							clasesInbiLM += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0

							clasesInbiLM += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
							clasesInbiLM += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
							clasesInbiLM += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
							clasesInbiLM += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
							clasesInbiLM += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0
						}


						const existeCategoria = this.pagos.find( el => el.id_usuario  == id_teacher )

						let hora_1 = existeCategoria ? existeCategoria.hora_1 : 0
						let hora_2 = existeCategoria ? existeCategoria.hora_2 : 0
						let hora_3 = existeCategoria ? existeCategoria.hora_3 : 0



						// AGREGAREMOS LOS PAGOS
						this.teachersQ[i]['categoria_lv']  = hora_1
						this.teachersQ[i]['categoria_d']   = hora_3

						/***********************************************************************/
						this.teachersQ[i]['HORAS_ENTRE_SEMANA_FAST']   = contadorBloquesFast
						this.teachersQ[i]['HORAS_DOMINGO_FAST']        = contadorBloquesFastDomingo
						this.teachersQ[i]['HORAS_LMV_FAST']            = contadorBloquesFastLMV

						/************************************************************************/
						this.teachersQ[i]['HORAS_ENTRE_SEMANA_INBI']   = contadorBloquesINBI
						this.teachersQ[i]['HORAS_DOMINGO_INBI']        = contadorBloquesInbiDomingo
						this.teachersQ[i]['HORAS_LMV_INBI']            = contadorBloquesInbiLMV
						/************************************************************************/
						this.teachersQ[i]['HORAS_TOTALES']                = contadorBloquesFast + contadorBloquesFastDomingo + contadorBloquesFastLMV + contadorBloquesINBI + contadorBloquesInbiDomingo + contadorBloquesInbiLMV 
						this.teachersQ[i]['HORAS_TOTALES_LV']             = contadorBloquesFast + contadorBloquesFastLMV + contadorBloquesINBI + contadorBloquesInbiLMV 
						this.teachersQ[i]['HORAS_TOTALES_D']              = contadorBloquesFastDomingo + contadorBloquesInbiDomingo 
						this.teachersQ[i]['PAGO_TOTAL']                = ( contadorBloquesFast * hora_1 ) + ( contadorBloquesFastDomingo * hora_3 ) + ( contadorBloquesFastLMV * hora_1 ) + ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesInbiLMV * hora_1 ) 

						/************************************************************************/

						// CLASES DOBLES
						const pagosDoblesTeachers = clasesDobles.filter(el => [`lu${bloqueInicial}`, `ma${bloqueInicial}`, `mi${bloqueInicial}`, `ju${bloqueInicial}`, `vi${bloqueInicial}`].includes( el.bloque ) && el.id_teacher_reemplazo == id_teacher || [`lu${bloqueFinal}`, `ma${bloqueFinal}`, `mi${bloqueFinal}`, `ju${bloqueFinal}`, `vi${bloqueFinal}`].includes( el.bloque ) && el.id_teacher_reemplazo == id_teacher);

						const rebajesDobles = clasesDobles.filter(el => [`lu${bloqueInicial}`, `ma${bloqueInicial}`, `mi${bloqueInicial}`, `ju${bloqueInicial}`, `vi${bloqueInicial}`].includes( el.bloque ) && el.id_teacher_falto == id_teacher || [`lu${bloqueFinal}`, `ma${bloqueFinal}`, `mi${bloqueFinal}`, `ju${bloqueFinal}`, `vi${bloqueFinal}`].includes( el.bloque ) && el.id_teacher_falto == id_teacher);

						let pagoDoble = pagosDoblesTeachers.map(item => item.monto_pago).reduce((prev, curr) => prev + curr, 0)
						let rebajeDoble = rebajesDobles.map(item => item.monto_rebaje).reduce((prev, curr) => prev + curr, 0)
						
						this.teachersQ[i]['PAGO_DOBLES'] = pagoDoble
						this.teachersQ[i]['REBAJES']     = rebajeDoble

						// BONO 
						if( this.selectedHeaders == 1 ){

							this.mostrarBono = true
							
							let bonos = this.cacularBonoTeachers()
							
							let bono = bonos.find( el => el.id_teacher == this.getdatosUsuario.iderp )

							this.existeBono = bono && bono.bono ? true : false

						}


						/************************************************************************/
					}
      	}catch( error ){
      		this.validarErrorDirecto( error.message )
      	}
      },

      cacularBonoTeachers(){

      	try{

					// Ordenar el arreglo de eventos por la hora de inicio
					this.gruposQuincena.sort(this.compararHorasInicio);

		    	// VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
		    	// Vamos a sacar los teachers de cada bloque
		      let sa1 = this.gruposQuincena.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${1}_n`] && el[`lu${1}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
					let sa2 = this.gruposQuincena.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${2}_n`] && el[`lu${2}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
					let sa3 = this.gruposQuincena.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${3}_n`] && el[`lu${3}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
					let sa4 = this.gruposQuincena.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${4}_n`] && el[`lu${4}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})

		    	// Vamos a sacar los teachers de cada bloque
		      let sa11 = this.gruposQuincena.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`ju${1}_n`] && el[`ju${1}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
					let sa22 = this.gruposQuincena.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`ju${2}_n`] && el[`ju${2}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
					let sa33 = this.gruposQuincena.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`ju${3}_n`] && el[`ju${3}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
					let sa44 = this.gruposQuincena.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`ju${4}_n`] && el[`ju${4}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})

		    	// Sacaremos a los teachers que solo dan clase de teens
		    	let sat1 = this.gruposQuincena.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`lu${1}_n`] && el[`lu${1}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
					let sat2 = this.gruposQuincena.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`lu${2}_n`] && el[`lu${2}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
					let sat3 = this.gruposQuincena.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`lu${3}_n`] && el[`lu${3}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
					let sat4 = this.gruposQuincena.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`lu${4}_n`] && el[`lu${4}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})

		    	let satt1 = this.gruposQuincena.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`ju${1}_n`] && el[`ju${1}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
					let satt2 = this.gruposQuincena.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`ju${2}_n`] && el[`ju${2}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
					let satt3 = this.gruposQuincena.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`ju${3}_n`] && el[`ju${3}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
					let satt4 = this.gruposQuincena.filter( el => { return ( el.sabado && el.grupo.match('TEENS') ) && el[`ju${4}_n`] && el[`ju${4}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})


		      let sabadoCompleto = sa1.concat( sa2 ).concat( sa3 ).concat( sa4 ).concat( sa11 ).concat( sa22 ).concat( sa33 ).concat( sa44 )
		      let sabadoTeacherDup  = sa1.concat( sa11 )

		      let sabadoTeacher = Array.from(new Set(sabadoTeacherDup.map(JSON.stringify))).map(JSON.parse);

		      // Conteo

		      for( const i in sabadoTeacher ){

		      	const { id_teacher } = sabadoTeacher[i]

		      	sabadoTeacher[i]['conteo'] = sabadoCompleto.filter( el => el.id_teacher == id_teacher ).length
		      }

		      sabadoTeacher = sabadoTeacher.filter( el => el.conteo >= 4 )

		      const existeBonoSabado = sabadoTeacher.find( el => el.id_teacher == this.getdatosUsuario.iderp )

		      if( existeBonoSabado ){ alert('Da clase sabatinas') }


		      let teachersBonoSabatino = []

		      for( const i in sabadoTeacher ){

		      	const { id_teacher, teacher } = sabadoTeacher[i]

		      	let conteoNoRetardos = 0

		      	let valida1 = this.gruposQuincena.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${1}`] == id_teacher })
		      	let valida2 = this.gruposQuincena.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${2}`] == id_teacher })
		      	let valida3 = this.gruposQuincena.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${3}`] == id_teacher })
		      	let valida4 = this.gruposQuincena.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${4}`] == id_teacher })

		      	// Validamos las horas 
		      	if( valida1 ){
		      		const { hora_inicio, lu1_hora_asistencia } = valida1
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu1_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida2 ){
		      		const { hora_inicio, lu2_hora_asistencia } = valida2
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu2_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida3 ){
		      		const { hora_inicio, lu3_hora_asistencia } = valida3
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu3_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida4 ){
		      		const { hora_inicio, lu4_hora_asistencia } = valida4
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu4_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	sabadoTeacher[i]['conteoNoRetardos'] = conteoNoRetardos
		      } 

		      sabadoTeacher = sabadoTeacher.filter( el => el.conteoNoRetardos >= 3 )

		      // *************************************************************************************
		      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
		    	// Vamos a sacar los teachers de cada bloque
		      let do1 = this.gruposQuincena.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`lu${1}_n`] && el[`lu${1}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
					let do2 = this.gruposQuincena.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`lu${2}_n`] && el[`lu${2}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
					let do3 = this.gruposQuincena.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`lu${3}_n`] && el[`lu${3}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
					let do4 = this.gruposQuincena.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`lu${4}_n`] && el[`lu${4}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})

		    	// Vamos a sacar los teachers de cada bloque
		      let do11 = this.gruposQuincena.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`ju${1}_n`] && el[`ju${1}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
					let do22 = this.gruposQuincena.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`ju${2}_n`] && el[`ju${2}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
					let do33 = this.gruposQuincena.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`ju${3}_n`] && el[`ju${3}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
					let do44 = this.gruposQuincena.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`ju${4}_n`] && el[`ju${4}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})


		      let domingoCompleto = do1.concat( do2 ).concat( do3 ).concat( do4 ).concat( do11 ).concat( do22 ).concat( do33 ).concat( do44 )
		      let domingoTeacherDup  = do1.concat( do11 )

		      let domingoTeacher = Array.from(new Set(domingoTeacherDup.map(JSON.stringify))).map(JSON.parse);

		      // Conteo

		      for( const i in domingoTeacher ){

		      	const { id_teacher } = domingoTeacher[i]

		      	domingoTeacher[i]['conteo'] = domingoCompleto.filter( el => el.id_teacher == id_teacher ).length
		      }

		      domingoTeacher = domingoTeacher.filter( el => el.conteo >= 4 )

		      const existeDomingo = domingoTeacher.find( el => el.id_teacher == this.getdatosUsuario.iderp )

		      if( existeDomingo ){ 
		      
		      	if( existeDomingo.conteo < 4 ){

		      		this.motivoNoBono += `No cuentas con tus 4 domingos de clase\n`
		      	}
		      }

		      for( const i in domingoTeacher ){

		      	const { id_teacher, teacher } = domingoTeacher[i]

		      	let conteoNoRetardos = 0

		      	let valida1 = this.gruposQuincena.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${1}`] == id_teacher ) })
		      	let valida2 = this.gruposQuincena.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${2}`] == id_teacher ) })
		      	let valida3 = this.gruposQuincena.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${3}`] == id_teacher ) })
		      	let valida4 = this.gruposQuincena.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${4}`] == id_teacher ) })

		      	// Validamos las horas 
		      	if( valida1 ){
		      		const { hora_inicio, lu1_hora_asistencia } = valida1
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu1_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida2 ){
		      		const { hora_inicio, lu2_hora_asistencia } = valida2
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu2_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida3 ){
		      		const { hora_inicio, lu3_hora_asistencia } = valida3 
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu3_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida4 ){
		      		const { hora_inicio, lu4_hora_asistencia } = valida4 
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu4_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	domingoTeacher[i]['conteoNoRetardos'] = conteoNoRetardos
		      } 

		      domingoTeacher = domingoTeacher.filter( el => el.conteoNoRetardos >= 3 )

		      const existeDomingoR = domingoTeacher.find( el => el.id_teacher == this.getdatosUsuario.iderp )

		      if( existeDomingoR ){ 
		      
		      	if( existeDomingoR.conteoNoRetardos < 3 ){

		      		this.conteoRetardo += 4 - existeDomingoR.conteoNoRetardos 

		      		this.motivoNoBono += `Cuentas con más de 2 retardos en tus clases dominicales\n`
		      	}
		      }

		      // *************************************************************************************
		      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
		    	// Vamos a sacar los teachers de cada bloque
		      let lu1 = this.gruposQuincena.filter( el => { return ( el.lunes ) && el[`lu${1}_n`] && el[`lu${1}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
					let lu2 = this.gruposQuincena.filter( el => { return ( el.lunes ) && el[`lu${2}_n`] && el[`lu${2}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
					let lu3 = this.gruposQuincena.filter( el => { return ( el.lunes ) && el[`lu${3}_n`] && el[`lu${3}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
					let lu4 = this.gruposQuincena.filter( el => { return ( el.lunes ) && el[`lu${4}_n`] && el[`lu${4}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})

		      let lunesCompleto = lu1.concat( lu2 ).concat( lu3 ).concat( lu4 )
		      let lunesTeacherDup  = lu1

		      let lunesTeacher = Array.from(new Set(lunesTeacherDup.map(JSON.stringify))).map(JSON.parse);

		      // Conteo

		      for( const i in lunesTeacher ){

		      	const { id_teacher } = lunesTeacher[i]

		      	lunesTeacher[i]['conteo'] = lunesCompleto.filter( el => el.id_teacher == id_teacher ).length

		      }

		      lunesTeacher = lunesTeacher.filter( el => el.conteo >= 3 )

		      const existeLunes = lunesTeacher.find( el => el.id_teacher == this.getdatosUsuario.iderp )

		      if( existeLunes ){ 
		      
		      	if( existeLunes.conteo < 3 ){

		      		this.motivoNoBono += `No cumpliste con tu rol completo los lunes\n`
		      	}

		      }

		      for( const i in lunesTeacher ){

		      	const { id_teacher, teacher } = lunesTeacher[i]

		      	let conteoNoRetardos = 0

		      	let valida1 = this.gruposQuincena.find( el => { return ( el.lunes ) && el[`lu${1}`] == id_teacher })
		      	let valida2 = this.gruposQuincena.find( el => { return ( el.lunes ) && el[`lu${2}`] == id_teacher })
		      	let valida3 = this.gruposQuincena.find( el => { return ( el.lunes ) && el[`lu${3}`] == id_teacher })
		      	let valida4 = this.gruposQuincena.find( el => { return ( el.lunes ) && el[`lu${4}`] == id_teacher })

		      	// Validamos las horas 
		      	if( valida1 ){
		      		const { hora_inicio, lu1_hora_asistencia } = valida1
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu1_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida2 ){
		      		const { hora_inicio, lu2_hora_asistencia } = valida2
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu2_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida3 ){
		      		const { hora_inicio, lu3_hora_asistencia } = valida3
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu3_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida4 ){
		      		const { hora_inicio, lu4_hora_asistencia } = valida4
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu4_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	lunesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

		      } 

		      lunesTeacher = lunesTeacher.filter( el => el.conteoNoRetardos >= 3 )
		      
		      const existeLunesR = lunesTeacher.find( el => el.id_teacher == this.getdatosUsuario.iderp )

		      if( existeLunesR ){ 
		      
		      	if( existeLunesR.conteoNoRetardos < 3 ){

		      		this.conteoRetardo += 4 - existeLunesR.conteoNoRetardos 

		      		// this.motivoNoBono += `Cuentas con más de 2 retardos en tus clases dominicales\n`
		      	}

		      }

		      // *************************************************************************************
		      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
		    	// Vamos a sacar los teachers de cada bloque
		      let ma1 = this.gruposQuincena.filter( el => { return ( el.martes ) && el[`ma${1}_n`] && el[`ma${1}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ma${1}`], teacher: registro[`ma${1}_n`]  }})
					let ma2 = this.gruposQuincena.filter( el => { return ( el.martes ) && el[`ma${2}_n`] && el[`ma${2}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ma${2}`], teacher: registro[`ma${2}_n`]  }})
					let ma3 = this.gruposQuincena.filter( el => { return ( el.martes ) && el[`ma${3}_n`] && el[`ma${3}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ma${3}`], teacher: registro[`ma${3}_n`]  }})
					let ma4 = this.gruposQuincena.filter( el => { return ( el.martes ) && el[`ma${4}_n`] && el[`ma${4}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ma${4}`], teacher: registro[`ma${4}_n`]  }})

		      let martesCompleto = ma1.concat( ma2 ).concat( ma3 ).concat( ma4 )
		      let martesTeacherDup  = ma1

		      let martesTeacher = Array.from(new Set(martesTeacherDup.map(JSON.stringify))).map(JSON.parse);

		      // Conteo

		      for( const i in martesTeacher ){

		      	const { id_teacher } = martesTeacher[i]

		      	martesTeacher[i]['conteo'] = martesCompleto.filter( el => el.id_teacher == id_teacher ).length

		      }

		      martesTeacher = martesTeacher.filter( el => el.conteo >= 4 )

		      const existeMartes = martesTeacher.find( el => el.id_teacher == this.getdatosUsuario.iderp )

		      if( existeMartes ){ 
		      	console.log( 'conteo Martes',existeMartes )
		      
		      	if( existeMartes.conteo < 3 ){

		      		this.motivoNoBono += `No cumpliste con tu rol completo los martes\n`
		      	}

		      }

		      for( const i in martesTeacher ){

		      	const { id_teacher, teacher } = martesTeacher[i]

		      	let conteoNoRetardos = 0

		      	let valida1 = this.gruposQuincena.find( el => { return ( el.martes ) && el[`ma${1}`] == id_teacher })
		      	let valida2 = this.gruposQuincena.find( el => { return ( el.martes ) && el[`ma${2}`] == id_teacher })
		      	let valida3 = this.gruposQuincena.find( el => { return ( el.martes ) && el[`ma${3}`] == id_teacher })
		      	let valida4 = this.gruposQuincena.find( el => { return ( el.martes ) && el[`ma${4}`] == id_teacher })

		      	// Validamos las horas 
		      	if( valida1 ){
		      		const { hora_inicio, ma1_hora_asistencia } = valida1
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma1_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida2 ){
		      		const { hora_inicio, ma2_hora_asistencia } = valida2
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma2_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida3 ){
		      		const { hora_inicio, ma3_hora_asistencia } = valida3
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma3_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida4 ){
		      		const { hora_inicio, ma4_hora_asistencia } = valida4
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma4_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	martesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

		      } 

		      martesTeacher = martesTeacher.filter( el => el.conteoNoRetardos >= 3 )

		      const existeMartesR = martesTeacher.find( el => el.id_teacher == this.getdatosUsuario.iderp )

		      if( existeMartesR ){ 
		      
		      	if( existeMartesR.conteoNoRetardos < 3 ){

		      		this.conteoRetardo += 4 - existeMartesR.conteoNoRetardos 

		      		// this.motivoNoBono += `Cuentas con más de 2 retardos en tus clases dominicales\n`
		      	}

		      }

		      // *************************************************************************************
		      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
		    	// Vamos a sacar los teachers de cada bloque
		      let mi1 = this.gruposQuincena.filter( el => { return ( el.miercoles ) && el[`mi${1}_n`] && el[`mi${1}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`mi${1}`], teacher: registro[`mi${1}_n`]  }})
					let mi2 = this.gruposQuincena.filter( el => { return ( el.miercoles ) && el[`mi${2}_n`] && el[`mi${2}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`mi${2}`], teacher: registro[`mi${2}_n`]  }})
					let mi3 = this.gruposQuincena.filter( el => { return ( el.miercoles ) && el[`mi${3}_n`] && el[`mi${3}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`mi${3}`], teacher: registro[`mi${3}_n`]  }})
					let mi4 = this.gruposQuincena.filter( el => { return ( el.miercoles ) && el[`mi${4}_n`] && el[`mi${4}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`mi${4}`], teacher: registro[`mi${4}_n`]  }})

		      let miercolesCompleto = mi1.concat( mi2 ).concat( mi3 ).concat( mi4 )
		      let miercolesTeacherDup  = mi1

		      let miercolesTeacher = Array.from(new Set(miercolesTeacherDup.map(JSON.stringify))).map(JSON.parse);

		      // Conteo

		      for( const i in miercolesTeacher ){

		      	const { id_teacher } = miercolesTeacher[i]

		      	miercolesTeacher[i]['conteo'] = miercolesCompleto.filter( el => el.id_teacher == id_teacher ).length

		      }

		      miercolesTeacher = miercolesTeacher.filter( el => el.conteo >= 4 )

		      for( const i in miercolesTeacher ){

		      	const { id_teacher, teacher } = miercolesTeacher[i]

		      	let conteoNoRetardos = 0

		      	let valida1 = this.gruposQuincena.find( el => { return ( el.miercoles ) && el[`mi${1}`] == id_teacher })
		      	let valida2 = this.gruposQuincena.find( el => { return ( el.miercoles ) && el[`mi${2}`] == id_teacher })
		      	let valida3 = this.gruposQuincena.find( el => { return ( el.miercoles ) && el[`mi${3}`] == id_teacher })
		      	let valida4 = this.gruposQuincena.find( el => { return ( el.miercoles ) && el[`mi${4}`] == id_teacher })

		      	// Validamos las horas 
		      	if( valida1 ){
		      		const { hora_inicio, mi1_hora_asistencia } = valida1
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi1_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida2 ){
		      		const { hora_inicio, mi2_hora_asistencia } = valida2
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi2_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida3 ){
		      		const { hora_inicio, mi3_hora_asistencia } = valida3
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi3_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida4 ){
		      		const { hora_inicio, mi4_hora_asistencia } = valida4
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi4_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	miercolesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

		      } 

		      miercolesTeacher = miercolesTeacher.filter( el => el.conteoNoRetardos >= 3 )

		      const existeMiercolesR = miercolesTeacher.find( el => el.id_teacher == this.getdatosUsuario.iderp )

		      if( existeMiercolesR ){ 
		      
		      	if( existeMiercolesR.conteoNoRetardos < 3 ){

		      		this.conteoRetardo += 4 - existeMiercolesR.conteoNoRetardos 

		      		// this.motivoNoBono += `Cuentas con más de 2 retardos en tus clases dominicales\n`
		      	}

		      }


		      // *************************************************************************************
		      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
		    	// Vamos a sacar los teachers de cada bloque
		      let ju1 = this.gruposQuincena.filter( el => { return ( el.jueves ) && el[`ju${1}_n`] && el[`ju${1}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
					let ju2 = this.gruposQuincena.filter( el => { return ( el.jueves ) && el[`ju${2}_n`] && el[`ju${2}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
					let ju3 = this.gruposQuincena.filter( el => { return ( el.jueves ) && el[`ju${3}_n`] && el[`ju${3}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
					let ju4 = this.gruposQuincena.filter( el => { return ( el.jueves ) && el[`ju${4}_n`] && el[`ju${4}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})

		      let juevesCompleto = ju1.concat( ju2 ).concat( ju3 ).concat( ju4 )
		      let juevesTeacherDup  = ju1


		      let juevesTeacher = Array.from(new Set(juevesTeacherDup.map(JSON.stringify))).map(JSON.parse);

		      console.log('Jueves juevesTeacher', juevesTeacher )
		      // Conteo

		      for( const i in juevesTeacher ){

		      	const { id_teacher } = juevesTeacher[i]

		      	juevesTeacher[i]['conteo'] = juevesCompleto.filter( el => el.id_teacher == id_teacher ).length

		      }


		      const existeJueves = juevesCompleto.filter( el => el.id_teacher == this.getdatosUsuario.iderp )

		      if( existeJueves.length && existeJueves.length < 4 ){ 

		      	this.motivoNoBono += `No cuentas con tus 4 Jueves de clase\n`

		      }

		      juevesTeacher = juevesTeacher.filter( el => el.conteo >= 4 )

		      for( const i in juevesTeacher ){

		      	const { id_teacher, teacher } = juevesTeacher[i]

		      	let conteoNoRetardos = 0

		      	let valida1 = this.gruposQuincena.find( el => { return ( el.jueves ) && el[`ju${1}`] == id_teacher })
		      	let valida2 = this.gruposQuincena.find( el => { return ( el.jueves ) && el[`ju${2}`] == id_teacher })
		      	let valida3 = this.gruposQuincena.find( el => { return ( el.jueves ) && el[`ju${3}`] == id_teacher })
		      	let valida4 = this.gruposQuincena.find( el => { return ( el.jueves ) && el[`ju${4}`] == id_teacher })

		      	// Validamos las horas 
		      	if( valida1 ){
		      		const { hora_inicio, ju1_hora_asistencia } = valida1
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju1_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida2 ){
		      		const { hora_inicio, ju2_hora_asistencia } = valida2
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju2_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida3 ){
		      		const { hora_inicio, ju3_hora_asistencia } = valida3
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju3_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida4 ){
		      		const { hora_inicio, ju4_hora_asistencia } = valida4
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju4_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	juevesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

		      } 

		      juevesTeacher = juevesTeacher.filter( el => el.conteoNoRetardos >= 3 )

		      const existeJuevesR = juevesTeacher.find( el => el.id_teacher == this.getdatosUsuario.iderp )

		      if( existeJuevesR ){ 
		      
		      	if( existeJuevesR.conteoNoRetardos < 3 ){

		      		this.conteoRetardo += 4 - existeJuevesR.conteoNoRetardos 

		      		// this.motivoNoBono += `Cuentas con más de 2 retardos en tus clases dominicales\n`
		      	}

		      }


		      // *************************************************************************************
		      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
		    	// Vamos a sacar los teachers de cada bloque
		      let vi1 = this.gruposQuincena.filter( el => { return ( el.viernes ) && el[`vi${1}_n`] && el[`vi${1}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`vi${1}`], teacher: registro[`vi${1}_n`]  }})
					let vi2 = this.gruposQuincena.filter( el => { return ( el.viernes ) && el[`vi${2}_n`] && el[`vi${2}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`vi${2}`], teacher: registro[`vi${2}_n`]  }})
					let vi3 = this.gruposQuincena.filter( el => { return ( el.viernes ) && el[`vi${3}_n`] && el[`vi${3}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`vi${3}`], teacher: registro[`vi${3}_n`]  }})
					let vi4 = this.gruposQuincena.filter( el => { return ( el.viernes ) && el[`vi${4}_n`] && el[`vi${4}_hora_asistencia`] })
		    							.map((registro) => { return { id_teacher: registro[`vi${4}`], teacher: registro[`vi${4}_n`]  }})

		      let viernesCompleto = vi1.concat( vi2 ).concat( vi3 ).concat( vi4 )
		      let viernesTeacherDup  = vi1

		      let viernesTeacher = Array.from(new Set(viernesTeacherDup.map(JSON.stringify))).map(JSON.parse);

		      // Conteo

		      for( const i in viernesTeacher ){

		      	const { id_teacher } = viernesTeacher[i]

		      	viernesTeacher[i]['conteo'] = viernesCompleto.filter( el => el.id_teacher == id_teacher ).length

		      }

		      viernesTeacher = viernesTeacher.filter( el => el.conteo >= 4 )

		      for( const i in viernesTeacher ){

		      	const { id_teacher, teacher } = viernesTeacher[i]

		      	let conteoNoRetardos = 0

		      	let valida1 = this.gruposQuincena.find( el => { return ( el.viernes ) && el[`vi${1}`] == id_teacher })
		      	let valida2 = this.gruposQuincena.find( el => { return ( el.viernes ) && el[`vi${2}`] == id_teacher })
		      	let valida3 = this.gruposQuincena.find( el => { return ( el.viernes ) && el[`vi${3}`] == id_teacher })
		      	let valida4 = this.gruposQuincena.find( el => { return ( el.viernes ) && el[`vi${4}`] == id_teacher })

		      	// Validamos las horas 
		      	if( valida1 ){
		      		const { hora_inicio, ju1_hora_asistencia } = valida1
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju1_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida2 ){
		      		const { hora_inicio, ju2_hora_asistencia } = valida2
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju2_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida3 ){
		      		const { hora_inicio, ju3_hora_asistencia } = valida3
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju3_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	// Validamos las horas 
		      	if( valida4 ){
		      		const { hora_inicio, ju4_hora_asistencia } = valida4
		      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju4_hora_asistencia.substr(11,5) ) ? 1 : 0
		      	}

		      	viernesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

		      } 

		      viernesTeacher = viernesTeacher.filter( el => el.conteoNoRetardos >= 3 )

		      const existeViernesR = viernesTeacher.find( el => el.id_teacher == this.getdatosUsuario.iderp )

		      if( existeViernesR ){ 
		      
		      	if( existeViernesR.conteoNoRetardos < 3 ){

		      		this.conteoRetardo += 4 - existeViernesR.conteoNoRetardos 

		      		// this.motivoNoBono += `Cuentas con más de 2 retardos en tus clases dominicales\n`
		      	}
		      }

		      let allTeachersDu = lunesTeacher.concat( martesTeacher).concat( juevesTeacher ).concat( miercolesTeacher ).concat( viernesTeacher ).concat( sabadoTeacher ).concat( domingoTeacher )

		      // Utilizar un conjunto para mantener un registro de los id_teacher únicos
			    var idTeacherUnicos = new Set();
			    // Utilizar filter para filtrar los elementos del arreglo
			    // y mantener solo aquellos con id_teacher que no estén en el conjunto
			    this.teachersBonos = allTeachersDu.filter(function (elemento) {
			        // Si el id_teacher ya está en el conjunto, retorna false (no lo incluye)
			        // Si no está en el conjunto, lo agrega y retorna true (lo incluye)
			        if (idTeacherUnicos.has(elemento.id_teacher)) {
			          return false;
			        } else {
			          idTeacherUnicos.add(elemento.id_teacher);
			          return true;
			        }
			    });

			    // Ahora... hay que ponerle qué días, estára....
			    for( const i in this.teachersBonos ){

			    	const { id_teacher, teacher } = this.teachersBonos[i]


			    	this.teachersBonos[i]['eliminable'] = 0
			    	this.teachersBonos[i]['bono']       = 0
			    	this.teachersBonos[i]['bono_l']     = 0

			    	const existeSabado    = sabadoTeacher.find( el => el.id_teacher == id_teacher )
			    	const existeDomingo   = domingoTeacher.find( el => el.id_teacher == id_teacher )
			    	const existeLunes     = lunesTeacher.find( el => el.id_teacher == id_teacher )
			    	const existeMartes    = martesTeacher.find( el => el.id_teacher == id_teacher )
			    	const existeMiercoles = juevesTeacher.find( el => el.id_teacher == id_teacher )
			    	const existeJueves    = miercolesTeacher.find( el => el.id_teacher == id_teacher )
			    	const existeViernes   = viernesTeacher.find( el => el.id_teacher == id_teacher )



			    	// Un fin de semana y entre semana medio turno
			    	if( ( existeSabado || existeDomingo ) && ( existeLunes && existeMartes && existeMiercoles && existeJueves && existeViernes )){

			    		// Significa que no debería estar rolada en nigun fin de semana
			    		let existeEntreSemana = this.gruposQuincena.filter( el => { return ( el.lunes == 1 || el.martes == 1 || el.miercoles == 1 || el.jueves == 1 || el.viernes == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

			    		let medioTurno = 0
			    		let medioTurno2 = 0

			    		for( const i in existeEntreSemana ){

			    			const { hora_inicio } = existeEntreSemana[i]

			    			if( hora_inicio.substr(0,5) <= "16:00" ){ medioTurno = 1 }
			    			if( hora_inicio.substr(0,5) >= "16:00" ){ medioTurno2 = 1 }

			    		}

			    		let grupos = existeEntreSemana.map((registro) => { return registro.grupo })

			    		// FULL
			    		if( medioTurno && medioTurno2 ){
			    			this.teachersBonos[i]['categoria'] = 1
			    			this.teachersBonos[i]['bono']      = 1000
			    		}else{ // 1/2 turno + fin de semana
			    			this.teachersBonos[i]['categoria'] = 2
			    			this.teachersBonos[i]['bono']      = 500
			    		}
			    		
			    	}


			    	// Un fin de semana y entre semana medio turno
			    	if( ( existeSabado || existeDomingo ) && ( existeLunes || existeMartes || existeMiercoles || existeJueves || existeViernes )){

			    		// Significa que no debería estar rolada en nigun fin de semana
			    		let existeEntreSemana = this.gruposQuincena.filter( el => { return ( el.lunes == 1 || el.martes == 1 || el.miercoles == 1 || el.jueves == 1 || el.viernes == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

			    		let medioTurno = 0
			    		let medioTurno2 = 0

			    		for( const i in existeEntreSemana ){

			    			const { hora_inicio } = existeEntreSemana[i]

			    			if( hora_inicio.substr(0,5) <= "16:00" ){ medioTurno = 1 }
			    			if( hora_inicio.substr(0,5) >= "16:00" ){ medioTurno2 = 1 }

			    		}

			    		let grupos = existeEntreSemana.map((registro) => { return registro.grupo })

			    		// FULL
			    		if( medioTurno && medioTurno2 ){
			    			this.teachersBonos[i]['categoria'] = 1
			    			this.teachersBonos[i]['bono_l']    = 1000
			    		}else{ // 1/2 turno + fin de semana
			    			this.teachersBonos[i]['categoria'] = 2
			    			this.teachersBonos[i]['bono_l']    = 500
			    		}
			    		
			    	}


			    	// SOLO FIN DE SEMANA
			    	if( ( existeSabado || existeDomingo ) && ( !existeLunes && !existeMartes && !existeMiercoles && !existeJueves && !existeViernes )){

			    		// Significa que no debería estar rolada en nigun fin de semana
			    		let existeEntreSemana = this.gruposQuincena.filter( el => { return ( el.sabado == 0 && el.domingo == 0 ) && ( el[`lu${1}`] == id_teacher && el[`ma${1}`] == id_teacher && el[`mi${1}`] == id_teacher && el[`ju${1}`] == id_teacher && el[`vi${1}`] == id_teacher ) })

			    		if( !existeEntreSemana.length ){
			    			this.teachersBonos[i]['categoria'] = 3
			    			this.teachersBonos[i]['bono']      = 350
			    			this.teachersBonos[i]['bono_l']    = 350
			    		}else{
			    			this.teachersBonos[i]['eliminable'] = 1
			    			this.teachersBonos[i]['bono']       = 0
			    			this.teachersBonos[i]['bono_l']     = 0
			    		}
			    		

			    	}

			    	// SOLO ENTRE SEMANA
			    	if( ( !existeSabado && !existeDomingo ) && ( existeLunes && existeMartes && existeMiercoles && existeJueves && existeViernes )){

			    		// Significa que no debería estar rolada en nigun fin de semana
			    		let existeFinSemana = this.gruposQuincena.filter( el => { return ( el.sabado == 1 || el.domingo == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

			    		if( !existeFinSemana.length ){
			    			this.teachersBonos[i]['categoria'] = 4
		            this.teachersBonos[i]['bono']      = 500
			    			this.teachersBonos[i]['bono_l']    = 500
			    		}else{
			    			this.teachersBonos[i]['eliminable'] = 1
			    			this.teachersBonos[i]['bono']       = 0
			    			this.teachersBonos[i]['bono_l']     = 0
			    		}
			    		

			    	}

		        // SOLO ENTRE SEMANA
		        if( ( !existeSabado && !existeDomingo ) && ( existeLunes || existeMartes || existeMiercoles || existeJueves || existeViernes )){

		          // Significa que no debería estar rolada en nigun fin de semana
		          let existeFinSemana = this.gruposQuincena.filter( el => { return ( el.sabado == 1 || el.domingo == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

		          if( !existeFinSemana.length ){
		            this.teachersBonos[i]['categoria'] = 4
		            this.teachersBonos[i]['bono_l']    = 500
		          }else{
		            this.teachersBonos[i]['eliminable'] = 1
		            this.teachersBonos[i]['bono_l']     = 0
		          }
		          

		        }
			    }


			    this.teachersBonos.push({
			    	id_teacher:  10000000000000000,
						teacher:     'TOTAL',
						categoria:   0,
						bono:        this.teachersBonos.map(item => item.bono).reduce((prev, curr) => prev + curr, 0) ,
						eliminable:  0,
			    })

		    	return this.teachersBonos
		    
		    }catch( error ){

		    	this.validarErrorDirecto( error.message )

		    }
	    },

			save ( valor ) {
				this.cargar = true
				const payload = {
					id_usuario : this.getdatosUsuario.iderp,  
					estatus    : valor,
					id_ciclo   : this.ciclo.id_ciclo,
					semana     : this.selectedHeaders,
					motivo     : this.tipo_nomina == 1 ? this.motivo3 : this.tipo_nomina == 2 ? this.motivo : this.motivo2,
					tipo_nomina: this.tipo_nomina
				}

				this.$http.post('teachers.estatus.nomina', payload).then(response=>{
					this.validarSuccess('Datos grabados correctamente')
					this.rechazado = false
					this.motivo    = ''
					this.cargar = false
					this.initialize()
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })	
			},

			desgloseHoras( horas ){

				this.horasTeacher = horas

				this.dialogHoras = true
			},

			s2ab(s) {
				var buf = new ArrayBuffer(s.length);
				var view = new Uint8Array(buf);
				for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
					return buf;
			},

			exportar(){
				let data = XLSX.utils.json_to_sheet(this.entradassalidas)

				const workbook = XLSX.utils.book_new()
				const filename = 'entradas_salidas'
				XLSX.utils.book_append_sheet(workbook, data, filename)

				var wbout = XLSX.write(workbook, {
					bookType: 'xls',
					bookSST: false,
					type: 'binary'
				});

				const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')

				let formData = new FormData();
		        // //se crea el objeto y se le agrega como un apendice el archivo 
				formData.append('file',file);
		        // /*getDatosRiesgo the form data*/

				this.$http.post('pdfs',formData).then(response=> {
					window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
				}).catch(error=> {
					console.log(error);
				});
			},

			format( valor ){
			  return valor.toLocaleString('es-MX', {
			    style: 'currency',
			    currency: 'MXN'
			  });
	    },

			validarHoraAsistencia(horaInicio, horaAsistencia) {
		    // Convertir las horas a minutos
		    var inicioMinutos = this.obtenerMinutos(horaInicio);
    		var asistenciaMinutos = this.obtenerMinutos(horaAsistencia);
		    
		    // Verificar si la hora de asistencia es al menos 15 minutos antes de la hora de inicio
		    if (inicioMinutos - asistenciaMinutos >= 15) {
		        return true;
		    } else {
		        return false;
		    }
			},

			obtenerMinutos(hora) {
		    var partesHora = hora.split(":");
		    var horas = parseInt(partesHora[0]);
		    var minutos = parseInt(partesHora[1]);
		    return horas * 60 + minutos;
			},

			compararHorasInicio(eventoA, eventoB) {
		    // Convertir las horas de inicio a minutos
		    var minutosA = this.obtenerMinutos(eventoA.hora_inicio);
		    var minutosB = this.obtenerMinutos(eventoB.hora_inicio);
		    
		    // Comparar los minutos
		    if (minutosA < minutosB) {
		        return -1;
		    } else if (minutosA > minutosB) {
		        return 1;
		    } else {
		        return 0;
		    }
			},

			getCiclosFecha () {
				this.cargar = true
				this.ciclos = []

				const payload = {
					fecha: this.fecha
				}

				return this.$http.post('roles.get.ciclofecha', payload).then(response=>{
					for(const i in response.data){
						if (!(response.data[i].ciclo.search('FE') != -1)) {
							if(response.data[i].ciclo.search('CICLO') != -1){
								this.ciclos.push(response.data[i])
								this.ciclo = this.ciclos[0];
							}
						}
					}
					this.cargar = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { })
			},

			verComentarios( comentarios ){

				this.comentarios = comentarios
				this.dialogVerComentario = true

			},

			exportExcel(data) {
				const wb = XLSX.utils.book_new();
				data.forEach(sheetData => {
					const ws = XLSX.utils.json_to_sheet(sheetData.data);
					XLSX.utils.book_append_sheet(wb, ws, sheetData.sheetName);
				});

				// Descarga el archivo Excel
				XLSX.writeFile(wb, 'Nomina y Prenomina.xlsx');
			},

			exportarInscripciones() {
				// const empleados = this.filterEntradas.map(({ id, nombre_completo, puesto, departamento, plantel, tipo_pago, sueldo_mensual, sueldo_semanal, sueldo_dia, sueldo_hora, dia_laboral, horas_laboradas_total, cantidad_horas, monto_horas_extras, retardos, comisiones_semana, devoluciones, rebajes, monto_retardo,  retensiones, compensaciones, bonos_ventas, bonos_ri, bonos_puntualidad, seguroIMSS, cajaAhorro, monto_laborado, monto_total }) =>
				// 	({ id, nombre_completo, puesto, departamento, plantel, tipo_pago, sueldo_mensual, sueldo_semanal, sueldo_dia, sueldo_hora, dia_laboral, horas_laboradas_total, "Horas Extras": cantidad_horas, monto_horas_extras, retardos, comisiones_semana, devoluciones, rebajes, monto_retardo, retensiones, compensaciones, bonos_ventas, bonos_ri, bonos_puntualidad, seguroIMSS, cajaAhorro, monto_laborado, monto_total }));

				const empleados = this.filterEntradas.map(({ id, nombre_completo, puesto, monto_laborado, monto_horas_extras, comisiones_semana, monto_total_rebajes, monto_total_bonos, monto_total }) =>
					({ "ID": id,"Empleado":nombre_completo, "Puesto":puesto, "Monto Jornada Laboral":monto_laborado,  "Monto Horas Extras":monto_horas_extras,  "Monto Comisiones":comisiones_semana, "Monto Rebajes":monto_total_rebajes, "Monto Bonos":monto_total_bonos,  "Monto Total":monto_total }));

				const empleados2 = this.filterEntradas.map(({ id, nombre_completo, puesto, plantel, sucursal_registro, fecha_ingreso, dia_laboral, horas_laboradas_total, horas_extras, entrada1, salida1, horas_laboradas1,  entrada2, salida2, horas_laboradas2, entrada3, salida3, horas_laboradas3,  entrada4, salida4, horas_laboradas4,  entrada5, salida5, horas_laboradas5,  entrada6, salida6, horas_laboradas6, entrada7, salida7, horas_laboradas7 }) => 
					({ id, nombre_completo, puesto, plantel, sucursal_registro, fecha_ingreso, dia_laboral, horas_laboradas_total, horas_extras, "Sabado/Entrada": entrada1, "Sabado/Salida":salida1, "Sabado/Horas":horas_laboradas1,  "Domingo/Entrada":entrada2, "Domingo/Salida":salida2, "Domingo/Horas":horas_laboradas2,  "Lunes/Entrada":entrada3, "Lunes/Salida":salida3, "Lunes/Horas":horas_laboradas3,  "Martes/Entrada":entrada4, "Martes/Salida":salida4, "Martes/Horas":horas_laboradas4, "Miercoles/Entrada":entrada5, "Miercoles/Salida":salida5, "Miercoles/Horas":horas_laboradas5, "Jueves/Entrada":entrada6, "Jueves/Salida":salida6, "Jueves/Horas":horas_laboradas6, "Viernes/Entrada":entrada7, "Viernes/Salida":salida7, "Viernes/Horas":horas_laboradas7  }));

				const empleados3 = this.nomina_pasada.map(({ id_trabajador, empleado, puesto, plantel, monto_total_sin_comisiones, monto_total_actual, monto_total_diferencia}) => 
					({ id_trabajador, empleado, puesto, plantel, "Monto Anterior": monto_total_sin_comisiones, "Monto Actual": monto_total_actual, "Monto Diferencia": monto_total_diferencia }));

				const empleados4 = this.filterEntradasEncargadas.map(({ id, nombre_completo, puesto, plantel, comisiones_semana}) => 
					({ id, nombre_completo, puesto, plantel, "Comisiones": comisiones_semana }));


				// Llama a la función exportExcel para crear un archivo Excel con dos pestañas
				this.exportExcel([
					{ sheetName: 'Nomina', data: empleados },
					{ sheetName: 'Prenomina', data: empleados2 }, // Segunda pestaña con los mismos datos
					{ sheetName: 'Comparativa', data: empleados3 }, // Segunda pestaña con los mismos datos
					{ sheetName: 'Comisiones', data: empleados4 } // Segunda pestaña con los mismos datos
				]);
			},

			sacarMotivoNoBono( ){

				const diasSemana = ['lu', 'ma', 'mi', 'ju', 'vi'];
	      const bloquesTiempo = [1,2,3,4];

	      // Obtenemos los grupos donde dio clase el teacher
	      let grupos = this.gruposAll.filter(el => {
	        for (const dia of diasSemana) {
	          for (const bloque of bloquesTiempo) {
	            if (el[`${dia}${bloque}`] === this.getdatosUsuario.iderp && el[`${dia}${bloque}_n`] != '' ) {
	              return true; // Si encuentra una coincidencia, incluye el grupo en el resultado
	            }
	          }
	        }
	        return false; // Si no hay coincidencias en ningún día ni bloque, excluye el grupo
	      });

	      // Filtramos los grupos donde el teacher es el teacher fijo 
	      grupos = grupos.filter( el => el.id_teacher == this.getdatosUsuario.iderp || el.id_teacher_2 == this.getdatosUsuario.iderp )

	      // Quitar grupos online
	      grupos = grupos.filter( el => !el.grupo.match('ONLINE') )

	      this.conteoRetardo = 0
	      this.textoRetardos = ''

	      let lunes = 0
	      let martes = 0
	      let miercoles = 0
	      let jueves = 0
	      let viernes = 0

	      let lunes2 = 0
	      let martes2 = 0
	      let miercoles2 = 0
	      let jueves2 = 0
	      let viernes2 = 0

	      let lunes3 = 0
	      let martes3 = 0
	      let miercoles3 = 0
	      let jueves3 = 0
	      let viernes3 = 0

	      let lunes4 = 0
	      let martes4 = 0
	      let miercoles4 = 0
	      let jueves4 = 0
	      let viernes4 = 0
	      // Recorremos los grupos fijos del teacher
	      for( const i in grupos ){

	      	// Sacamos los grupos, los dias y las cosas principales
	      	const { id_teacher, id_teacher_2, lu1, ma1, mi1, ju1, vi1, lu2, ma2, mi2, ju2, vi2, lu3, ma3, mi3, ju3, vi3, lu4, ma4, mi4, ju4, vi4, grupo, hora_inicio } = grupos[i]

	      	if( id_teacher == this.getdatosUsuario.iderp ){

	      		if( lu1 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Lunes 1 en ${ grupo }\n` }
	      		if( lu2 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Lunes 2 en ${ grupo }\n` }
	      		if( lu3 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Lunes 3 en ${ grupo }\n` }
	      		if( lu4 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Lunes 4 en ${ grupo }\n` }

	      		if( ma1 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Martes 1 en ${ grupo }\n` }
	      		if( ma2 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Martes 2 en ${ grupo }\n` }
	      		if( ma3 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Martes 3 en ${ grupo }\n` }
	      		if( ma4 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Martes 4 en ${ grupo }\n` }

	      		if( mi1 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Miércoles 1 en ${ grupo }\n` }
	      		if( mi2 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Miércoles 2 en ${ grupo }\n` }
	      		if( mi3 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Miércoles 3 en ${ grupo }\n` }
	      		if( mi4 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Miércoles 4 en ${ grupo }\n` }

	      	}

	      	if( id_teacher_2 == this.getdatosUsuario.iderp ){

	      		if( ju1 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Jueves 1 en ${ grupo }\n` }
	      		if( ju2 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Jueves 2 en ${ grupo }\n` }
	      		if( ju3 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Jueves 3 en ${ grupo }\n` }
	      		if( ju4 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Jueves 4 en ${ grupo }\n` }


	      		if( vi1 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Viernes 1 en ${ grupo }\n` }
	      		if( vi2 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Viernes 2 en ${ grupo }\n` }
	      		if( vi3 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Viernes 3 en ${ grupo }\n` }
	      		if( vi4 != this.getdatosUsuario.iderp ){ this.motivoNoBono += `No diste la clase del Viernes 4 en ${ grupo }\n` }

	      	}


	      	if( lu1 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].lu1_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && lunes == 0 ){
	      			this.conteoRetardo += 1
	      			lunes += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].lu1_hora_asistencia}*\n`
	      		}
	      	}
	      	if( ma1 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].ma1_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && martes == 0 ){
	      			this.conteoRetardo += 1
	      			martes += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].ma1_hora_asistencia}*\n`
	      		}
	      	}
	      	if( mi1 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].mi1_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && miercoles == 0 ){
	      			this.conteoRetardo += 1
	      			miercoles += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].mi1_hora_asistencia}*\n`
	      		}
	      	}
	      	if( ju1 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].ju1_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && jueves == 0 ){
	      			this.conteoRetardo += 1
	      			jueves += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].ju1_hora_asistencia}*\n`
	      		}
	      	}



	      	if( lu2 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].lu2_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && lunes2 == 0 ){
	      			this.conteoRetardo += 1
	      			lunes2 += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].lu2_hora_asistencia}*\n`
	      		}
	      	}
	      	if( lu3 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].lu3_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && lunes3 == 0 ){
	      			this.conteoRetardo += 1
	      			lunes3 += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].lu3_hora_asistencia}*\n`
	      		}
	      	}
	      	if( lu4 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].lu4_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && lunes4 == 0 ){
	      			this.conteoRetardo += 1
	      			lunes4 += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].lu4_hora_asistencia}*\n`
	      		}
	      	}


	      	if( ma2 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].ma2_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && martes2 == 0 ){
	      			this.conteoRetardo += 1
	      			martes2 += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].ma2_hora_asistencia}*\n`
	      		}
	      	}
	      	if( ma3 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].ma3_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && martes3 == 0 ){
	      			this.conteoRetardo += 1
	      			martes3 += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].ma3_hora_asistencia}*\n`
	      		}
	      	}
	      	if( ma4 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].ma4_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && martes4 == 0 ){
	      			this.conteoRetardo += 1
	      			martes4 += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].ma4_hora_asistencia}*\n`
	      		}
	      	}


	      	if( mi2 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].mi2_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && miercoles2 == 0 ){
	      			this.conteoRetardo += 1
	      			miercoles2 += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].mi2_hora_asistencia}*\n`
	      		}
	      	}
	      	if( mi3 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].mi3_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && miercoles3 == 0 ){
	      			this.conteoRetardo += 1
	      			miercoles3 += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].mi3_hora_asistencia}*\n`
	      		}
	      	}
	      	if( mi4 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].mi4_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && miercoles4 == 0 ){
	      			this.conteoRetardo += 1
	      			miercoles4 += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].mi4_hora_asistencia}*\n`
	      		}
	      	}



	      	
	      	if( ju2 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].ju2_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && jueves2 == 0 ){
	      			this.conteoRetardo += 1
	      			jueves2 += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].ju2_hora_asistencia}*\n`
	      		}
	      	}
	      	if( ju3 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].ju3_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && jueves3 == 0 ){
	      			this.conteoRetardo += 1
	      			jueves3 += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].ju3_hora_asistencia}*\n`
	      		}
	      	}
	      	if( ju4 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].ju4_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && jueves4 == 0 ){
	      			this.conteoRetardo += 1
	      			jueves4 += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].ju4_hora_asistencia}*\n`
	      		}
	      	}


	      	if( vi1 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].vi1_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && viernes == 0 ){
	      			this.conteoRetardo += 1
	      			viernes += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].vi1_hora_asistencia}*\n`
	      		}
	      	}
	      	if( vi2 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].vi2_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && viernes2 == 0 ){
	      			this.conteoRetardo += 1
	      			viernes2 += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].vi2_hora_asistencia}*\n`
	      		}
	      	}
	      	if( vi3 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].vi3_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && viernes3 == 0 ){
	      			this.conteoRetardo += 1
	      			viernes3 += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].vi3_hora_asistencia}*\n`
	      		}
	      	}
	      	if( vi4 == this.getdatosUsuario.iderp ){ 
						let asistencia = this.validarHoraAsistencia( hora_inicio.substr(0,5), grupos[i].vi4_hora_asistencia.substr(11,5) ) ? 0 : 1
	      		if( asistencia && viernes4 == 0 ){
	      			this.conteoRetardo += 1
	      			viernes4 += 1 
	      			this.textoRetardos += `${grupo} - Entrada: ${hora_inicio.substr(0,5)} - Registro: ${grupos[i].vi4_hora_asistencia}*\n`
	      		}
	      	}
	      	

	      }

			},


		},
	}
</script>




