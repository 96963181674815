import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';
import axios from 'axios';
import XLSX from 'xlsx'

export default {
	data: () => ({

		
  }),

  created(){


  },

  watch: {

  	
  },

  computed:{

  	totalHoras(){
  		return this.gruposActivos.filter( el => { return el.deleted == 0 && el.sabado }).map(item => item.horas_semana ).reduce((prev, curr) => prev + curr, 0)
  	},

  	notasSabatino ( ){

			let payload = ''

			// Comparamos ambas horas impartidas
			if( this.horasImpartidas > this.horasImpartidasAnt ){

				let semanaAct = this.selectedHeaders 

				let semanaAnt = this.selectedHeaders - 1

				for( const i in this.gruposActivos ){


					if( !this.gruposActivos[i][`lu${semanaAnt}`] && this.gruposActivos[i][`lu${semanaAct}`] || !this.gruposActivos[i][`ju${semanaAnt}`] && this.gruposActivos[i][`ju${semanaAct}`]  ){

						payload += `\n ${this.gruposActivos[i].grupo}`

					}

				}

				return payload

			}
		}

  },

	methods: {

		inciarNominaSabtina( ){

			this.tabla1Comparativa = []
      this.tabla2Comparativa = []

      this.categorias1 =[]
      this.categorias2 =[]

      this.costoHora1 = 0
      this.costoHora2 = 0

    	if( !this.ciclo ){
    		return this.validarErrorDirecto('Selecciona un ciclo por favor')
    	}
    	this.gruposRoles = []
    	this.cargar      = true
      this.$http.post('rol.clases', this.ciclo ).then(async response=>{
        this.gruposActivos    = response.data.respuesta.filter( el => { return el.deleted == 0 && el.sabado })

        this.rebajes          = response.data.rebajes 

        // Veremos el this.selectedHeaders == 3 si es 3, la quincena es 2, semana 1 y 2 del ciclo actual
        if( this.selectedHeaders == 3 ){
        	this.gruposQuincena   = response.data.respuesta.filter( el => { return el.deleted == 0 && !el.sabado })
        	this.horasDobles      = response.data.horasDobles
        }

        this.pagos            = response.data.teachers

        await this.getNominaTeacher2( )

        await this.getBonosTeachers( )


        this.limpiarGrupos( )

        this.sacarTeachers( )

        this.sacarHorasSabado1( )
  	
	    	this.sacarTotales( )
	    	
	    	this.getTablaComparativa( )

	    	this.getTablaComparativaAnt( )

	    	this.igualarCategorias( )

	    	this.motivoDiferencias( )

	    	// Solo si es semana 1 o 3, se inicia también la quincena
	    	if( [1,3].includes( this.selectedHeaders )){
	    		this.iniciarQuincena( )
	    	}

	    	this.formatMoney( )

        // this.cargar      = false
      }).catch( error =>{
      	console.log( error )
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
		},

		getNominaTeacher2( ){
			this.gruposRoles2 = []
			this.cargar       = true

			return this.$http.post('rol.clases.nomina2', this.ciclo ).then(response=>{
				this.gruposRoles2     = response.data.respuesta.filter( el => { return el.deleted == 0 && el.sabado })

				// Veremos el this.selectedHeaders == 3 si es 3, la quincena es 2, semana 1 y 2 del ciclo actual
        if( this.selectedHeaders == 1 ){
        	this.gruposQuincena   = response.data.respuesta.filter( el => { return el.deleted == 0 && !el.sabado })
        	// this.gruposAllBono    = response.data.respuesta.filter( el => { return el.deleted == 0 })
        	this.horasDobles      = response.data.horasDobles
        }

        this.gruposQuincenaAnt   = response.data.respuesta.filter( el => { return el.deleted == 0 && !el.sabado })

        this.cicloAnt         = response.data.id_ciclo
        this.comentariosT     = response.data.estatusNominaQuincena

			}).catch( error =>{
				this.validarError( error.response.data.message )
			}).finally( () => { this.cargar = false })
		},

		getBonosTeachers( ){
			this.bonosTeachers = []
			this.cargar       = true

			return this.$http.post('rol.clases.bonos', this.ciclo ).then(response=>{

				if( this.selectedHeaders == 1 ){
					this.bonosTeachers     = response.data
					console.log( this.bonosTeachers )
				}
			}).catch( error =>{
				this.validarError( error.response.data.message )
			}).finally( () => { this.cargar = false })
		},


    exportar( ){
      this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
    },

    limpiarGrupos ( ) {
    	for( const i in this.gruposActivos ){
    		const { grupo } = this.gruposActivos[i]
      	if( ( grupo.match('SABATI') && grupo.match('TEENS') ) || ( grupo.match('DOMINICAL') && grupo.match('TEENS') ) ){
    			this.gruposActivos[i].ju1_n = null
    			this.gruposActivos[i].ju1   = null
    			this.gruposActivos[i].vi1_n = null
    			this.gruposActivos[i].vi1   = null
    			this.gruposActivos[i].ju2_n = null
    			this.gruposActivos[i].ju2   = null
    			this.gruposActivos[i].vi2_n = null
    			this.gruposActivos[i].vi2   = null
    			this.gruposActivos[i].ju3_n = null
    			this.gruposActivos[i].ju3   = null
    			this.gruposActivos[i].vi3_n = null
    			this.gruposActivos[i].vi3   = null
    			this.gruposActivos[i].ju4_n = null
    			this.gruposActivos[i].ju4   = null
    			this.gruposActivos[i].vi4_n = null
    			this.gruposActivos[i].vi4   = null
    		}      		
    	}
    },

    limpiarGruposAnt ( ) {
    	for( const i in this.gruposRoles2 ){
    		const { grupo } = this.gruposRoles2[i]
      	if( ( grupo.match('SABATI') && grupo.match('TEENS') ) || ( grupo.match('DOMINICAL') && grupo.match('TEENS') ) ){
    			this.gruposRoles2[i].ju1_n = null
    			this.gruposRoles2[i].ju1   = null
    			this.gruposRoles2[i].vi1_n = null
    			this.gruposRoles2[i].vi1   = null
    			this.gruposRoles2[i].ju2_n = null
    			this.gruposRoles2[i].ju2   = null
    			this.gruposRoles2[i].vi2_n = null
    			this.gruposRoles2[i].vi2   = null
    			this.gruposRoles2[i].ju3_n = null
    			this.gruposRoles2[i].ju3   = null
    			this.gruposRoles2[i].vi3_n = null
    			this.gruposRoles2[i].vi3   = null
    			this.gruposRoles2[i].ju4_n = null
    			this.gruposRoles2[i].ju4   = null
    			this.gruposRoles2[i].vi4_n = null
    			this.gruposRoles2[i].vi4   = null
    		}      		
    	}
    },

    sacarTeachers ( ) {
    	// Sacar a los teachers que dan clase
    	// Vamos a sacar los teachers de cada bloque
      let tlu1 = this.gruposActivos.filter( el => { return el[`lu${this.selectedHeaders}_n`] }).map((registro) => { return { id_teacher: registro[`lu${this.selectedHeaders}`], teacher: registro[`lu${this.selectedHeaders}_n`]}})
      let tma1 = this.gruposActivos.filter( el => { return el[`ma${this.selectedHeaders}_n`] }).map((registro) => { return { id_teacher: registro[`ma${this.selectedHeaders}`], teacher: registro[`ma${this.selectedHeaders}_n`]}})
      let tmi1 = this.gruposActivos.filter( el => { return el[`mi${this.selectedHeaders}_n`] }).map((registro) => { return { id_teacher: registro[`mi${this.selectedHeaders}`], teacher: registro[`mi${this.selectedHeaders}_n`]}})
      let tju1 = this.gruposActivos.filter( el => { return el[`ju${this.selectedHeaders}_n`] }).map((registro) => { return { id_teacher: registro[`ju${this.selectedHeaders}`], teacher: registro[`ju${this.selectedHeaders}_n`]}})
      let tvi1 = this.gruposActivos.filter( el => { return el[`vi${this.selectedHeaders}_n`] }).map((registro) => { return { id_teacher: registro[`vi${this.selectedHeaders}`], teacher: registro[`vi${this.selectedHeaders}_n`]}})

      // Concatenamos a los maestros
      let arregloTeachers = tlu1.concat( tma1 ).concat( tmi1 ).concat( tju1 ).concat( tvi1 )
        
      // Quitamos los duplicados de los maestros
      this.teachers = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);
    },

    sacarHorasSabado1 ( ) {

			// Recorremos los maestros para ir agregando las cosas
			for( const i in this.teachers ){

				// Sacamos el id del teacher
				const { id_teacher, teacher } = this.teachers[i]

				// Sacamos las clases donde esta el maestro 
				let clasesSabado = this.gruposActivos.filter( el => el[`lu${this.selectedHeaders}`] == id_teacher || el[`ma${this.selectedHeaders}`] == id_teacher || el[`mi${this.selectedHeaders}`] == id_teacher || el[`ju${this.selectedHeaders}`] == id_teacher || el[`vi${this.selectedHeaders}`] == id_teacher )

				// Lo separamos por escuela
				const clasesFast      = clasesSabado.filter( el => { return el.id_unidad_negocio == 2})
				const clasesInbi      = clasesSabado.filter( el => { return el.id_unidad_negocio == 1})
				const clasesInbiTeens = clasesSabado.filter( el => { return el.id_unidad_negocio == 1})

				let clasesInbiContador = 0
				let clasesFastContador = 0

				let contadorBloquesFast = 0
				for( const i in clasesFast ){

					contadorBloquesFast += clasesFast[i][`lu${this.selectedHeaders}`] == id_teacher ? 1 : 0
					contadorBloquesFast += clasesFast[i][`ma${this.selectedHeaders}`] == id_teacher ? 1 : 0
					contadorBloquesFast += clasesFast[i][`mi${this.selectedHeaders}`] == id_teacher ? 1 : 0
					contadorBloquesFast += clasesFast[i][`ju${this.selectedHeaders}`] == id_teacher ? 1 : 0
					contadorBloquesFast += clasesFast[i][`vi${this.selectedHeaders}`] == id_teacher ? 1 : 0

					clasesFastContador += clasesFast[i][`lu${this.selectedHeaders}`] == id_teacher ? 1 : 0
				}

				let contadorBloquesInbi = 0
				for( const i in clasesInbi ){

					const { grupo } = clasesInbi[i]

					if( grupo.match('TEENS') ){

					}else{
						contadorBloquesInbi += clasesInbi[i][`lu${this.selectedHeaders}`] == id_teacher ? 1 : 0
						contadorBloquesInbi += clasesInbi[i][`ma${this.selectedHeaders}`] == id_teacher ? 1 : 0
						contadorBloquesInbi += clasesInbi[i][`mi${this.selectedHeaders}`] == id_teacher ? 1 : 0
						contadorBloquesInbi += clasesInbi[i][`ju${this.selectedHeaders}`] == id_teacher ? 1 : 0
						contadorBloquesInbi += clasesInbi[i][`vi${this.selectedHeaders}`] == id_teacher ? 1 : 0

						clasesInbiContador += clasesInbi[i][`lu${this.selectedHeaders}`] == id_teacher ? 1 : 0
					}
				}

				// CLASES TEENS
				let contadorBloqueTeens = 0
				let clasesTeens = clasesInbi.filter( el => { return el.grupo.match('TEENS') })

				for( const i in clasesTeens ){
					const { grupo } = clasesTeens[i]
					contadorBloqueTeens += clasesTeens[i][`lu${this.selectedHeaders}`] == id_teacher ? 1 : 0
					contadorBloqueTeens += clasesTeens[i][`ma${this.selectedHeaders}`] == id_teacher ? 1 : 0
					contadorBloqueTeens += clasesTeens[i][`mi${this.selectedHeaders}`] == id_teacher ? 1 : 0
					contadorBloqueTeens += clasesTeens[i][`ju${this.selectedHeaders}`] == id_teacher ? 1 : 0
					contadorBloqueTeens += clasesTeens[i][`vi${this.selectedHeaders}`] == id_teacher ? 1 : 0
					// contadorBloqueTeens += 3
					clasesInbiContador  += 1
				}

				const existeCategoria = this.pagos.find( el => el.id_usuario  == id_teacher )

				let hora_1   = existeCategoria ? existeCategoria.hora_1   : 0
				let hora_2   = existeCategoria ? existeCategoria.hora_2   : 0
				let hora_3   = existeCategoria ? existeCategoria.hora_3   : 0
				let aceptado = existeCategoria ? existeCategoria.aceptado : 0
				let hsbc     = existeCategoria ? existeCategoria.hsbc     : 0

				// AGREGAREMOS LOS PAGOS
				this.teachers[i]['categoria']  = hora_2
				this.teachers[i]['aceptado']   = aceptado
				this.teachers[i]['hsbc']       = hsbc
				this.teachers[i]['existeCategoria'] = 1

				const total_horas = contadorBloquesFast + contadorBloquesInbi + contadorBloqueTeens

				let totalClases = this.gruposActivos.filter( el => el[`lu${this.selectedHeaders}`] == id_teacher || el[`ma${this.selectedHeaders}`] == id_teacher || el[`mi${this.selectedHeaders}`] == id_teacher || el[`ju${this.selectedHeaders}`] == id_teacher || el[`vi${this.selectedHeaders}`] == id_teacher ).length

				let clasesTeacher = this.gruposActivos.filter( el => el[`lu${this.selectedHeaders}`] == id_teacher || el[`ma${this.selectedHeaders}`] == id_teacher || el[`mi${this.selectedHeaders}`] == id_teacher || el[`ju${this.selectedHeaders}`] == id_teacher || el[`vi${this.selectedHeaders}`] == id_teacher )

				this.teachers[i]['CLASES_SABADO_FAST']  = clasesFast.length
				this.teachers[i]['HORAS_SABADO_FAST']   = contadorBloquesFast
				this.teachers[i]['CLASES_SABADO_INBI']  = clasesInbi.length
				this.teachers[i]['HORAS_SABADO_INBI']   = contadorBloquesInbi
				this.teachers[i]['CLASES_SABADO_TEENS'] = clasesTeens.length
				this.teachers[i]['HORAS_SABADO_TEENS']  = contadorBloqueTeens
				this.teachers[i]['TOTAL_CLASES']        = totalClases
				this.teachers[i]['TOTAL_HORAS']         = contadorBloquesFast + contadorBloquesInbi + contadorBloqueTeens

				/* SACAR LOS REABJES */

				this.teachers[i]['PAGO_SABADO']         = this.format( ( contadorBloquesInbi * hora_2 ) + ( contadorBloquesFast * hora_2 ) + ( contadorBloqueTeens * hora_2 ))
				this.teachers[i]['plantel']             = clasesSabado.length ? clasesSabado[0].plantel : 'SIN PLANTEL'

				// Aquí... poner esa cosa de los comentarios
				const existeEstatusS = this.comentariosT.find( el => el.id_usuario == id_teacher && el.semana == this.selectedHeaders && el.tipo_nomina == 1 )

				this.teachers[i]['estatus_sabatina']     = existeEstatusS ? existeEstatusS.estatus : 0 
				this.teachers[i]['comentarios_sabatina'] = existeEstatusS ? existeEstatusS.comentarios : [] 

			}
    },

    sacarTotales ( ){

    	// Agregar los rebajes

    	// this.teachers.push({
    	// 	id_teacher: 100000,
      //   teacher   : 'TOTAL',
      //   HORAS_SABADO_FAST  : this.teachers.map(item => item.HORAS_SABADO_FAST).reduce((prev, curr) => prev + curr, 0),
      //   HORAS_SABADO_INBI  : this.teachers.map(item => item.HORAS_SABADO_INBI).reduce((prev, curr) => prev + curr, 0),
      //   HORAS_SABADO_TEENS : this.teachers.map(item => item.HORAS_SABADO_TEENS).reduce((prev, curr) => prev + curr, 0),
      //   // bono_teacher       : this.teachers.map(item => item.bono_teacher).reduce((prev, curr) => prev + curr, 0),
      //   TOTAL_HORAS        : this.teachers.map(item => item.TOTAL_HORAS ).reduce((prev, curr) => prev + curr, 0),
      //   PAGO_SABADO        : this.teachers.map(item => item.PAGO_SABADO).reduce((prev, curr) => prev + curr, 0),
    	// })
    },

    formatMoney ( ) {
    	// En teoría aquí ya está todo
    	try{
	    	for( const i in this.teachers ){

	    		const { HORAS_SABADO_FAST, HORAS_SABADO_INBI, HORAS_SABADO_TEENS, TOTAL_HORAS, PAGO_SABADO, id_teacher } = this.teachers[i]

	    		// this.teachers[i].PAGO_SABADO  = PAGO_SABADO ? this.format( PAGO_SABADO ) : '$0.00' 
	    		this.teachers[i]['categoria'] = this.teachers[i]['categoria'] ? this.format( this.teachers[i]['categoria'] ) : '$0.00' 

	    	}

    	}catch( error ){
    		this.validarErrorDirecto( error.message )
    	}

    },

    getTablaComparativa( ){

    	// Mi variable de la tabla 1 comparativa = tabla1Comparativa

    	// Primero, sacar la semana anterior
    	let getCategorias = this.teachers.filter( el => el.id_teacher < 100000 ).map((registro) => { return registro.categoria })

    	this.categorias1 = [...new Set(getCategorias)].sort((a, b) => b - a) 

    	for( const i in this.categorias1 ){

    		let teachersCategoria = this.teachers.filter( el => el.categoria == this.categorias1[i] && el.id_teacher < 100000 )

    		let totalHoras =  teachersCategoria.map(item => item.TOTAL_HORAS ).reduce((prev, curr) => prev + curr, 0)


    		this.tabla1Comparativa.push({
    			valor_hora   : this.categorias1[i] == null ? 'TEACHER LIDER' : this.categorias1[i] ,
    			numero_horas : totalHoras,
    			costo        : this.format( totalHoras * this.categorias1[i] )
    		})

    	}

    	this.tabla1Comparativa.push({
  			valor_hora   : 'Horas totales',
  			numero_horas : this.teachers.filter( el => el.teacher != 'TOTAL' ).map(item => item.TOTAL_HORAS ).reduce((prev, curr) => prev + curr, 0),
  			costo        : this.format( this.teachers.filter( el => el.teacher != 'TOTAL' ).map(item => parseFloat( item.PAGO_SABADO.replace('$','').replace(',','') ) ).reduce((prev, curr) => prev + curr, 0))
  		})

  		this.tabla1Comparativa.push({
  			valor_hora   : 'Horas impartidas',
  			numero_horas : this.teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.TOTAL_HORAS).reduce((prev, curr) => prev + curr, 0),
  			costo        : this.format( this.teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => parseFloat(  item.PAGO_SABADO.replace('$','').replace(',','') ) ).reduce((prev, curr) => prev + curr, 0))
  		})


  		// this.tabla1Comparativa.push({
  		// 	valor_hora   : 'Horas totales',
  		// 	numero_horas : this.gruposActivos.filter( el => { return el.deleted == 0 && el.sabado }).map(item => item.horas_semana ).reduce((prev, curr) => prev + curr, 0),
  		// 	costo        : this.format( this.teachers.filter( el => el.teacher != 'TOTAL' ).map(item => item.PAGO_SABADO).reduce((prev, curr) => prev + curr, 0))
  		// })

  		// this.tabla1Comparativa.push({
  		// 	valor_hora   : 'Horas impartidas',
  		// 	numero_horas : this.teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.TOTAL_HORAS).reduce((prev, curr) => prev + curr, 0),
  		// 	costo        : this.format( teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.PAGO_SABADO).reduce((prev, curr) => prev + curr, 0))
  		// })

  		this.horasImpartidas = this.teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.TOTAL_HORAS).reduce((prev, curr) => prev + curr, 0)
  		let costoTotal       = this.teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => parseFloat( item.PAGO_SABADO.replace('$','').replace(',','') ) ).reduce((prev, curr) => prev + curr, 0)


  		this.costoHora2     = this.format( costoTotal / this.horasImpartidas )
    },

    getTablaComparativaAnt( ){

    	let semana = this.selectedHeaders == 1 ? 4 : this.selectedHeaders - 1 

    	let gruposActivos = this.selectedHeaders == 1 ? this.gruposRoles2 : this.gruposActivos

    	for( const i in gruposActivos ){

    		const { lunes, martes, miercoles, jueves, viernes, sabado, domingo } = gruposActivos[i]

    		if( sabado || domingo ){
    			gruposActivos[i][`lu${semana}_n`] = gruposActivos[i][`lu${semana}_n`] == '' ? 0 : gruposActivos[i][`lu${semana}_n`]
    			gruposActivos[i][`ma${semana}_n`] = gruposActivos[i][`ma${semana}_n`] == '' ? 0 : gruposActivos[i][`ma${semana}_n`]
    			gruposActivos[i][`mi${semana}_n`] = gruposActivos[i][`mi${semana}_n`] == '' ? 0 : gruposActivos[i][`mi${semana}_n`]
    			gruposActivos[i][`ju${semana}_n`] = gruposActivos[i][`ju${semana}_n`] == '' ? 0 : gruposActivos[i][`ju${semana}_n`]
    			gruposActivos[i][`vi${semana}_n`] = gruposActivos[i][`vi${semana}_n`] == '' ? 0 : gruposActivos[i][`vi${semana}_n`]
    		}
    	}

    	// Sacar a los teachers que dan clase
    	// Vamos a sacar los teachers de cada bloque
      let tlu1 = gruposActivos.filter( el => { return el[`lu${semana}_n`] }).map((registro) => { return { id_teacher: registro[`lu${semana}`], teacher: registro[`lu${semana}_n`]}})
      let tma1 = gruposActivos.filter( el => { return el[`ma${semana}_n`] }).map((registro) => { return { id_teacher: registro[`ma${semana}`], teacher: registro[`ma${semana}_n`]}})
      let tmi1 = gruposActivos.filter( el => { return el[`mi${semana}_n`] }).map((registro) => { return { id_teacher: registro[`mi${semana}`], teacher: registro[`mi${semana}_n`]}})
      let tju1 = gruposActivos.filter( el => { return el[`ju${semana}_n`] }).map((registro) => { return { id_teacher: registro[`ju${semana}`], teacher: registro[`ju${semana}_n`]}})
      let tvi1 = gruposActivos.filter( el => { return el[`vi${semana}_n`] }).map((registro) => { return { id_teacher: registro[`vi${semana}`], teacher: registro[`vi${semana}_n`]}})

      // Concatenamos a los maestros
      let arregloTeachers = tlu1.concat( tma1 ).concat( tmi1 ).concat( tju1 ).concat( tvi1 )
        
      // Quitamos los duplicados de los maestros
      let teachers = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);

      // Recorremos los maestros para ir agregando las cosas
			for( const i in teachers ){

				// Sacamos el id del teacher
				const { id_teacher } = teachers[i]

				// Sacamos las clases donde esta el maestro 
				let clasesSabado = gruposActivos.filter( el => el[`lu${semana}`] == id_teacher || el[`ma${semana}`] == id_teacher || el[`mi${semana}`] == id_teacher || el[`ju${semana}`] == id_teacher || el[`vi${semana}`] == id_teacher )

				// Lo separamos por escuela
				const clasesFast      = clasesSabado.filter( el => { return el.id_unidad_negocio == 2})
				const clasesInbi      = clasesSabado.filter( el => { return el.id_unidad_negocio == 1})
				const clasesInbiTeens = clasesSabado.filter( el => { return el.id_unidad_negocio == 1})

				let clasesInbiContador = 0
				let clasesFastContador = 0

				let contadorBloquesFast = 0
				for( const i in clasesFast ){

					contadorBloquesFast += clasesFast[i][`lu${semana}`] == id_teacher ? 1 : 0
					contadorBloquesFast += clasesFast[i][`ma${semana}`] == id_teacher ? 1 : 0
					contadorBloquesFast += clasesFast[i][`mi${semana}`] == id_teacher ? 1 : 0
					contadorBloquesFast += clasesFast[i][`ju${semana}`] == id_teacher ? 1 : 0
					contadorBloquesFast += clasesFast[i][`vi${semana}`] == id_teacher ? 1 : 0

					clasesFastContador += clasesFast[i][`lu${semana}`] == id_teacher ? 1 : 0
				}

				let contadorBloquesInbi = 0
				for( const i in clasesInbi ){

					const { grupo } = clasesInbi[i]

					if( grupo.match('TEENS') ){

					}else{
						contadorBloquesInbi += clasesInbi[i][`lu${semana}`] == id_teacher ? 1 : 0
						contadorBloquesInbi += clasesInbi[i][`ma${semana}`] == id_teacher ? 1 : 0
						contadorBloquesInbi += clasesInbi[i][`mi${semana}`] == id_teacher ? 1 : 0
						contadorBloquesInbi += clasesInbi[i][`ju${semana}`] == id_teacher ? 1 : 0
						contadorBloquesInbi += clasesInbi[i][`vi${semana}`] == id_teacher ? 1 : 0

						clasesInbiContador += clasesInbi[i][`lu${semana}`] == id_teacher ? 1 : 0
					}
				}

				// CLASES TEENS
				let contadorBloqueTeens = 0
				let clasesTeens = clasesInbi.filter( el => { return el.grupo.match('TEENS') })

				for( const i in clasesTeens ){
					const { grupo } = clasesTeens[i]
					contadorBloqueTeens += 3
					clasesInbiContador  += 1
				}

				const existeCategoria = this.pagos.find( el => el.id_usuario  == id_teacher )

				let hora_1 = existeCategoria ? existeCategoria.hora_1 : 0
				let hora_2 = existeCategoria ? existeCategoria.hora_2 : 0
				let hora_3 = existeCategoria ? existeCategoria.hora_3 : 0

				// AGREGAREMOS LOS PAGOS
				teachers[i]['categoria']  = hora_2

				const total_horas = contadorBloquesFast + contadorBloquesInbi + contadorBloqueTeens

				let totalClases = this.gruposActivos.filter( el => el[`lu${this.selectedHeaders}`] == id_teacher || el[`ma${this.selectedHeaders}`] == id_teacher || el[`mi${this.selectedHeaders}`] == id_teacher || el[`ju${this.selectedHeaders}`] == id_teacher || el[`vi${this.selectedHeaders}`] == id_teacher ).length

				teachers[i]['CLASES_SABADO_FAST']  = clasesFast.length
				teachers[i]['HORAS_SABADO_FAST']   = contadorBloquesFast
				teachers[i]['CLASES_SABADO_INBI']  = clasesInbi.length
				teachers[i]['HORAS_SABADO_INBI']   = contadorBloquesInbi
				teachers[i]['CLASES_SABADO_TEENS'] = clasesTeens.length
				teachers[i]['HORAS_SABADO_TEENS']  = contadorBloqueTeens
				teachers[i]['TOTAL_CLASES']        = totalClases
				teachers[i]['TOTAL_HORAS']         = contadorBloquesFast + contadorBloquesInbi + contadorBloqueTeens

				teachers[i]['PAGO_SABADO']         = ( contadorBloquesInbi * hora_2 ) + ( contadorBloquesFast * hora_2 ) + ( contadorBloqueTeens * hora_2 )

			}


    	// Mi variable de la tabla 1 comparativa = tabla1Comparativa

    	// Primero, sacar la semana anterior
    	let getCategorias = teachers.filter( el => el.id_teacher < 100000 ).map((registro) => { return registro.categoria })

    	this.categorias2 = [...new Set(getCategorias)].sort((a, b) => b - a) 

    	for( const i in this.categorias2 ){

    		let teachersCategoria = teachers.filter( el => el.categoria == this.categorias2[i] && el.id_teacher < 100000 )

    		let totalHoras =  teachersCategoria.map(item => item.TOTAL_HORAS ).reduce((prev, curr) => prev + curr, 0)


    		this.tabla2Comparativa.push({
    			valor_hora   : this.categorias2[i] == null ? 'TEACHER LIDER' : this.categorias2[i] ,
    			numero_horas : totalHoras,
    			costo        : this.format( totalHoras * this.categorias2[i] )
    		})

    	}


    	this.tabla2Comparativa.push({
  			valor_hora   : 'Horas totales',
  			numero_horas : teachers.filter( el => el.teacher != 'TOTAL' ).map(item => item.TOTAL_HORAS).reduce((prev, curr) => prev + curr, 0),
  			costo        : this.format( teachers.filter( el => el.teacher != 'TOTAL' ).map(item => item.PAGO_SABADO).reduce((prev, curr) => prev + curr, 0))
  		})

  		this.tabla2Comparativa.push({
  			valor_hora   : 'Horas impartidas',
  			numero_horas : teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.TOTAL_HORAS).reduce((prev, curr) => prev + curr, 0),
  			costo        : this.format( teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.PAGO_SABADO).reduce((prev, curr) => prev + curr, 0))
  		})

  		this.horasImpartidasAnt = teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.TOTAL_HORAS).reduce((prev, curr) => prev + curr, 0)
  		let costoTotal          = teachers.filter( el => el.teacher != 'TOTAL' && el.teacher != 'SIN TEACHER').map(item => item.PAGO_SABADO).reduce((prev, curr) => prev + curr, 0)

  		this.costoHora1     = this.format( costoTotal / this.horasImpartidasAnt )
    },

    igualarCategorias( ){

    	if( this.tabla1Comparativa.length != this.tabla2Comparativa.length ){


    		if( this.tabla1Comparativa.length > this.tabla2Comparativa.length ){

					const findIndexById = (arr, valor_hora) => arr.findIndex(obj => obj.valor_hora == valor_hora);
    			this.tabla1Comparativa.forEach((obj, index) => {
					  const foundIndex = findIndexById(this.tabla2Comparativa, obj.valor_hora);
					  if (foundIndex === -1) {
					    // Insertar el objeto en la misma posición que en array1
					    let objeto = {
					    	valor_hora: obj.valor_hora,
					    	numero_horas: 0,
					    	costo: '$0.00'
					    }
					    this.tabla2Comparativa.splice(index, 0, objeto);
					  }
					});

    		}else{

    			// Función para encontrar el índice de un objeto por id
					const findIndexById = (arr, valor_hora) => arr.findIndex(obj => obj.valor_hora == valor_hora);


					this.tabla2Comparativa.forEach((obj, index) => {
					  const foundIndex = findIndexById(this.tabla1Comparativa, obj.valor_hora);
					  if (foundIndex === -1) {
					    // Insertar el objeto en la misma posición que en array1
					    let objeto = {
					    	valor_hora: obj.valor_hora,
					    	numero_horas: 0,
					    	costo: '$0.00'
					    }
					    this.tabla1Comparativa.splice(index, 0, objeto);
					  }
					});


    			// for( const i in this.tabla2Comparativa ){

    				// if( !this.tabla1Comparativa.find( el => el.valor_hora == this.tabla2Comparativa[i].valor_hora ) ){

    				// 	// this.tabla2Comparativa.push({

    				// 	// })

    				// 	alert(`no encontre la categoria 2: ${ this.tabla2Comparativa[i].valor_hora }`)

    				// }

    			// }

    		}

    	}
    },

    motivoDiferencias ( ) {

    	for( const i in this.tabla2Comparativa ){

    		const { numero_horas, valor_hora, costo } = this.tabla2Comparativa[i]

    		let diferencia = this.tabla1Comparativa[i].numero_horas - numero_horas

    		let diferenciaM = parseFloat( this.tabla1Comparativa[i].costo.replace('$','').replace(',','')) - parseFloat( costo.replace('$','').replace(',','') )

    		this.tabla1Comparativa[i]['diferencia']  = this.format( diferenciaM )

    		if( numero_horas > this.tabla1Comparativa[i].numero_horas ){
    			
    			this.tabla1Comparativa[i]['motivo'] = `Menos ${ diferencia } horas de ${ valor_hora }`

    		}else if( numero_horas == this.tabla1Comparativa[i].numero_horas ){
    			
    			this.tabla1Comparativa[i]['motivo'] = 'No hay diferencia'

    		}else{

    			this.tabla1Comparativa[i]['motivo'] = `Más ${ diferencia } horas de ${ valor_hora }`

    		}
    		// 

    	}
			// this.tabla2Comparativa
    },

    bloquear( value ) { 

    	if( [1,3].includes( this.selectedHeaders ) ){

    		this.bloquearQuincena( value )

    	}

    	this.bloquearSabatino( value )

    },

    bloquearSabatino( liberada ){
    	const payload = {
    		id_ciclo: this.ciclo.id_ciclo,
    		semana  : this.selectedHeaders,
    		tipo    : 1,
    		liberada
    	}

    	this.cargar      = true
      this.$http.post('rol.clases.bloquear', payload ).then(response=>{
      	if( liberada == 1 ){
        	this.validarSuccess('Liberación de nómina correctamente')
      	}else{
        	this.validarSuccess('Bloqueo de nómina correctamente')
      	}
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    bloquearQuincena( liberada ){

    	const payload = {
    		id_ciclo : this.ciclo.id_ciclo,
    		semana   : this.selectedHeaders == 1 ? [3,4] : [ 1, 2 ],
    		cicloAnt : this.selectedHeaders == 1 ? this.cicloAnt : this.ciclo.id_ciclo,
    		tipo     : 2,
    		liberada
    	}

    	this.cargar      = true
      this.$http.post('rol.clases.bloquear.quincena', payload ).then(response=>{
        if( liberada == 1 ){
        	this.validarSuccess('Liberación de nómina correctamente')
      	}else{
        	this.validarSuccess('Bloqueo de nómina correctamente')
      	}
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })

    },

    format( valor ){
		  return valor.toLocaleString('es-MX', {
		    style: 'currency',
		    currency: 'MXN'
		  });
    }

  }
}